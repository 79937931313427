import $ from 'jquery';
import React from 'react';
import {btnSetColor_CBack,CallMapMethod_CBack} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './comp_upload_basic.css';
import './comp_working_hours.css';
let appLoanUrlPath;
let preWhAppToken="";
export function preWorkingHr(app_token){
	let map=[];
	map.push({name:"curl_type",value:"getWorkingHrs"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:app_token});
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getWorkingHrs";
	CallMapMethod_CBack(this,map,function(data,msg){
	  //alert(data);
	  if(data!=="0"){
		let lsData=JSON.parse(data);
		let strWhHr="";
		for(let i=0;i<lsData.length;i++){
			strWhHr+="<tr>"+
				"<td>"+lsData[i]["col1"]+"</td>"+
				"<td>"+lsData[i]["col2"]+"</td>"+
				"<td>"+lsData[i]["col3"]+"</td>"+
				"</tr>";
		}
		$("#tbWorkingHr").html(strWhHr);
	  }
	  
	})
  }

export function UpdDocSubmit(frmName){
 
  btnSetColor_CBack(this,frmName,"addWorkingHoursP",function(data,datares){
	//alert(data+datares);
    if(data===-10){
      alert("Kindly fill required fields");
    }else if(data==="001"){
		preWorkingHr(preWhAppToken);
    }else{
      //Not accepted from server
    }
  });
}


export function Comp_Working_Hours(props){

	/* check click */


  //alert(JSON.stringify(props));
  //alert(props.userObject);
  if(props.viewLoadDoc!=="undefined"){
   // alert(props.viewLoadDoc[0].doc_list);
  }
  preWhAppToken=props.userObject[0].app_user_id;
  preWorkingHr(props.userObject[0].app_user_id);
  return(<div class="modal" id="divWorkHours" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">Working Hours</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="frmWorkHours" name="frmWorkHours" className='formarea' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="curl_type" id="curl_type" value="addWorkingHoursP" />  
        <input type="hidden" name="m_type" id="m_type" value="addWorkingHoursP" /> 
        <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
        <input type="hidden" name="app_token" id="app_token" value={props.userObject[0].app_user_id} />
        
        <div class="row d-flex justify-content-center">
				<div class="col-md-7 myblock">
					<div class="row">
						<div class="p-3 px-3">
							<h3>Establishment Timings</h3>
							
							<div class="">
								<p class="fs-6 text-body">Clinic</p>
								<small class="text-muted mb-2 d-block">Days</small>
								<div class="weekDays-selector mb-4">
									<input type="checkbox" id="weekday-mon" name="weekday-mon" class="weekday" />
									<label for="weekday-mon">Mo</label>
									<input type="checkbox" id="weekday-tue" name="weekday-tue" class="weekday" />
									<label for="weekday-tue">Tu</label>
									<input type="checkbox" id="weekday-wed" name="weekday-wed" class="weekday" />
									<label for="weekday-wed">We</label>
									<input type="checkbox" id="weekday-thu" name="weekday-thu" class="weekday" />
									<label for="weekday-thu">Th</label>
									<input type="checkbox" id="weekday-fri" name="weekday-fri" class="weekday" />
									<label for="weekday-fri">Fr</label>
									<input type="checkbox" id="weekday-sat" name="weekday-sat" class="weekday" />
									<label for="weekday-sat">Sa</label>
									<input type="checkbox" id="weekday-sun" name="weekday-sun" class="weekday" />
									<label for="weekday-sun">Su</label>
								</div>
								<div class="row gx-3 gy-2 align-items-center">
									<div class="col-md-12"><small class="text-muted">Session 1</small></div>
									<div class="col-sm-6 col-6">
										<select class="form-select" id="col2" name="col2">
											<option value={"0"}>From...</option>
										{props.listClocks.map((item,index)=>{
											return <option value={item}>{item}</option>
										})}	
											
										</select>
									</div>
									<div class="col-sm-6 col-6">
										<select class="form-select" id="col3" name="col3">
											<option value={"0"}>To...</option>
											{props.listClocks.map((item,index)=>{
											return <option value={item}>{item}</option>
										})}	
										</select>
									</div>
									<div class="col-md-12"><small class="text-muted">Session 2</small></div>
									<div class="col-sm-6 col-6">
										<select class="form-select" id="col4" name="col4">
											<option value={"0"}>From...</option>
											{props.listClocks.map((item,index)=>{
											return <option value={item}>{item}</option>
										})}	
										</select>
									</div>
									<div class="col-sm-6 col-6">
										<select class="form-select" id="col5" name="col5">
											<option value={"0"}>To...</option>
											{props.listClocks.map((item,index)=>{
											return <option value={item}>{item}</option>
										})}	
										</select>
									</div>
								</div>
							</div>
							{/* <a href="" id="plus" class="text-decoration-none py-3 d-block show_hide">ADD TIMING FOR REMAINING DAYS +</a> */}
                        </div>
						
					</div>
				</div>
			</div>
        </form>
		<div className="row d-flex justify-content-center">
			<div className="col-md-7">
				<table className="table table-bordered table-striped table-ms">
					<thead>
						<th>Week Day</th>
						<th>Session 1</th>
						<th>Session 2</th>
					</thead>
					<tbody id="tbWorkingHr">
						
					</tbody>

				</table>
			</div>
		</div>
      </div>
      <div class="modal-footer">
        <button type="button" onClick={()=>UpdDocSubmit("frmWorkHours")} class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>);
}
export default Comp_Working_Hours;