import  $ from 'jquery';
import React from 'react';
import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";
import UserToken from '../function_lib/userToken';
import {getUserObjData} from '../function_lib/userToken';
import {getAccessRolesNew,CallCMSMapMethod} from '../function_lib/common_cms';
import {CityList,tmpAutoList} from '../function_lib/city_india';
import {RespBkMthdMapp,setKeyUpMapp,CallMapMethod} from '../function_lib/common_lib';
import './front_header.css';
import logoImg from '../assets/images/logo/logo.png';

let listRoles;
let loginUser;
let userObject;

let logInObj;
let userOpt;
let clsObject;

let _topTitle="";
let _topKeywords="";
let _topDescription="";
let loc="";
class Front_Header extends React.Component{
    logout(rf){
        localStorage.clear();
        window.location.href="./";
      }
    constructor(props){
       super(props);
       loc=this.props.loc;
       if(localStorage.getItem("userDS")!=null){
        //logInObj=JSON.parse(localStorage.getItem("userDS"));
        logInObj=getUserObjData();
        if(logInObj){
            //alert(JSON.stringify(logInObj));
            if(logInObj[0]["user_type"]==="_ash_adv"){
                window.location.href="../dashboard";
            }
        }
      }
    }
    setMetaTags(listMeta){
        // alert(JSON.stringify(listMeta));
     
         for(var m=0;m<listMeta.length;m++){
             if(typeof loc==='undefined'|| loc===""){
                 loc="home";
             }
             //alert(listMeta[m]["col2"]+"=="+loc);
            if(listMeta[m]["col2"].toUpperCase()===loc.toUpperCase() || listMeta[m]["col2"].toUpperCase()==="../"+loc.toUpperCase()){
                //alert(listMeta[m]["col2"]);
                _topTitle=listMeta[m]["col3"];
                _topKeywords=listMeta[m]["col4"];
                _topDescription=listMeta[m]["col5"];
                document.title=_topTitle;
             document.querySelector('meta[name="title"]').setAttribute("content", _topTitle);
             document.querySelector('meta[name="description"]').setAttribute("content", _topDescription);
             document.querySelector('meta[name="keywords"]').setAttribute("content", _topKeywords);
            }
           
         }
         
     }
    gb_search(ref){
        if(ref!==""){
         var map=[];
         map.push({name:"curl_type",value:"gbSearchApp"});
     map.push({name:"res_func",value:"callBackNg"});
     map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
     map.push({name:"ser_key",value:ref})
     map["res_func"]="callBackNg";
     map["curl_type"]="gbSearchApp";
     CallMapMethod(this,map);
        }else{
         //$("#gb_item_search").html(""); 
        }
     }
 
     callBackNg(data,msg){
         //alert(data);
         if(data.trim()!=="0"){
             var listVal= JSON.parse(data);
             var strList="";
             $("#txtSearchHead").autocomplete({
                source:listVal,
                select:function(event,ui){
                    event.preventDefault();
                    let urlItem=ui.item.item_name;
                    urlItem=urlItem.replace(/\s/g, '_');
                    urlItem=urlItem.replace('/','_');
                    if(ui.item.category_name==="Medicine"){
                        window.location.href="../product/"+urlItem+"?rf="+ui.item.id;
                    }else if(ui.item.category_name.trim().toUpperCase()==="LABORATORY"){
                        window.location.href="../vendors/"+urlItem+"?rf="+ui.item.item_code;
                    }
                    //alert(ui.item.category_name);
                    //if(event)
                    //alert("i am in select");
                }
              }).data("ui-autocomplete")._renderItem = function (ul, item) {
                var newText = String(item.value).replace(
                        new RegExp(this.term, "gi"),
                        "<span class='ui-state-highlight'>$&</span>");
        
                return $("<li></li>")
                    .data("item.autocomplete", item)
                    .append("<div>" + newText + "</div>")
                    .appendTo(ul);
            };

              $("#txtSearchHead_sm").autocomplete({
                source:listVal,
                select:function(event,ui){
                    event.preventDefault();
                    let urlItem=ui.item.item_name;
                    urlItem=urlItem.replace(/\s/g, '_');
                    urlItem=urlItem.replace('/','_');
                    if(ui.item.category_name==="Medicine"){
                        window.location.href="../product/"+urlItem+"?rf="+ui.item.id;
                    }else if(ui.item.category_name.trim().toUpperCase()==="LABORATORY"){
                        window.location.href="../vendors/"+urlItem+"?rf="+ui.item.item_code;
                    }
                    //alert(ui.item.category_name);
                    //if(event)
                    //alert("i am in select");
                }
              }).data("ui-autocomplete")._renderItem = function (ul, item) {
                var newText = String(item.value).replace(
                        new RegExp(this.term, "gi"),
                        "<span class='ui-state-highlight'>$&</span>");
        
                return $("<li></li>")
                    .data("item.autocomplete", item)
                    .append("<div>" + newText + "</div>")
                    .appendTo(ul);
            };
         }
     }
    componentDidMount(){
        clsObject=this;
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getAccessToken"});
        mapCat.push({name:"res_func",value:"callBackAccess"});
       // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
        //mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackAccess";
        mapCat["curl_type"]="getAccessToken";
        CallCMSMapMethod(this,mapCat);

        userOpt="<button class=\"dropdown-toggle header-action-btn\" title=\"login\" data-bs-toggle=\"dropdown\">"+
        "<i class=\"icon-user\"></i></button>"+
                                        "<ul class=\"dropdown-menu dropdown-menu-right\">"+
                                        "<li><a class=\"dropdown-item\" data-toggle=\"modal\" data-target=\"#compLoginModal\" href=\"javascript:void(0)\">Sign in</a></li>"+
                                            //"<li><a class=\"dropdown-item\" href=\"../login\">Sign in</a></li>"+
                                            "<li><a class=\"dropdown-item\" href=\"../register\">Register</a></li>"+
                                        "</ul>";
            if(logInObj)
            {
                         userOpt= "<button class=\"dropdown-toggle pr-0 border-0 header-action-btn\" data-bs-toggle=\"dropdown\">"+
                                        "<a href=\"javascript:void(0)\">My Account</a> "+"<i class=\"ion-ios-arrow-down\"></i>"+"</button>"+
                                                                            "<ul class=\"dropdown-menu dropdown-menu-right\">"+
                                                                            "<li><a class=\"dropdown-item\" href=\"../dashboard\">"+"Dashboard"+"</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\"../dashboard\">"+logInObj[0]["person_name"]+"</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\"../dashboard\">My account</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\"#\">Checkout</a></li>"+
                                                                                "<li><a class=\"dropdown-item\" href=\"#\">My Cart</a></li>"+
                                                                                "<li><a class=\"dropdown-item _setbackparam\" param1=\"1\"  href=\"javascript:void(0)\">Logout</a></li>"+
                                                                            "</ul>";   
                                 
                                        }
  //alert(userOpt);
  $("#user_opt_desk").html(userOpt);
  $("#user_opt_mob").html(userOpt);

  setKeyUpMapp(this,"gb_search");

  var locCityList=tmpAutoList();
 
  $("#txtSearchHead").autocomplete({
   source:locCityList
 });
 $("#txtSearchHead_sm").autocomplete({
    source:locCityList
 })


  RespBkMthdMapp(clsObject,"_setbackparam","logout")

    }
    callBackAccess(data,msg){
       // alert("cback"+data);
        if(data!=="0"){
            localStorage.setItem('userData',data);
            UserToken.setUserObject(localStorage.getItem("userData"));
            listRoles= UserToken.getAccessRoles();
            this.setMetaTags(listRoles);
          var listHtm=  getAccessRolesNew(listRoles); 
          $("#menu_opt").html(listHtm);
          $("#mob_menu").html(listHtm);
        }
      

    }
    render(){
        return(<div><div className="header section">
        
        
        <div className="header-bottom d-none d-lg-block">
            <div className="container position-relative">
                <div className="d-flex justify-content-center" style={{alignItems:"center"}}>
                   
                    <div className="p2 d-flex" style={{"align-items":"center"}}>
                        <div className="header-logo">
                            <a href="../"><img src={logoImg} alt="Site Logo" /></a>
                           
                        </div>
                        <div className="header-top-curr dropdown pl-5">
                                <button className="dropdown-toggle pr-0 border-0 cmb_location" data-bs-toggle="dropdown"><i class="fa fa-map-marker fa-1x icon-themecolor" aria-hidden="true"></i>Indore</button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="javascript:void(0)">Indore</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0)"></a></li>
                                </ul>
                            </div>
                        {/* <a href={""} className="white1 pl-5"><i class="fa fa-map-marker fa-2x icon-themecolor" aria-hidden="true"></i></a> */}
                    </div>
                   
                    <div className="p2 custom-search input-group">
                    
    <input type="text" autoComplete='off' id="txtSearchHead" name="txtSearchHead" className="form-control _setSearchKey" placeholder="Search Labs,Test,Prescribed Products etc. " />
    <button className="input-group-text shadow-none px-4 btn-warning">
    <i className="icon-magnifier"></i>
    </button>
</div>
                  
        
                    
                    <div className="p2">
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="header-action-btn search-btn"><i
                                        className="icon-magnifier"></i></a>
                                <div className="dropdown_search">
                                <div className="input-group mb-3">
    <div className="input-group-text p-0">
        <select className="form-select form-select-lg shadow-none bg-light border-0">
            <option>Categories</option>
            <option>One</option>
            <option>Two</option>
            <option>Three</option>
            <option>Four</option>
        </select>
    </div>
    
    <input type="text" className="form-control" placeholder="Search Here" />
    <button className="input-group-text shadow-none px-4 btn-warning">
    <i className="icon-magnifier"></i>
    </button>
</div>
                                </div>
                            </div>
                            <div className="header-top-curr dropdown">
                                <button className="dropdown-toggle pr-0 border-0" data-bs-toggle="dropdown">
                                For Business</button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="../advaiet_corporate">Business registration</a></li>
                                    <li><a className="dropdown-item" href="#">Management S/w</a></li>
                                </ul>
                            </div>
                            
                            
                            <div id="user_opt_desk" className="header-bottom-set dropdown">
                               
                            </div>
                            
                            <a href="#offcanvas-cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                                <i className="ion-android-cart"></i>
                                <span className="header-action-num _cart_elm_count"></span>
                                 {/* <span className="cart-amount _cart_elm_sun"></span>  */}
                            </a>
                            <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu"></i>
                            </a>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        
        <div className="header-bottom border-box-21 d-lg-none sticky-nav bg-white">
            <div className="container position-relative">
                <div className="row align-self-center">
                    
                    <div className="col-auto align-self-center d-flex">
                        <div className="header-logo">
                            <a href="/"><img src={logoImg} alt="Site Logo" /></a>
                        </div>
                        <div className="header-top-curr dropdown pl-2">
                                <button id="cmb_location" name="cmb_location" className="dropdown-toggle pr-0 border-0 cmb_location" data-bs-toggle="dropdown"><i class="fa fa-map-marker fa-1x icon-themecolor" aria-hidden="true"></i>Indore</button>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a className="dropdown-item" href="javascript:void(0)">Indore</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0)"></a></li>
                                </ul>
                            </div>
                    </div>
                    
        
                    
                    <div className="col align-self-center" style={{"justify-content":"flex-end"}}>
                        <div className="header-actions">
                            <div className="header_account_list">
                                <a href="javascript:void(0)" className="header-action-btn search-btn"><i
                                        className="icon-magnifier"></i></a>
                                <div className="dropdown_search">
                                    <form className="action-form" action="#">
                                        <input id="txtSearchHead_sm" name="txtSearchHead_sm" className="form-control _setSearchKey" placeholder="Enter your search key" type="text" />
                                        <button className="submit" type="submit"><i className="icon-magnifier"></i></button>
                                    </form>
                                </div>
                            </div>
                            
                            <div id="user_opt_mob" className="header-bottom-set dropdown">
                            </div>
                            
                            <a href="#offcanvas-cart" className="header-action-btn header-action-btn-cart offcanvas-toggle pr-0">
                                <i className="ion-android-cart"></i>
                                <span className="header-action-num _cart_elm_count"></span>
                                {/* <span className="cart-amount _cart_elm_sun"></span>  */}
                            </a>
                            <a href="#offcanvas-mobile-menu" className="header-action-btn header-action-btn-menu offcanvas-toggle d-lg-none">
                                <i className="icon-menu"></i>
                            </a>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div className="green d-none d-lg-block sticky-nav">
            <div className="container position-relative">
                <div className="row">
                    <div className="col-md-12 align-self-center">
                        <div className="main-menu manu-color-white">
                        <a href="#offcanvas-mobile-menu" style={{"line-height":"40px","padding-right":"10px"}} className="header-action-btn white header-action-btn-menu offcanvas-toggle">
                                <i className="icon-menu"></i>
                            </a>
                            <ul id="menu_opt">
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        </div>
        <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
        <button className="offcanvas-close"></button>
        <div className="inner customScroll">
        
            <div className="offcanvas-menu mb-4">
                <ul id="mob_menu">
                </ul>
            </div>
        </div>
        </div>
        </div>
        )
    }
}
export default Front_Header;