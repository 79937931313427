import React from 'react';
//import {new_enc_dec_m} from '../function_lib/common_lib';
export const Comp_Vendor_Dashboard=(props)=>{
return ( <div className="row mt-4">
     {/* invoice order view */}
     <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-warning">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cogs fa-3x" aria-hidden="true"></i></span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Profile Activation"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                  <a className="nav-link" data-toggle="modal"  data-target="#divBasicDetails" href="javascript:void(0)">Basic Details
                  <span className="float-right badge bg-primary">{"pending"}</span>
                  </a>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link" data-toggle="modal"  data-target="#divBankDetails" href="javascript:void(0)">Bank Details <span className="float-right badge bg-danger">{"pending"}</span></a>
                  </li>
                  <li className="nav-item">
                    <a href="javascript:void(0)" className="nav-link" data-toggle="modal"  data-target="#divEntityDoc">
                    Entity Documents Upload <span className="float-right badge bg-success">{"9"}</span>
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                    Signin Documents<span className="float-right badge bg-success">{"27"}</span>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          {/* end of invoice order view */}


{/* recent orders */}
{/* <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header card-primary">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Recent Orders"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                  <a className="nav-link" href="../user_order">Today
                  <span className="float-right badge bg-primary">{"pending"}</span>
                  </a>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link" href="../user_order">Yesterday <span className="float-right badge bg-danger">{"pending"}</span></a>
                  </li>
                  <li className="nav-item">
                    <a href="../user_order" className="nav-link">
                    Weekly <span className="float-right badge bg-success">{"9"}</span>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div> */}
{/* end of recent orders */}

{/* Action To DO */}
<div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header  card-primary bg-info112">
                <div className="widget-user-image">
                 <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Action To Do"}</h5>
                {/* <p class="card-text">Important to do</p> */}
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                <li className="nav-item">
             <a className="nav-link" href="../home_collection">Pending Home Collections
             <span className="float-right badge bg-primary">{"pending"}</span>
             </a>
             </li>
                  <li className="nav-item">
                  <a className="nav-link" href="../user_order">Pending orders
                  <span className="float-right badge bg-primary">{"pending"}</span>
                  </a>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link" href="../user_order">Pending Results <span className="float-right badge bg-danger">{"pending"}</span></a>
                  </li>
                  <li className="nav-item">
                    <a href="../user_order" className="nav-link">
                    Pending Submissions <span className="float-right badge bg-success">{"9"}</span>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
{/* end of Action to DO */}

{/* 4th Item */}
<div className="col-md-4">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Business Page Activation</h5>
<p class="card-text">Activate your online presence</p>
</div>
<ul class="list-group list-group-flush">
<li class="list-group-item"><a href="javascript:void(0)" data-toggle="modal"  data-target="#divWorkHours"><i className="fa fa-info-circle"></i>Opening/Working Hours</a></li>
{/* <li class="list-group-item"><a href="javascript:void(0)" data-toggle="modal"  data-target="#divWorkHours"><i className="fa fa-info-circle"></i>Landing Page Images</a></li> */}
<li class="list-group-item gion"><a href="../package_submit"><i className="fa fa-tags"></i>Business Packages Activation</a></li>
<li class="list-group-item gion"><a href="../vendor_advaiet_money"><i className="fa fa-shopping-bag"></i>Advaiet Money</a></li>
{/* <li class="list-group-item">Business Page Activation</li> */}
</ul>
</div>

</div>
{/* 5th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Accounts and Settlments</h5>
<p class="card-text">Year wise status/records</p>
</div>
<ul class="list-group list-group-flush">

<li class="list-group-item gion"><a href="../merchant_payments"><i className="fa fa-file"></i>Pending Approvals</a></li>
<li class="list-group-item gion"><a href="../merchant_payments"><i className="fa fa-bank"></i>Pending settlments</a></li>
{/* <li class="list-group-item gion"><a href="javascript:void(0)"><i className="fa fa-file"></i>Weekly settled amount</a></li> */}
<li class="list-group-item gion"><a href="../merchant_payments"><i className="fa fa-file"></i>Settlment summery</a></li>
</ul>
</div>

</div>
{/* 6th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Your Advaiet Consultant</h5>
<p class="card-text">Assigned:</p>
</div>
<ul class="list-group list-group-flush">
<li class="list-group-item gion"><a target="blank" href="https://test.advaiet.com"><i class="fa-solid fa-arrow-right-to-bracket"></i>Login to Advaiet S/w</a></li>

</ul>
{typeof props.taxConsultant!=="undefined"?props.taxConsultant.map((item,index)=>{
return <ul class="list-group list-group-flush">

<li class="list-group-item">Name :{item.first_name}</li>
<li class="list-group-item">Email :{item.email_id}</li>
<li class="list-group-item">A third item</li>
</ul>

}):''}

</div>

</div>

</div>)
}
export default Comp_Vendor_Dashboard;