import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod_CBack,RespBkMthdMapp,AjaxCallMethod,listClock,
    btnSetColor_CBack,CallMapMethod} from '../function_lib/common_lib';
import {StateMaster} from '../function_lib/city_india';
import User_Header from './user_header';
import User_Footer from './user_footer';
import Mobile_Widgets from '../sample_comp/mobile_widgets';
import Comp_Dist_Adv_Money from '../user_components/comp_dist_adv_money';







import pdfIcon from '../assets/images/pdf_icon.png';
import './common.css';

let logInObj;
let stateList;

let lsClock=[];

let clsVenAdvMoney;
class Vendor_Advaiet_Money extends React.Component{
    
forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!=null){
            //logInObj=JSON.parse(localStorage.getItem("userDS"));
            logInObj=getUserObjData();
          }
          clsVenAdvMoney=this;
          this.state={stSoftAmount:[],stAdvMoney:[],
            stMoneyList:[],stVendorId:0,stVendor:{}}
          stateList=StateMaster();
          lsClock=listClock(15);
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }
     /* Methods used for create Invoice */
     updatePaymentStatus(response,vref){
        //alert("On status update =="+vref);
        var map=[];
            map.push({name:"curl_type",value:"checkPaymentStatus"});
            map.push({name:"res_func",value:"callBackPUpd"});
            map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
            map.push({name:"invoice_id",value:vref});
            map.push({name:"status",value:"Paid"});
            map.push({name:"payment_trans_id",value:response.razorpay_payment_id})
            map["res_func"]="callBackPUpd";
            map["curl_type"]="checkPaymentStatus";
            CallMapMethod(clsVenAdvMoney,map);
      }
      callBackPUpd(data,resmsg){
        //alert(data+resmsg);
        if(data!=="0" && data!==""){
         //window.location="orderConfirmation?rf="+data;
         alert("Payment accepted successfully, and advaiet money added to your account");
        }
      }
    payAdvMoney(){
        //alert("i m in paymn");
        btnSetColor_CBack(clsVenAdvMoney,"frmVenAdvMoney","createVendorAdvaietMoney",function(data,msg){
            if(data!=="0"){
               let finalAmt=$("#inv_amount").val();
                /* Go for Payment Gateway */
                        /* Check payment gateway */
                       //if($("#payment_mode").val()==="online")
                       {
                         let p_contactNo="+91"+$("#contact_no").val();
             
                         const script1 = document.createElement("script");
                         script1.src = "https://checkout.razorpay.com/v1/checkout.js";
                         script1.async = true;
                        
                         script1.onload = () =>{
                           var options = {
                            "key":"rzp_test_qZSVx89ATLcVHd",
                             //"key": "rzp_live_9jpRWSg5EIxaVl", // Enter the Key ID generated from the Dashboard
                             "amount": finalAmt*100,
                             "currency": "INR",
                             "description": "Advaiet Health Care-Hemo Dialysis",
                             "image": "https://advaiet.com/logo.png",
                             "prefill":
                             {
                               "email": "care@advaiet.com",
                               "contact": p_contactNo,
                             },
                             config: {
                               display: {
                                 blocks: {
                                   hdfc: { //name for HDFC block
                                     name: "Pay using HDFC Bank",
                                     instruments: [
                                       {
                                         method: "card",
                                         issuers: ["HDFC"]
                                       },
                                       {
                                         method: "netbanking",
                                         banks: ["HDFC"]
                                       },
                                     ]
                                   },
                                   other: { //  name for other block
                                     name: "Other Payment modes",
                                     instruments: [
                                       {
                                         method: "card",
                                         issuers: ["ICIC"]
                                       },
                                       {
                                         method: 'netbanking',
                                       }
                                     ]
                                   }
                                 },
                                 // hide: [
                                 //   {
                                 //   method: "upi"
                                 //   }
                                 // ],
                                 sequence: ["block.hdfc", "block.other"],
                                 preferences: {
                                   show_default_blocks: true // Should Checkout show its default blocks?
                                 }
                               }
                             },
                             "handler": function (response) {
                               alert("Order Submitted Successfully");
                               //redirectConfirmation="orderConfirmation?rf="+data;
                               clsVenAdvMoney.updatePaymentStatus(response,data);
                               //window.location="orderConfirmation?rf="+data;
                               //alert(JSON.stringify(response));
                               //alert(response.razorpay_payment_id);
                             },
                             "modal": {
                               "ondismiss": function () {
                                 if (window.confirm("Are you sure, you want to close the form?")) {
                                   var txt = "You pressed OK!";
                                   console.log("Checkout form closed by the user");
                                 } else {
                                   var txt = "You pressed Cancel!";
                                   console.log("Complete the Payment")
                                 }
                               }
                             }
                           };
                     
                     
                           var rzp1 = new window.Razorpay(options);
                           rzp1.open();
                         };
                       
                         document.body.appendChild(script1);
                       }
                    //    else{
                    //      alert("Order Submitted Successfully");
                    //      window.location="orderConfirmation?rf="+data;
                    //    }
                        
             
                 
                 //alert("Payment testing");
                    
             
                       
                      }else{
                       alert(msg);
                      }
        })
    }
   

   getAdvMoney(){
    let map=[];
	map.push({name:"curl_type",value:"getVenTotalAdvaietMoney"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:logInObj[0]["app_user_id"]});
  map.push({name:"local_token",value:logInObj[0]["id"]});
    
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getVenTotalAdvaietMoney";
    CallMapMethod_CBack(this,map,function(data,msg){
        //alert(data+msg);
        if(data!=="-001" && data!=="0"){
            let lsData=JSON.parse(data);
            //alert(JSON.stringify(lsData.total_inv));
            clsVenAdvMoney.setState({stSoftAmount:lsData.total_inv,stAdvMoney:lsData.total_money,
            stMoneyList:lsData.list_money},function(){
                clsVenAdvMoney.forceUpdateHandler();
                
            })
        }
    })

   }
   exportCsv(){

    let map=[];
	map.push({name:"curl_type",value:"getMerchantPayments"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:logInObj[0]["app_user_id"]});
    map.push({name:"local_token",value:logInObj[0]["id"]});
    map.push({name:"isCSV",value:"true"});
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getMerchantPayments";
    CallMapMethod_CBack(this,map,function(data,msg){
        //alert(data+msg);
        if(data!=="-001" && data!=="0"){
           
    let csvContent = atob(data);
var blob = new Blob([csvContent], {type: "data:application/octet-stream;base64"});
var url  = window.URL.createObjectURL(blob);
// you need to precise a front-end button to have a name
var link = document.createElement("a");
link.setAttribute("href", url);
let getDt=new Date();
link.setAttribute("download", "_data" + getDt.getDate()+"_"+getDt.getMonth()+"_"+getDt.getFullYear()+"_"+"_"+getDt.getHours()+"_"+getDt.getMinutes() + ".csv");
link.click();
            
        }
    })
   }

   onClickDetails(data){
    clsVenAdvMoney.setState({stVendorId:data.encKey,stVendor:data});
   }
  

    componentDidMount(){
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
       
        this.getAdvMoney();
        AjaxCallMethod(this,"okSuccCNOT");
    }
    render(){
       
        return(<div>
            <div id="tmpSt"></div>
               <User_Header />
             
<div className="container">



<div className="row">
{/* 4th feature */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Track Advaiet Money</h5>
<p class="card-text"></p>
</div>
<ul class="list-group list-group-flush">
<li class="list-group-item"><a href="javascript:void(0)" data-toggle="modal"  data-target="#divWorkHours"><i className="fa fa-info-circle"></i>Check distributed money</a></li>
<li class="list-group-item"><a href="javascript:void(0)" data-toggle="modal"  data-target="#divWorkHours"><i className="fa fa-info-circle"></i>Check purchase history</a></li>

</ul>
</div>

</div>
{/* end of 4th feature */}

{/* 5th feature */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Purchase Advaiet Money</h5>
<p class="card-text">Advaiet money will provide you same amount as wallet money, and you can allow same discount amount to online users.</p>
</div>
<div class="modal-body">
<form id="frmVenAdvMoney" name="frmVenAdvMoney" className="formarea">
    <input type="hidden" id="gb_token" name="gb_token" value={logInObj[0]["app_user_id"]} />
    <input type="hidden" id="loc_token" name="loc_token" value={logInObj[0]["app_user_id"]}/>
    <input type="hidden" id="curl_type" name="curl_type" value="createVendorAdvaietMoney" />
    <input type="hidden" id="m_type" name="m_type" value="createVendorAdvaietMoney" />
    <input type="hidden" id="res_func" name="res_func" value="oksucc"/>

<div className="form-group">
              <label >Registered Contact No*</label>
                <input type="text" id="contact_no" name="contact_no" className=" required" />
            </div>
            <div className="form-group">
              <label>Payable Money*</label>
                <select id="inv_amount" name="inv_amount" className="form-control form-control-sm">
                    <option value="1000">Rs. 1,000/-</option>
                    <option value="2000">Rs. 2,000/-</option>
                    <option value="3000">Rs. 3,000/-</option>
                    <option value="5000">Rs. 5,000/-</option>
                    <option value="10000">Rs. 10,000/-</option>
                    <option value="20000">Rs. 20,000/-</option>
                </select>
            </div>   
            <div className="form-group">
                <button type="button" onClick={()=>this.payAdvMoney()} class="btn btn-primary">Pay Now</button>
            </div>         
</form>
</div>
<ul class="list-group list-group-flush">
{/* <li class="list-group-item"><a href="javascript:void(0)" data-toggle="modal"  data-target="#divWorkHours"><i className="fa fa-info-circle"></i>Increase your customers</a></li>
<li class="list-group-item"><a href="javascript:void(0)" data-toggle="modal"  data-target="#divWorkHours"><i className="fa fa-info-circle"></i>Increase your online presence</a></li>
<li class="list-group-item gion"><a href="../package_submit"><i className="fa fa-tags"></i>Advaiet Money Discount</a></li>
<li class="list-group-item gion"><a href="../vendor_advaiet_money"><i className="fa fa-shopping-bag"></i>100% money uses</a></li> */}

</ul>
</div>

</div>
{/* end of 5th feature */}

 {/* invoice order view */}
 <div className="col-md-4 pb-20px pt-20px">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-warning">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cogs fa-3x" aria-hidden="true"></i></span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Available Advaiet Money"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                  <a className="nav-link" data-toggle="modal"  data-target="#divBasicDetails" href="javascript:void(0)">Available Balance for s/w usage
                  <span className="float-right badge bg-success">Rs.{this.state.stSoftAmount.length>0?this.state.stSoftAmount[0]["net_amount"]:"0.00"}</span>
                  </a>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link" data-toggle="modal"  data-target="#divBankDetails" href="javascript:void(0)">Available Distributable Money <span className="float-right badge bg-danger">Rs.{this.state.stAdvMoney.length>0?this.state.stAdvMoney[0]["available_amount"]:"0.00"}</span></a>
                  </li>
                 
                  {/* <li className="nav-item">
                    <a href="#" className="nav-link">
                    Signin Documents<span className="float-right badge bg-success">{"27"}</span>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* place buttons for distribute advaiet money */}
           
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header card-primary">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cogs fa-3x" aria-hidden="true"></i></span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Available Advaiet Money"}</h5>
              </div>
              <div className="card-footer p-2">
                
                {this.state.stAdvMoney.length>0?<p className="card-text">
                  You can attract your customer and increase your business as you have available <b>ADVAIET MONEY {this.state.stAdvMoney[0]["available_amount"]} </b>and 
                  you can distribute to <b>{this.state.stAdvMoney[0]["available_amount"]/50} user(s) as Rs. 50 each.</b><br></br>
                  <a href="javascript:void(0)" data-toggle="modal"  data-target="#divDistAdvMoney">click here to distribute</a>
                </p>:<p className="card-text">No advaiet money available</p>
                }
                 <p>
                 {this.state.stAdvMoney.length>0}
                  
                 </p>
                 
                 
                
              </div>
            </div>
            {/* end of distribute moeny system */}
          </div>
          {/* end of invoice order view */}
</div>



</div>
{/* <Comp_Approve_Packages userObject={logInObj} stateList={stateList} vendorId={this.state.stVendorId} vendor={this.state.stVendor} /> */}
<Comp_Dist_Adv_Money userObject={logInObj} adv_money_list={this.state.stMoneyList} />
               {/* <Mobile_Widgets/> */}
               <User_Footer/>
        </div>)
    }
}
export default Vendor_Advaiet_Money; 