import $ from 'jquery';
import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';

let appPathCMS="";
class Comp_Blog_Land extends React.Component{
    forceUpdateHandler(){
        this.forceUpdate();
      };
    constructor(props){
        super(props);
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
        appPathCMS=appGetUrlPath();
    }
    componentWillReceiveProps(props){
        this.props=props;
        //alert("child props"+JSON.stringify(this.props.blogData));
        //alert(this.props.blogData["col4"]);
        this.forceUpdateHandler();
        $("#blog_landing").html(this.props.blogData["col2"]+this.props.blogData["col3"]);
    }
    componentDidMount(){

    }
    render(){
        return(<div className="blog-posts pt-70px">
       
        <div className="single-blog-post  blog-grid-post">
            <div className="blog-post-media">
                <div className="blog-image single-blog">
                    <img src={appPathCMS+this.props.blogData["col4"]} alt="blog" />
                </div>
            </div>
            <div className="blog-post-content-inner mt-6">
                <div className="blog-athor-date">
                    {/* <a className="blog-date" href="#">14 November</a> */}
                </div>
                <h4 className="blog-title"><a href="javascript:void(0)">{this.props.blogData["col1"]}</a></h4>
                <p id="blog_landing">
                 
                </p>
            </div>
            
        </div>
        
    </div>)
    }
}
export default Comp_Blog_Land;