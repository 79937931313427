import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod_CBack,RespBkMthdMapp,AjaxCallMethod,listClock} from '../function_lib/common_lib';
import {StateMaster} from '../function_lib/city_india';
import User_Header from './user_header';
import User_Footer from './user_footer';
import Mobile_Widgets from '../sample_comp/mobile_widgets';
import Comp_Emp_Dashboard from '../user_components/comp_emp_dashboard';
import Comp_Vendor_Dashboard from '../user_components/comp_vendor_dashboard';
import Comp_Admin_Dashboard from '../user_components/comp_admin_dashboard';

import Comp_Approve_Packages from '../user_components/comp_approve_packages';
import Comp_Merchant_Payment_List from '../user_components/comp_merchant_payment_list';
import Reports_Search_Filter from '../component_version2/reports_search_filter';


import pdfIcon from '../assets/images/pdf_icon.png';
import './common.css';

let logInObj;
let stateList;
let clsVendorList;
let lsClock=[];

// parameters for filters
let fltStatus=[{value:"0",display:"-Status-"},
{value:"Pending Approval",display:"Pending Approval"},
{value:"Pending Payment",display:"Pending Payment"},
{value:"Completed Payment",display:"Completed Payment"}];
let isFromDate=true;
let isToDate=true;
// end of parameters for Filters

class Merchant_Payments extends React.Component{
    
forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!=null){
            //logInObj=JSON.parse(localStorage.getItem("userDS"));
            logInObj=getUserObjData();
          }
          clsVendorList=this;
          this.state={stVendorList:[],stVendorId:0,stVendor:{}}
          stateList=StateMaster();
          lsClock=listClock(15);
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }
   onSearchClick(){
    let map=[];
	map.push({name:"curl_type",value:"getMerchantPayments"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:logInObj[0]["app_user_id"]});
    map.push({name:"local_token",value:logInObj[0]["id"]});
    
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getMerchantPayments";
    CallMapMethod_CBack(this,map,function(data,msg){
       // alert(data+msg);
        if(data!=="-001" && data!=="0"){
            let lsData=JSON.parse(data);
            clsVendorList.setState({stVendorList:lsData},function(){
                clsVendorList.forceUpdateHandler();
                
            })
        }
    })
   }
   getVendorList(){
    let map=[];
	map.push({name:"curl_type",value:"getMerchantPayments"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:logInObj[0]["app_user_id"]});
    map.push({name:"local_token",value:logInObj[0]["id"]});
    
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getMerchantPayments";
    CallMapMethod_CBack(this,map,function(data,msg){
       // alert(data+msg);
        if(data!=="-001" && data!=="0"){
            let lsData=JSON.parse(data);
            clsVendorList.setState({stVendorList:lsData},function(){
                clsVendorList.forceUpdateHandler();
                
            })
        }
    })

   }
   exportCsv(){

    let map=[];
	map.push({name:"curl_type",value:"getMerchantPayments"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:logInObj[0]["app_user_id"]});
    map.push({name:"local_token",value:logInObj[0]["id"]});
    map.push({name:"isCSV",value:"true"});
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getMerchantPayments";
    CallMapMethod_CBack(this,map,function(data,msg){
        //alert(data+msg);
        if(data!=="-001" && data!=="0"){
           
    let csvContent = atob(data);
var blob = new Blob([csvContent], {type: "data:application/octet-stream;base64"});
var url  = window.URL.createObjectURL(blob);
// you need to precise a front-end button to have a name
var link = document.createElement("a");
link.setAttribute("href", url);
let getDt=new Date();
link.setAttribute("download", "_data" + getDt.getDate()+"_"+getDt.getMonth()+"_"+getDt.getFullYear()+"_"+"_"+getDt.getHours()+"_"+getDt.getMinutes() + ".csv");
link.click();
            
        }
    })

   
   }
   onClickDetails(data){
    //alert(data);
    //alert(JSON.stringify(data));
    clsVendorList.setState({stVendorId:data.encKey,stVendor:data});
   }
  

    componentDidMount(){
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
       
        this.getVendorList();
        AjaxCallMethod(this,"okSuccCNOT");

        document.body.appendChild(script);
         const script2 = document.createElement("script");
         script2.src = "/specific_listing.js";
         script2.async = true;
         document.body.appendChild(script2);
    }
    render(){
       
        return(<div>
            <div id="tmpSt"></div>
               <User_Header />
             
<div className="container">

{/* <div class="parent_tb">
 <div class="line_tb">
<div id="l_pro_counts" class="left_tb">
                <h5 class="card-title">Document List</h5>
            </div>
    </div>
    </div>  */}

    <div className="row mt-5">
       
        <Reports_Search_Filter statusList={fltStatus} 
        filters={[]}
        isFromDate={isFromDate} 
        isToDate={isToDate}
        onSearchClick={this.onSearchClick} 
        onExportClick={this.exportCsv}
         />
        
        <div className="col-md-8 col-lg-8 order-2 order-md-1">
            <h4>Order List</h4>
        <Comp_Merchant_Payment_List lsVenPaymentList={this.state.stVendorList}/>
        </div>

    </div>


{/* <table class="dtgrid set-min-height table table-bordered table-hover text-sm text-nowrap table-responsive">
    <thead>
      <tr class="border">
          <th>S.No.</th>
          <th>Invoice No.</th>
          <th>Inv Amount</th>
          <th>% Ded.</th>
          <th>Ded. Amount</th>
          <th>Net Payable</th>
          <th>Vendor Name</th>
         <th>Date</th>
         <th>Status</th> 
         <th>Action</th>
      </tr>
    </thead>
    <tbody id="tbData">
      {(this.state.stVendorList.map((item,index)=>{
          return(<tr>
              <td>{index+1}</td>
              <td>{item["invoice_no"]}</td>
              <td>{item["invoice_amt"]}</td>
              <td>{item["per_deduct"]+"%"}</td>
              <td>{item["com_deduct"]}</td>
              <td>{item["net_payable"]}</td>
              <td>{item["company_name"]}</td>
              <td>{item["entry_date"]}</td>
              <td>{item["status"]}</td>
              <td>
              <div className="main-menu manu-color-white">
          <ul>
            <li class="dropdown">
          <a className="font-sm" href="#"><span className="badge bg-primary">Action <i class="ion-ios-arrow-down"></i></span></a>
          <ul class="sub-menu">
          <li><a class="dropdown-item" data-toggle="modal" data-target="#divApprovePackages" href="javascript:void(0)" onClick={()=>this.onClickDetails(item)}><i class="fa fa-pencil text-inverse m-r-10">Approve</i></a></li>
          <li><a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#divQustMapping" onClick={()=>this.setQuestMapData(item)}>View.</a></li>
          commentted  <li><a class="dropdown-item" href="javascript:void(0)" onClick={()=>this.setDeleteLoanDoc(item)}><i class="fa fa-window-close text-danger">Delete</i></a></li> 
            </ul>
            </li>
            </ul>
            </div>
              </td>
             
          </tr>)
      }))}
    </tbody>
  </table>
 */}

  </div>

<Comp_Approve_Packages userObject={logInObj} stateList={stateList} vendorId={this.state.stVendorId} vendor={this.state.stVendor} />
               {/* <Mobile_Widgets/> */}
               <User_Footer/>
        </div>)
    }
}
export default Merchant_Payments; 