import $ from 'jquery';
import React from 'react';
import Cart_Canvas from '../sample_comp/cart_canvas';
let cartItems;
class User_Footer extends React.Component{
    componentDidMount(){
        cartItems=localStorage.getItem("_cart");
        
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
          cartItems=JSON.parse(cartItems);
          //alert("i am in cart item "+cartItems.length);
          $("._cart_elm_count").html(cartItems.length);
          $("._cart_elm_sun").html("₹"+window.cAmount); 
        }
      }
    render(){
        return(
        <div className="pt-100px">
        <div className="footer-area">
        <div className="footer-container">
            <div style={{padding:"1px 0px 1px 0px"}} className="footer-bottom mobile_footer">
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                      
                        <div className="col-md-12 text-center">
                            <p className="copy-text">Advaiet Healthcare Services</p>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Cart_Canvas/>
        </div>);
    }
}
export default User_Footer;