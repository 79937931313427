import $ from 'jquery';
import React from 'react';
import {CallMapMethod,AjaxCallMethod,btnSetColor_CBack,CallMapMethod_CBack} from '../function_lib/common_lib';
import {getUserObjData} from '../function_lib/userToken';
import {StateMaster} from '../function_lib/city_india';
import Front_Header from './front_header';
import Footer from './footer';
import AOS from 'aos';

let clsObject;
let cartItems;
let finalAmt=0;
let userObject;
let stateList;
let pItemList="";

let couponDiscount=0;

class Step_Two extends React.Component{
    constructor(props){
        super(props);
        cartItems=localStorage.getItem("_cart");
  
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
            cartItems=JSON.parse(cartItems);
        }
        userObject= getUserObjData();
        stateList=StateMaster();
        this.state={stCoupon_data:{},stShipping_data:[],stTax_data:[]
        ,stNTrigger:"",stShipingCost:"0",stGiftCode_otp:[],stGiftCode_data:[]}
        
        //alert(userObject[0].id);
    }

    setParsableItem(){
        pItemList=[];
        for(let i=0;i<cartItems.length;i++){
            pItemList.push({"item_id":cartItems[i].item_rf_id,
            "rate":cartItems[i].item_rate,
            "quantity":1,
            "amount":cartItems[i].item_rate,
            "mrp":cartItems[i].item_rate
        })
        }
     
    }

    /* Methods used for create Invoice */
    updatePaymentStatus(response,vref){
        //alert("On status update =="+vref);
        var map=[];
            map.push({name:"curl_type",value:"checkPaymentStatus"});
            map.push({name:"res_func",value:"callBackPUpd"});
            map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
            map.push({name:"invoice_id",value:vref});
            map.push({name:"status",value:"Paid"});
            map.push({name:"payment_trans_id",value:response.razorpay_payment_id})
            map["res_func"]="callBackPUpd";
            map["curl_type"]="checkPaymentStatus";
            CallMapMethod(clsObject,map);
      }
      callBackPUpd(data,resmsg){
        //alert(data+resmsg);
        if(data!=="0" && data!==""){
         window.location="orderConfirmation?rf="+data;
        }
      }      
    saveInvoice(frmName){
      
        if(typeof cartItems[0].parent_id=="undefined"){
            alert("Invalid Order request kindly remove cart items and add again")
            return;
        }

        let extraElm=[];
        extraElm.push({name:"inv_amount",value:finalAmt});
        extraElm.push({name:"per_discount",value:0});
        extraElm.push({name:"discount",value:0});
        extraElm.push({name:"net_amount",value:finalAmt});
        extraElm.push({name:"collection_status",value:"Pending Accession"});
        extraElm.push({name:"app_user_id",value:cartItems[0].parent_id});

        let tmpCartItems=localStorage.getItem("_cart");
        
        //alert(pItemList);
        clsObject.setParsableItem();
        let tmpStrJson=JSON.stringify(JSON.stringify(pItemList));
        let tmpObject="{\"tt\":["+tmpStrJson+"]}";
        extraElm.push({name:"p_item_list",value:tmpObject});
        
        btnSetColor_CBack(clsObject,frmName,"createAppInvoice",function(data,msg){
           //alert(data);
           //return;
            if(data!=="0"){
               let jsInvoice=JSON.parse(data);
                /* Go for Payment Gateway */
                        /* Check payment gateway */
                       //if($("#payment_mode").val()==="online")
                       {
                         let p_contactNo="+91"+$("#contact_no").val();
             
                         const script1 = document.createElement("script");
                         script1.src = "https://checkout.razorpay.com/v1/checkout.js";
                         script1.async = true;
                        
                         script1.onload = () =>{
                           var options = {
                            "key":"rzp_test_qZSVx89ATLcVHd",
                             //"key": "rzp_live_9jpRWSg5EIxaVl", // Enter the Key ID generated from the Dashboard
                             "amount": parseFloat(jsInvoice.amount)*100,
                             "currency": "INR",
                             "description": "Advaiet Health Care-Hemo Dialysis",
                             "image": "https://advaiet.com/logo.png",
                             "prefill":
                             {
                               "email": "care@advaiet.com",
                               "contact": p_contactNo,
                             },
                             config: {
                               display: {
                                 blocks: {
                                   hdfc: { //name for HDFC block
                                     name: "Pay using HDFC Bank",
                                     instruments: [
                                       {
                                         method: "card",
                                         issuers: ["HDFC"]
                                       },
                                       {
                                         method: "netbanking",
                                         banks: ["HDFC"]
                                       },
                                     ]
                                   },
                                   other: { //  name for other block
                                     name: "Other Payment modes",
                                     instruments: [
                                       {
                                         method: "card",
                                         issuers: ["ICIC"]
                                       },
                                       {
                                         method: 'netbanking',
                                       }
                                     ]
                                   }
                                 },
                                 // hide: [
                                 //   {
                                 //   method: "upi"
                                 //   }
                                 // ],
                                 sequence: ["block.hdfc", "block.other"],
                                 preferences: {
                                   show_default_blocks: true // Should Checkout show its default blocks?
                                 }
                               }
                             },
                             "handler": function (response) {
                               alert("Order Submitted Successfully");
                               //redirectConfirmation="orderConfirmation?rf="+data;
                               clsObject.updatePaymentStatus(response,jsInvoice.refc_key);
                               //window.location="orderConfirmation?rf="+data;
                               //alert(JSON.stringify(response));
                               //alert(response.razorpay_payment_id);
                             },
                             "modal": {
                               "ondismiss": function () {
                                 if (window.confirm("Are you sure, you want to close the form?")) {
                                   var txt = "You pressed OK!";
                                   console.log("Checkout form closed by the user");
                                 } else {
                                   var txt = "You pressed Cancel!";
                                   console.log("Complete the Payment")
                                 }
                               }
                             }
                           };
                     
                     
                           var rzp1 = new window.Razorpay(options);
                           rzp1.open();
                         };
                       
                         document.body.appendChild(script1);
                       }
                    //    else{
                    //      alert("Order Submitted Successfully");
                    //      window.location="orderConfirmation?rf="+data;
                    //    }
                 //alert("Payment testing");
                       
                      }else{
                       alert(msg);
                      }
                      
        },extraElm)
        
    }
    /* End of Invoice */

/*
    okSuccInv(data,datares){
     // alert("on steptow="+data);
      if(data!=="" && data!=="0"){
          window.location.href="https://adlog.isomerlab.in/securePayNow?token="+data;
      }
    }
    */

    getState(){
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getLocation_StNew"});
        mapCat.push({name:"res_func",value:"callBackSt"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"parent_id",value:$("#country").val()});
        mapCat.push({name:"location_type",value:"1"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackSt";
        mapCat["curl_type"]="getLocation_StNew";
        CallMapMethod(clsObject,mapCat);
    }
    callBackSt(data,msg){
        if(data!=="" && data!=="0"){
            var listState=JSON.parse(data);
            var strState="<option value=\"0\">-Select-</option>";
            for(var i=0;i<listState.length;i++){
                strState+="<option value=\""+listState[i]["id"]+"\">"+listState[i]["label"]+"</option>"
            }
            $("#state").html(strState);
        }
    }
    getCountry(){
       
        var mapCat=[];
        mapCat.push({name:"curl_type",value:"getLocation_StNew"});
        mapCat.push({name:"res_func",value:"callBackCntry"});
        mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
        mapCat.push({name:"location_type",value:"0"});
       // mapCat.push({name:"emp_id",value:userObject[0].id});
        mapCat["res_func"]="callBackCntry";
        mapCat["curl_type"]="getLocation_StNew";
        CallMapMethod(clsObject,mapCat);
    }
    callBackCntry(data,msg){
        //alert(data);
        if(data!=="" && data!=="0"){
            var listCountry=JSON.parse(data);
            var strCountry="<option value=\"0\">-Select-</option>";
            for(var i=0;i<listCountry.length;i++){
                strCountry+="<option value=\""+listCountry[i]["id"]+"\">"+listCountry[i]["label"]+"</option>"
            }
            $("#country").html(strCountry);
        }
       // alert(data);
    }

    checkCouponCode(){
        //alert(finalAmt);
        let mapCat=[];
        mapCat.push({name:"curl_type",value:"checkCouponCode"});
        mapCat.push({name:"res_func",value:"callBackProCoupon"});
        mapCat.push({name:"app_token",value:userObject[0]["id"]});
        mapCat.push({name:"m_code",value:$("#coupon_disc").val()});

        mapCat.push({name:"p2",value:finalAmt});
        mapCat["res_func"]="callBackProCoupon";
        mapCat["curl_type"]="checkCouponCode";
        CallMapMethod_CBack(clsObject,mapCat,function(data,msg){
            //alert(data);
            //return;
            if(data!=="0"){
                finalAmt=0;
                let couponData=JSON.parse(data);
                //alert(data);
                couponData=couponData[0];
                //couponDiscount=parseFloat(couponData["amount"]).toFixed(2);
                couponData.amount=parseFloat(couponData["available_amount"]).toFixed(2);
                couponData.item_name="Coupon Discount";
                couponDiscount=couponData.amount;

                clsObject.setState({stCoupon_data:couponData})
                }else{
                    alert("Invalid Coupon Code");
                }
        });
    }

    componentDidMount(){
        clsObject=this;
        //this.getCountry();
        this.getState();
       // alert("check data");
       AOS.init();
       AOS.init({
         once: true
       });
       const script = document.createElement("script");
       script.src = "/main.js";
       script.async = true;
       //script.onload = () => clsObject.scriptLoaded();
     
       document.body.appendChild(script);

       AjaxCallMethod(clsObject,"okSuccCNOT");
       //Init 
       //$("#loc_token").val(userObject[0].id);

    
       //$("#amount").val(finalAmt);
       //alert(finalAmt);
    }
    render(){
        return(<div>
             <Front_Header/>
             {/* <!-- checkout area start --> */}
            <div className="checkout-area pt-100px pb-100px">
            <div className="container">
                <div className="row">
                <div className="col-lg-5 mt-md-30px mt-lm-30px ">
                        <div className="border-box your-order-area">
                            <h3>Your order</h3>
                            <div className="your-order-wrap gray-bg-4">
                                <div className="your-order-product-info">
                                    <div className="your-order-top">
                                        <ul>
                                            <li>Product</li>
                                            <li>Total</li>
                                        </ul>
                                    </div>
                                    <div className="your-order-middle">
                                        <ul>
                                        {(cartItems) ? cartItems.map((item,index)=>{
            
            finalAmt+=parseFloat(item.item_rate);
            window.cAmount=finalAmt;
           return <li><span className="order-middle-left">{item.item_name} X 1</span> <span className="order-price">₹{item.item_rate}</span></li>
                                           }):''
                                    }
                                        </ul>
                                    </div>
                                    <div className="your-order-bottom">
                                    {typeof this.state.stCoupon_data.item_name!=="undefined"?
                                             <ul>
                                                 <li className="your-order-shipping">{this.state.stCoupon_data.item_name}</li>
                                            <li className='shipping_cost'>{-this.state.stCoupon_data.amount}</li>
                                            </ul>:
                                            <ul>
                                            <li className="your-order-shipping">
                                            <input className="form-control" type='text' id="coupon_disc" name="coupon_disc" placeholder='coupon code' />
                                            </li>
                                            <li className="shipping_cost">
                                            <a href="javascript:void(0)"><span onClick={()=>this.checkCouponCode()} className='float-right badge bg-danger'>Apply</span></a>

                                            </li>
                                        </ul>}
                                    </div>
                                    <div className="your-order-total">
                                        <ul>
                                            <li className="order-total">Total</li>
                                            <li>₹{finalAmt-couponDiscount}</li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="border-box col-lg-7">
                        <div className="billing-info-wrap">
                        <form id="frmInvoiceBilling" name="frmInvoiceBilling" enctype="multipart/form-data" method="POST" action="#">
                        <input type="hidden" name="curl_type" id="curl_type" value="createAppInvoice" />  
                <input type="hidden" name="m_type" id="m_type" value="createAppInvoice" />
                <input type="hidden" name="loc_token" id="loc_token" value={userObject[0].id}/>
                
                <input type="hidden" name="gb_token" id="gb_token" value="5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg" />
                <input type="hidden" name="coupon_apply" id="coupon_apply"  value={btoa(JSON.stringify(this.state.stCoupon_data))}/>
                
                <input type="hidden" name="res_func" id="res_func" value="okSuccInv" />
                            <h3>Patient Details</h3>
                            <div className="row">
                            <div className="col-lg-12 col-md-12">
                                    <div className="billing-info mb-4">
                                        <label>Upload Doctor's Prescription (if Any)</label>
                                        {/* <input id="name" name="name" type="text" className="form-control required" /> */}
                                        <input type="file" name="dr_prescription" id="dr_prescription" className="form-control"/>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="billing-info mb-4">
                                        <label>Full Name</label>
                                        <input id="name" name="name" type="text" className="form-control required" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>Country</label>
                                        <select className="select_target form-control required" resp_dest="getState" id="country" name="country">
                                            <option>Select a country</option>
                                            <option selected value="100">India</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-select mb-4">
                                        <label>State</label>
                                        <select id="state" name="state" className="form-control required">
                                           
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-g col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>Town / City</label>
                                        <input id="city" name="city" type="text" className="form-control required" />
                                    </div>
                                </div>
                               
                                <div className="col-lg-6 col-md-6">
                                    <div className="billing-info mb-4">
                                        <label>Postcode / ZIP</label>
                                        <input id="pincode" name="pincode" type="number" className="form-control required" />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="billing-info mb-4">
                                        <label>Street Address</label>
                                        <input className="billing-address form-control required" id="address1" name="address1" placeholder="House number and street name" type="text" />
                                        <input className="form-control required" placeholder="Apartment, suite, unit etc." id="address2" name="address2" type="text" />
                                    </div>
                                </div>
                            </div>
                          
                         
                            <div className="additional-info-wrap">
                                <h4>Additional information</h4>
                                <div className="additional-info">
                                    <label>Order notes</label>
                                    <textarea placeholder="Notes about your order, e.g. special notes for delivery. " name="message"></textarea>
                                </div>
                            </div>
                            <div id="getRegLogn" style={{color:"red"}}></div>
                            <div className="Place-order mt-25">
                                        <button className="btn btn-primary btn-outline-dark current-btn _btnSetColor11" onClick={()=>this.saveInvoice("frmInvoiceBilling")} type="button">Place Order</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
             {/* <!-- checkout area end --> */}
           <Footer/>
           </div>
            )
    }
}
export default Step_Two;