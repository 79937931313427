import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser,btnSetColor_CBack,CallMapMethod_CBack} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Listing from '../sample_comp/product_listing';
import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
/*Image */
import loginPng from '../assets/images/icons/login.png';

import './login_new.css';
//import './animate_o.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let logInObj="";
class Advaiet_Corporate extends React.Component{

  setClickFor(idName){
   
    if(idName==="nav_otp"){
      document.getElementById("lg2").classList.remove("active");
      document.getElementById("lg3").classList.add("active");
    }else{
        
        document.getElementById("lg2").classList.add("active");
        document.getElementById("lg3").classList.remove("active");
      }
    
    //$("#nav_regsi").click();
  }

    constructor(props){
        super(props);
        loc=props.location["pathname"].substr(1);
        if(localStorage.getItem("userDS")!=null){
          logInObj=JSON.parse(localStorage.getItem("userDS"));
        }

        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){

        loadCaptchaEnginge(4,'white','black','lower');
        this.getBusinessCategory();

        clsObject=this;
      var pgMap=[];
      pgMap.push({name:"curl_type",value:"getPgData"});
      pgMap.push({name:"res_func",value:"callBackpgData"});
      pgMap.push({name:"loc",value:loc});
     // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
      //mapCat.push({name:"emp_id",value:userObject[0].id});
      pgMap["res_func"]="callBackpgData";
      pgMap["curl_type"]="getPgData";
      CallCMSMapMethod(clsObject,pgMap);
      AjaxCallMethod(this,"okSuccCNOT");
    }

    onRegisterClk(frmName){
      //alert("i m in click");
      let user_captcha = document.getElementById('user_captcha').value;
      if (validateCaptcha(user_captcha)===true) {
      
      btnSetColor_CBack(clsObject,frmName,"createAppUserP",function(data,datares){
       
        if(data===-10){
          //alert(datares);
          return;
        }
        if(data==="not_validated"){
          $("#getRegLogn").html(datares);
          return;
        }
        if(data!=="0" && data!==""){
          clsObject.setClickFor("nav_otp");
          $("#refotp_id").val(data);
          $(".opt_resp_num").html(datares);
        }else{
          //clsObject.setClickFor("nav_otp");
          $("#getRegLognReg").html(datares);
        }
      })
    }else{
      $("#getRegLognReg").html("Invalid Capatch !");
    }
    }
    okSuccCNOT(data,datares){
      //alert(data);
      if(data==="not_validated"){
        $("#getRegLogn").html(datares);
        return;
      }
      if(data!=="0" && data!==""){
        clsObject.setClickFor("nav_otp");
        let useOTP=JSON.parse(data);
        $("#refotp_id").val(useOTP[0]["id"]);
        $("#refotp_contact_id").val(useOTP[0]["id"]);
        $(".opt_resp_num").html(datares);
      //  $("#getRegResp").html("Login Successfully..");
      //  localStorage.setItem('userDS',data);
      //  window.location.href="/home";




      }else{
        //clsObject.setClickFor("nav_otp");
        $("#getRegLognReg").html("Invalid UserName or Password !");
      }
  }
  okSuccOTP(data,datares){
    if(data==="not_validated"){
      $("#getRegLognOTP").html(datares);
      return;
    }
     if(data!=="0" && data!==""){
        $("#getRegLognOTP").html("Verify Successfully..");
       localStorage.setItem('userDS',data);
       window.location.href="/home";
     }else{
      $("#getRegLognOTP").html("Invalid OTP");
     }
  }
   
  callBackpgData(data,msg){
    //alert("testing of2 "+data);
    //$("#pgComp").html(data);
    var listObj= JSON.parse("{\"access\":"+data+"}");
    //alert("successfully parse");
    //var mainHt=Cart_Canvas();
    var mainHt="";
    if(loc==="home"){
       // mainHt+=Video_Banner_Comp();
    }
    var injextI=0;
    var injCondition=false;
  
    for(var _dlist=0;_dlist<50;_dlist++){
     
        if(listObj.access[_dlist]){
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               mainHt+=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
               // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
            }else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    mainHt+=Features_Slider();
                    //alert(products);
                    mainHt+=products;
                }
           }
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    if(listObj.access.length==0){
        mainHt+=products;
    }
    //alert(mainHt);
    //mainHt+=Product_Hori_Slider();
    $("#pgComp").html(mainHt);
    AOS.init();
    AOS.init({
      once: true
    });
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);
      //this.forceUpdateHandler();
  
}

getBusinessCategory(){
  let map=[];
  map.push({name:"curl_type",value:"getVendorCategory"});
  map.push({name:"res_func",value:"callBackNg"});
  map["res_func"]="callBackNg";
  map["curl_type"]="getVendorCategory";
  CallMapMethod_CBack(clsObject,map,function(data,msg){
    //alert(data);
    let strListCate="<option value=\"0\">Select Business</option>";
    if(data!=="0"){
       let listCategory=JSON.parse(data);
       for(let i=0;i<listCategory.length;i++){
        strListCate+="<option value=\""+listCategory[i]["id"]+"\">"+listCategory[i]["category_name"]+"</option>";
       }
    }
    $("#party_mapping_id").html(strListCate);
  })
}

render(){
    return(<div>
        <Front_Header/>
        <div id="pgComp">
      </div>
      <div class="bg_fram wrapper">
<div className=" container py-4" style={{"position":"relative"}}>
{/* <div class="bg"></div>
 <div class="bg bg2"></div>
<div class="bg bg3"></div> */}
{/* <div className="content"></div> */}
<div className="border-box-2 loginform login-register-tab-list nav11">

<div className="header">
       {/* <img id="logo" src={loginPng} alt="" /> */}
       {/* <p className="formname login-register-tab-list nav"><a id="nav_login" className="text-white active" data-bs-toggle="tab" href="#lg2">
       Register
               </a>
                 </p> */}
       <p className="formtxt"><a id="nav_login" className="text-white active" data-bs-toggle="tab" href="#lg2">Register your business</a></p>
       
       </div>
       <div className="fdiv tab-content">
       <div id="lg3" className="tab-pane">
             <form className="formarea">
             <input type="hidden" name="curl_type" id="curl_type" value="registerVerification" />  
                <input type="hidden" name="m_type" id="m_type" value="registerVerification" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccOTP" />
                <p  style={{color:"red"}} className="opt_resp_num"></p>
                <input  type="number" name="otp_token" id="otp_token"  />
                <input type="hidden" name="refotp_id" id="refotp_id" />
                <div id="getRegLognOTP" style={{color:"red"}}></div>
                <div className="col-md-6 text-right">
                            <div className="payment-link">
                            <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Resend OTP</a></p>
                              
                            </div>
                        </div>
                        <div className="col-md-6 text-left">
                            {/* <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Forget Password.</a></p> */}
                        </div>
                <button className="btn-primary _btnSetColor" type="button"><span>Verify Now</span></button>
             </form>
           </div>
       <div id="lg2" className="tab-pane active">
               <form id="frmRegistration" className="formarea">
               <input type="hidden" name="curl_type" id="curl_type" value="createAppUserP" />  
               <input type="hidden" name="m_type" id="m_type" value="createAppUserP" />
               <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
               
               <div className="form-group">
                               <input className="required" autoComplete="off" type="text" name="company_name" id="company_name" placeholder="Business Name *" />
                               </div>
                               <div className="form-group">
                <input className="required" autoComplete="off" type="text" name="contact_no" id="contact_no" placeholder="Contact No" />
                               </div>
                              
                               <div className="form-group">
                
                <input className="required_email" autoComplete="off" type="text" name="email" id="email" placeholder="Email" />
                               </div>

                               <div className="form-group">
                                <select className="form-control required" id="party_mapping_id" name="party_mapping_id">
                                  <option>Select Business Type</option>
                                </select>
                                </div>
                                <div className="form-group">
                <input className="required" autoComplete="off" type="text" name="person_name" id="person_name" placeholder="Contact Person Name*" />
                               </div>
                                <div className="form-group">
                <input className="required" autoComplete="off" type="text" name="user_name" id="user_name" placeholder="User Name *" />
                               </div>
                               
                               <div className="form-group">
                
                <input className="required" type="password" name="password" id="password" placeholder="Password *" />
                               </div>
                               <div className="form-group">
                
                <input className="required" name="password" placeholder="Re-Enter Password *" type="password" />
                               </div>
                          <div className="form-group" style={{"text-align":"left"}}>
                          <LoadCanvasTemplate />
                          <input id="user_captcha" name="user_captcha" type="text" class="form-control required" />
                            </div>         
                                   
                                 
<div className="row flex-sm-row-reverse">
                    <div className="col-md-8 text-right">
                        <div className="payment-link">
                        <p className="copy-text">Already Registered<a className='linkclass' href="../login"> Click here to Login</a></p>
                          
                        </div>
                    </div>
                    <div className="col-md-6 text-left">
                        {/* <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Forget Password.</a></p> */}
                    </div>
                
                  
                </div>  
                                   
                                   
               <div id="getRegLognReg" style={{color:"red"}}></div>
              
               <p className="bottomtxt"> <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
<label for="vehicle1">By continuing, you agree to our<a href="#" className="linkclass"> Terms and Conditions</a></label></p>
                                       <button className="btn-primary _btnSetColor11" onClick={()=>this.onRegisterClk("frmRegistration")} type="button"><span>Sign Up</span></button>
           </form>
           </div>
      
   </div>
  
</div>
</div>
</div>
<Footer/>
   </div>)
}
}
export default Advaiet_Corporate;
