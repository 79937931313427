import $ from 'jquery';
import React,{useState,useEffect } from 'react';
import {GetImgCertUser_CBack,TableDatePicker,CallMapMethod_CBack,appGetUrlPath} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './comp_upload_basic.css';
let appLoanUrlPath;

export function preEntityDocs(app_token,callback){
  let appUrlPath=appGetUrlPath();
	let map=[];
	map.push({name:"curl_type",value:"getEntityDocs"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:app_token});
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getEntityDocs";
	let returnData=CallMapMethod_CBack(this,map,function(data,msg){
    let lsData=[];
    if(data!=="0"){
      lsData=JSON.parse(data);
    }
    callback(lsData);
    return;
	})
  }
export function UpdEntityDoc(frmName){
 
  GetImgCertUser_CBack(this,frmName,"updEntityDocsP",function(data,msg,userMsg){
    //alert(data+msg+userMsg);
    if(data!=="0"){
      alert("Document added Successfully");
    }
  });
}

export function Comp_Entity_Docs(props){
  const[listEntityDoc,setEntityDoc]=useState([]);
  let appUrlPath=appGetUrlPath();
  //alert(JSON.stringify(props));
  //alert(props.userObject);
  let callingVendorId=0;
  if(props.viewLoadDoc!=="undefined"){
  // alert(props.viewLoadDoc[0].doc_list);
  callingVendorId=props.userObject[0].app_user_id;
  if(typeof props.vendorId!=="undefined"){
   callingVendorId=props.vendorId 
  }
 
  if(callingVendorId!==0){
   
    
  }
  }
  useEffect(()=>{
    preEntityDocs(callingVendorId,function(data){
      setEntityDoc(data);
      //listEntityDoc=data;
      //alert(JSON.stringify(data));
    });
  },[callingVendorId]);
 
  return(<div class="modal" id="divEntityDoc" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">Upload Registration Documents</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="frmEntityDoc" name="frmEntityDoc" className='formarea' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="lsEntityDocs" id="lsEntityDocs" />
        <input type="hidden" name="curl_type" id="curl_type" value={"hidden"}/>
        <input type="hidden" name="app_token" id="app_token" value={props.userObject[0].app_user_id} />
        <input type="hidden" name="hi_docs" id="hi_docs"/>
        <div className="row">
        <div className="col-md-4">
            <div className="form-group">
              <label>Registration Certificate of*</label>
                <select id="col1" name="col1" className="form-control required">
                <option value="0">Select</option>
    <option value="Laboratory">Laboratory</option>
    <option value="Diagnostic">Diagnostic</option>
                </select>
            </div>
         </div>
         <div className="col-md-4">
            <div className="form group">
              <label>Exp Date*</label><br></br>
              <TableDatePicker refName="col2"/>
                
            </div>
         </div>
         <div className="col-md-4">
            <div className="form-group form-row">
              <label>Details </label>
                <input type="text" id="col3" name="col3" className="form-control required" />
            </div>
         </div>
     
         <div className="col-md-12 mb-2">
               {/* upload file 1 */}
               <div className="row">
               <div className="col-md-3">        
               <a href="javascript:void(0)"  setTo="customEntDocs" className="_brFileThumb updthumb">
<div className="card-header">
  {/* <h3 className="card-title center-middle">Upload Documents</h3> */}
  <span>Upload Documents</span>
  <img className="upd-icon img-fluid" src={updFile} />
  <small> (Max File Size 1MB) </small>
  <input type="file" ref_img="ImageThumbBack" assignTo="lsEntityDocs" multiple name="customEntDocs" id="customEntDocs" accept="image/*,application/pdf" className="updImgThumb" />
</div>
    
</a>
</div>
<div className="col-md-8">
<div className="row audioLibThumb imgLibThumb">
                      
                      </div>
</div>

               </div>
               
               
             
</div>
{/* end of file upload */}
           

        </div>       
        </form>
        <div className="row">
			<div className="col-md-12">
				  {listEntityDoc.map((item,index)=>{
             let updFilePath="javascript:void(0)";
             let fileText="";
             if(item.col4.trim()!==""){
              updFilePath=appUrlPath+"entity_dfile/"+item.col4;
              fileText="view";
             }
              return <div class="post box">
             
              <div class="row">
              <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box less-border">
                <span class="info-box-icon bg-warning">{index+1}</span>
  
                <div class="info-box-content">
                  <span class="info-box-text">Certificate:</span>
                  <span class="info-box-number"><b>{item.col1}</b></span>
                </div>
              
              </div>
              
            </div>    
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box less-border">
                {/* <span class="info-box-icon bg-info"><i class="fa fa-envelope"></i></span> */}
  
                <div class="info-box-content">
                  <span class="info-box-text">Details</span>
                  <span class="info-box-number">{item.col3}</span>
                </div>
            
              </div>
            
            </div>
            
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box less-border">
                {/* <span class="info-box-icon bg-danger"><i class="fa fa-star"></i></span> */}
  
                <div class="info-box-content">
                  <span class="info-box-text">Expiry Date</span>
                  <span class="info-box-number">{item.col2}</span>
                </div>
               
              </div>
             
            </div>
            <div class="col-md-3 col-sm-6 col-12">
              <div class="info-box less-border">
                {/* <span class="info-box-icon bg-success"><i class="fa fa-flag"></i></span> */}
  
                <div class="info-box-content">
                  <span class="info-box-text">Uploaded Files</span>
                  <span class="info-box-number"><a href={updFilePath} target="blank" class="link-black text-sm"><i class="fa fa-link mr-1"></i>{fileText}</a></span>
                </div>
               
              </div>
            
            </div>
            
          </div>
             
            </div>
            })}
					
			</div>
		</div>
      </div>
      <div class="modal-footer">
        <button type="button" onClick={()=>UpdEntityDoc("#frmEntityDoc")} class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>);
}
export default Comp_Entity_Docs;