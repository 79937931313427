import React from 'react';
//import {new_enc_dec_m} from '../function_lib/common_lib';
export const Comp_Admin_Dashboard=(props)=>{
return (<div className="row mt-4">
     {/* invoice order view */}
     <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header bg-warning">
                <div className="widget-user-image">
                 <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cogs fa-3x" aria-hidden="true"></i></span>
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Vendor Section"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                <li className="nav-item">
                    <a href="#" className="nav-link gion">
                      <i className="fa fa-info-circle"></i>
                    Pending Completion<span className="float-right badge bg-success">{"27"}</span>
                    </a>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link gion" data-toggle="modal"  data-target="#divBasicDetails" href="javascript:void(0)">
                  <i className="fa fa-info-circle"></i>Pending Activation
                  <span className="float-right badge bg-primary">{"pending"}</span>
                  </a>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link gion" data-toggle="modal"  data-target="#divBankDetails" href="javascript:void(0)">
                  <i className="fa fa-info-circle"></i>Pending Renewals <span className="float-right badge bg-danger">{"pending"}</span></a>
                  </li>
                  <li className="nav-item">
                    <a href="javascript:void(0)" className="nav-link gion" data-toggle="modal"  data-target="#divEntityDoc">
                    <i className="fa fa-info-circle"></i>Pending Registration Certificates <span className="float-right badge bg-success">{"9"}</span>
                    </a>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
          {/* end of invoice order view */}


{/* recent orders */}
{/* <div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header card-primary">
                <div className="widget-user-image">
                
                 <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
                  
                 
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Recent Orders"}</h5>
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)">Today
                  <span className="float-right badge bg-primary">{"pending"}</span>
                  </a>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link" href="javascript:void(0)">Yesterday <span className="float-right badge bg-danger">{"pending"}</span></a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link">
                    Weekly <span className="float-right badge bg-success">{"9"}</span>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div> */}
{/* end of recent orders */}

{/* Action To DO */}
<div className="col-md-4">
            <div className="card card-widget widget-user-2">
              <div className="widget-user-header card-primary bg-info11">
                <div className="widget-user-image">
                 <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
                </div>
                <h3 className="widget-user-username"></h3>
                <h5 className="widget-user-desc">{"Action To Do"}</h5>
                {/* <p class="card-text">Important to do</p> */}
              </div>
              <div className="card-footer p-0">
                <ul className="nav flex-column">
                <li className="nav-item">
             <a className="nav-link" href="../home_collection">Pending Home Collections
             <span className="float-right badge bg-primary">{"pending"}</span>
             </a>
             </li>
                  <li className="nav-item">
                  <a className="nav-link" href="../user_order">Pending orders
                  <span className="float-right badge bg-primary">{"pending"}</span>
                  </a>
                  </li>
                  <li className="nav-item">
                  <a className="nav-link" href="../user_order">Pending Results <span className="float-right badge bg-danger">{"pending"}</span></a>
                  </li>
                  <li className="nav-item">
                    <a href="../user_order" className="nav-link">
                    Pending Submissions <span className="float-right badge bg-success">{"9"}</span>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
{/* end of Action to DO */}

{/* 4th Item */}
<div className="col-md-4 ">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body bg-info11">
<h5 class="card-title">Packages</h5>
<p class="card-text"></p>
</div>
<ul class="list-group list-group-flush">

<li class="list-group-item gion"><i className="fa fa-tags"></i>Service Packages</li>
<li class="list-group-item gion"><i className="fa fa-tags"></i>Vendor Business Page</li>
</ul>
</div>

</div>
{/* 5th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Merchant Payments</h5>
<p class="card-text"></p>
</div>
<ul class="list-group list-group-flush">
<li class="list-group-item gion">
  <a href="../merchant_payments"><i className="fa fa-bank"></i>Make the Payments</a>
  </li>
<li class="list-group-item gion"><i className="fa fa-inr"></i>Payment in Process</li>
<li class="list-group-item gion"><i className="fa fa-file"></i>Setlled Transaction Log</li>
<li class="list-group-item gion"><i className="fa fa-file"></i>Setlled Transaction Reporting</li>
</ul>
</div>

</div>
{/* 6th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Message/Complaints</h5>
<p class="card-text">Assigned:</p>
</div>
{typeof props.taxConsultant!=="undefined"?props.taxConsultant.map((item,index)=>{
return <ul class="list-group list-group-flush">

<li class="list-group-item">Name :{item.first_name}</li>
<li class="list-group-item">Email :{item.email_id}</li>
<li class="list-group-item">A third item</li>
</ul>

}):''}

</div>

</div>

</div>)
}
export default Comp_Admin_Dashboard;