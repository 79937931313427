import  $ from 'jquery';
import React from 'react';
import ImgLogin from '../assets/images/login_img.png';
import {btnSetColor_CBack,CallMapMethod_CBack,HideModal} from '../function_lib/common_lib'; 
import './comp_login.css';
export function setHiddenLoginOtp(modelId){
    HideModal(modelId)
}
export function getLoginOtpAuth(frmName){
    let map=[];
    map.push({name:"curl_type",value:"registerWithOTP"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"p1_contact",value:document.getElementById("frmLoginOtpModal_contact_no").value});
   
	map["res_func"]="callBackNg";
	map["curl_type"]="registerWithOTP"; 
    btnSetColor_CBack(this,frmName,"registerWithOTP",function(data,msg){
        //alert(data);
    if(data==="not_validated"){
        $("#frmLoginOtpModal_getRegLogn").html(msg);
        return;
      }
      if(data!=="0" && data!==""){
       
        //let useOTP=JSON.parse(data);
        $("#frmOtpVerifyModal_refotp_id").val(data);
        //$("#frmOtpVerifyModal_opt_resp_num").val(data);
        $(".frmOtpVerifyModal_opt_resp_num").html(msg);
        HideModal("compLoginOtpModal");
        HideModal("compOtpVerifyModal","show");

      //  $("#getRegResp").html("Login Successfully..");
      //  localStorage.setItem('userDS',data);
      //  window.location.href="/home";
      }else{
        //clsObject.setClickFor("nav_otp");
        $("#frmLoginOtpModal_getRegLogn").html(msg);
      }
    });
}
export const Comp_Login_Otp=(props)=>{
return(<div className="modal fade" id="compLoginOtpModal" tabindex="-1" role="dialog">
    
<div className="modal-dialog modal-dialog-sm" role="document">
    <div className="modal-content">
        <div className="modal-header">
            <button type="button" className="close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">x</span></button>
        </div>
        <div className="modal-body container">
            <p>
        <h5 className="white1 center">Welcome to Advaiet Health Services</h5>
        </p>
            <div className="row">
                <div className="col-md-6 bg-color311 b-radius5 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px  hide-mob">
                    
                    <img src={ImgLogin} style={{"max-width":"90%"}}  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="product-details-content quickview-content">
                        <h2>Login with OTP</h2>
                        <p>Enter your Contact No</p>
                <form id="frmLoginOtpModal" name="frmLoginOtpModal" className="form-control11">
                    <div>
                    <input type="hidden" name="curl_type" id="curl_type" value="registerWithOTP" />  
            <input type="hidden" name="m_type" id="m_type" value="registerWithOTP" />
            <input type="hidden" name="res_func" id="res_func" value="okSuccOTP" />
                    </div>
                    <div className="row">
                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                        <div className="form-group">
                            {/* <label>Contact No</label> */}
                            <input className="form-control form-control-sm no-input-border-sm required" autocomplete="off" type="text" id="frmLoginOtpModal_contact_no" name="frmLoginOtpModal_contact_no" placeholder="Contact No." />
                        </div>
                     </div>
                    
                    
                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                     <div id="frmLoginOtpModal_getRegLogn" style={{color:"red"}}></div>
                        <div className="form-group">
                            <button type="button" onClick={()=>getLoginOtpAuth("frmLoginOtpModal")} className="btn text-center btn-primary btn-hover-primary ml-4 float-right">Verify Now</button>
                        </div>
                     </div>

                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2 d-flex" style={{"flex-direction":"column"}}>
                     <p className="copy-text col"><a className='linkclass' onClick={()=>setHiddenLoginOtp("compLoginOtpModal")} data-toggle="modal" data-target="#compLoginModal" href="javascript:void(0)">Login with Contact No. and Password</a></p>
                     <p className="copy-text col float-right"><a className='linkclass' onClick={()=>setHiddenLoginOtp("compLoginOtpModal")} data-toggle="modal" data-target="#compUserRegModal" href="javascript:void(0)">Register as New User.</a></p>
                     </div>
                    </div>
                     </form>
                        
                        
                 </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>)
}
export default Comp_Login_Otp;