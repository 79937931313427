import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod_CBack,RespBkMthdMapp,AjaxCallMethod,listClock} from '../function_lib/common_lib';
import {StateMaster} from '../function_lib/city_india';
import User_Header from './user_header';
import User_Footer from './user_footer';
import Mobile_Widgets from '../sample_comp/mobile_widgets';
import Comp_Emp_Dashboard from '../user_components/comp_emp_dashboard';
import Comp_Vendor_Dashboard from '../user_components/comp_vendor_dashboard';
import Comp_Admin_Dashboard from '../user_components/comp_admin_dashboard';

import Comp_Upload_Basic from '../user_components/comp_upload_basic';
import Comp_Bank_Details from '../user_components/comp_bank_details';
import Comp_Working_Hours from '../user_components/comp_working_hours';
import Comp_Entity_Docs from '../user_components/comp_entity_docs';
import pdfIcon from '../assets/images/pdf_icon.png';
import './common.css';
import './vendor_pro.css';

let logInObj;
let stateList;
let clsVendorPro;
let lsClock=[];


class Vendor_Pro extends React.Component{
    
forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!=null){
            //logInObj=JSON.parse(localStorage.getItem("userDS"));
            logInObj=getUserObjData();
          }
          clsVendorPro=this;
          this.state={stVendorList:[],stVendorId:0}
          stateList=StateMaster();
          lsClock=listClock(15);
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

   getVendorList(){
    let map=[];
	map.push({name:"curl_type",value:"getVendorListP"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:logInObj[0]["app_user_id"]});
    map.push({name:"local_token",value:logInObj[0]["id"]});
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getVendorListP";
    CallMapMethod_CBack(this,map,function(data,msg){
        //alert(data);
        if(data!=="-001" && data!=="0"){
            let lsData=JSON.parse(data);
            clsVendorPro.setState({stVendorList:lsData},function(){
                clsVendorPro.forceUpdateHandler();
            })
        }
    })

   }
   onClickDetails(data){
    //alert(data);
    clsVendorPro.setState({stVendorId:data});
   }

  

    componentDidMount(){
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
       
        this.getVendorList();
        AjaxCallMethod(this,"okSuccCNOT");
    }
    render(){
       
        return(<div>
            <div id="tmpSt"></div>
               <User_Header />
             
<div className="container">
<div className="card card-outline cover-height">
<div className="card-header">
{/* <div class="parent_tb">
 <div class="line_tb">
<div id="l_pro_counts" class="left_tb">
                <h5 class="card-title">Document List</h5>
            </div>
    </div>
    </div>  */}
<div class="parent_tb">
                <div class="line_tb">
            <div id="l_pro_counts" class="left_tb">
            {/* <span class="float-left badge ">
                <select id="serFilterYear" name="serFilterYear" className="form-control year_master">
                </select>
            </span> */}
            {/* <span class="float-left  badge ">
                <select id="serFilterHeader" name="serFilterHeader" className="form-control doc_header">
                </select>
            </span> */}
            <span class="float-left badge ">    
                <input id="serTxtSearch" name="serTxtSearch" type="text" placeholder="search by company,person,contact,email" />
            </span>
            <span class="float-left  badge "> 
            <button type="button" onClick={()=>this.getVendorList("#frmEntityDoc")} class="btn btn-primary">Search</button>
            </span>
            
            </div>
            <div class="right_tb">
          
            </div>
        </div>
                </div>
    </div>
<div class="card-body">

   
      {(this.state.stVendorList.map((item,index)=>{
          return(<div class="callout callout-danger child_desk_thumb">
          <div className="row">
            <div className="col-md-4">
            <h5>{item.company_name+"/"+item.person_name}</h5>
            <p>{item.user_name}</p>
            
            <p>Registration DateTime:{item.date}</p>
            <a href="#" class="link-black text-sm">Contact Person:<b>{item.person_name}</b> </a>
            
            
            </div>
            <div className="col-md-5">
            <div className="main-menu manu-color-white">
          <ul>
            <li class="dropdown">
          <a className="font-sm" href="#"><span className="badge bg-primary">View Vendor Profile <i class="ion-ios-arrow-down"></i></span></a>
          <ul class="sub-menu">
          <li><a class="dropdown-item gion" onClick={()=>this.onClickDetails(item.encKey)} href="javascript:void(0)" data-toggle="modal"  data-target="#divBasicDetails">
          <i class="fa fa-share"></i>Check Basic Profile
          </a></li>
          <li><a class="dropdown-item" onClick={()=>this.onClickDetails(item.encKey)} href="javascript:void(0)" data-toggle="modal"  data-target="#divBankDetails">
          <i class="fa fa-share"></i>Check Bank Details
          </a></li>
          <li><a class="dropdown-item" onClick={()=>this.onClickDetails(item.encKey)} href="javascript:void(0)" data-toggle="modal"  data-target="#divEntityDoc">
          <i class="fa fa-share"></i>Check Documents
          </a></li>
          <li><a class="dropdown-item" target="_blank"  href={"../create_package?rf="+item.encKey}>
          <i class="fa fa-inr"></i>Assign Package
          </a></li>
            </ul>
            </li>
            </ul>
            </div>
            
            
            </div>
            <div className="col-md-3">
            {/* for final sms push */}
            <p><span className="badge bg-success">Last Login: {item.date}</span></p>
            <p><span className="badge bg-danger">Status: {"Pending"}</span></p>
            <p><a href="javascript:void(0)">Check Details</a></p>
            </div>
          </div>     
          </div>
 )
      }))}
   
  


</div>
</div>
</div>

<Comp_Upload_Basic userObject={logInObj}  vendorId={this.state.stVendorId} stateList={stateList}/>
<Comp_Bank_Details userObject={logInObj}  vendorId={this.state.stVendorId} />
<Comp_Working_Hours userObject={logInObj} vendorId={this.state.stVendorId} listClocks={lsClock} />
<Comp_Entity_Docs userObject={logInObj} vendorId={this.state.stVendorId} />
               {/* <Mobile_Widgets/> */}
               <User_Footer/>
        </div>)
    }
}
export default Vendor_Pro; 