import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import User_Header from './user_header';
import User_Footer from './user_footer';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,SetResponseDiv,AjaxCallMethod,RespBkMthdMapp,GetImgCert} from '../function_lib/common_lib';
import {Result_Element,Order_Items} from './order_list_element';
import {Comp_View_Test_Result} from '../user_components/comp_view_test_result';
import TkMeter from '../assets/images/camera.svg';

let clsObject;

let objImages = [];
let objAudio = [];

let arrExistingFiles=[];
let indexExisting=0;
let queries=0; 
let logInObj;
let rf="";
class View_Order_Items extends React.Component{
    constructor(props){
        super(props);
         queries = QueryString.parse(this.props.location.search);
         rf=queries.rf;
         if(localStorage.getItem("userDS")!=null){
            //logInObj=JSON.parse(localStorage.getItem("userDS"));
            logInObj=getUserObjData();
          }
          this.state={stOrderItems:[],stItemsIds:"",stItemCodes:"",stPurchaseIds:""}
    }
    getResults(){
        //let queries = QueryString.parse(this.props.location.search);
        //this.setState(queries); 
        //console.log(queries.rf); 
        //alert(queries.rf);
        var map=[];
        map["res_func"]="callBackNg";
        map["curl_type"]="getTestListofOrder";
        map.push({name:"rf",value:queries.rf});
        CallMapMethod(this,map);
    }
    callBackNg(data,msg){
       // alert(data);
        if(data!=="0"){
            let listData=JSON.parse(data);
            let itemIds="";
            let itemCodes="";
            let purchaseIds="";
            for(let i=0;i<listData.length;i++){
                if(itemIds!==""){
                    itemIds+=",";
                }
                itemIds+=listData[i]["item_id"];

                if(itemCodes!==""){
                    itemCodes+=",";
                }
                itemCodes+=listData[i]["Item Code"];

                if(purchaseIds!==""){
                    purchaseIds+=",";
                }
                purchaseIds+=listData[i]["purchase_item_id"];
            }
            clsObject.setState({stItemsIds:itemIds,stItemCodes:itemCodes,stPurchaseIds:purchaseIds},function(){
                var checkResult=Order_Items(data);
                $("#divOrder").html(checkResult);
            })
            
            //$("#divOrder").html(msg);
        }
      

    }
    ImageThumbBack(flImg, flName,fObject,othInfo="") {
        //alert(flImg);
       
       
        if(othInfo===""){
          othInfo="nm"+indexExisting+","+indexExisting;
        }
        indexExisting++;
        var arrNmValue=othInfo.split(',');
        //arrExistingFiles.push
        arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
        var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
        "<i class=\"icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
          "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
          "</div>";
          if(flImg.indexOf("data:video/webm")!=-1 || flImg.indexOf("data:video/mp4")!=-1){
           strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
            "<i class=\"icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
              "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
              "</div>";
          }
          
        //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
        objImages.push({ name: arrNmValue[1], value: flImg });
        $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");
        //alert(strSet);            
        $(".imgLibThumb").append(strSet);
        if(fObject!==null){
          fObject.val('');
        }
        $('.gallery-item').magnificPopup({
          type: 'image',
          gallery: {
              enabled: true
          }
      });
      RespBkMthdMapp(clsObject, "_removeBtn"+indexExisting, "rmFileProc", "2");
        //imgLibThumb
      }
    componentDidMount(){
        clsObject=this;
        this.getResults();

        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
        AjaxCallMethod(clsObject, "okSuccNot");
    }

    saveResult(){
        GetImgCert(clsObject,"#frmSaveResults","saveResultLog","okSuccSaveRes");
    }
    okSuccSaveRes(data,msg){
        alert(data+msg);
    }

    render(){
        return(<div>
            <User_Header />
            
<div className="container">
<div id="divOrder">

</div>
<a href="javascript:void(0)" data-toggle="modal" data-target="#divViewTestResult">View Test Result</a>
<form id="frmSaveResults" name="frmSaveResults">
<div className='profile-wrapper-area py-3'>
<img  setTo="customFileBr" className="_brFileThumb updthumb mb-3" src={TkMeter} alt=""/>
<input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" className="updImgThumb" />
<input type="hidden" name="lsImages" id="lsImages" />
<input type="hidden" name="curl_type" id="curl_type" value="saveResultLog" />  
                <input type="hidden" name="m_type" id="m_type" value="saveResultLog" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccSaveRes" />
                <input type="hidden" name="invoice_id" id="invoice_id" value={queries.rf} />
<div className="row audioLibThumb imgLibThumb">
                      
</div>
<div className="mb-3">
<div className="title mb-2"><span>Reports/Result</span></div>
<textarea className='form-control'></textarea>
</div>

</div>
<div className="profile-wrapper-area py-3">
                <div className="mb-3">
                  <div className="title mb-2"><span>Status</span></div>
                  <select id="collection_status" name="collection_status" className="form-control">
                      <option value="0">-Select-</option>
                      <option value="Verified">Verified</option>
                      <option value="Approved">Approve</option>
                      <option value="Sample Taken">Back to OrderList</option>
                  </select>
                  
                </div>
                <button className="btn btn-success _btnSetColor" type="button">Submit</button>
                
                    </div>
                    </form>
</div>
<Comp_View_Test_Result userObject={logInObj} pItemIds={this.state.stItemsIds} pItemCodes={this.state.stItemCodes} pPurchaseIds={this.state.stPurchaseIds} vendorId={rf} />
            <User_Footer/>
        </div>)
    }
}
export default View_Order_Items;