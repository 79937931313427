import React from 'react';
import {appGetUrlPath} from '../function_lib/common_cms';
import './feature_explore_comp.css';
const Feature_Explore_Comp=(props)=>{
    let appUrtPath=appGetUrlPath();
    let lsData=props.lsData;
    return (
        <section class="py-4">
        {/* <!-- start  --> */}
        <div class="container">
            <div class="heading">
            <h3 class="mb-3 title">Explore Advaiet Health Care</h3>
            </div>
            <div class="row justify-content-center flx_container">
        {lsData!=="undefined"?lsData.map((item,index)=>{
            return(<div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col">
                
            <div class="box col-item hover-image">
            
                <div class="d-flex justify-content-between">
                    <h6 class="mb-0 title">{item.col1}
                    {/* <span class="badge bg-secondary bg-purple">New</span> */}
                    </h6>
                    <a href="">
                        <i class="fa fa-tags orange-for"></i>
                    </a>
                </div>
                <p class="myp">{item.col2}</p>
                <a href={item.col7}>
                <img src={appUrtPath+item.col4} class="img-fluid" />
                </a>
            </div>
            
        </div>)
        }):''}

            </div>
        </div>
        </section>        
)
}
export default Feature_Explore_Comp;