import React from 'react';
import ImgBill from '../assets/images/dashboard/bill.png';
import './comp_merchant_payment_list.css';
export const Comp_Merchant_Payment_List=(props)=>{
    return (<div>
        {props.lsVenPaymentList.map((item,index)=>{
            return  <div class="post box">
            <div class="user-block">
              <img class="img-circle img-bordered-sm" src={ImgBill} alt="user image" />
              <span class="username">
                <a href="#">{item.company_name}.</a>
              </span>
              <span class="description">Order No- {item.invoice_no}
              <span className="float-right badge bg-danger">Status:{item.status}</span>
              </span>
            </div>
            <div class="row">
          <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
              {/* <span class="info-box-icon bg-info"><i class="fa fa-envelope"></i></span> */}

              <div class="info-box-content">
                <span class="info-box-text">Invoice Amount</span>
                <span class="info-box-number">₹ {item.invoice_amt}</span>
              </div>
          
            </div>
          
          </div>
          
          <div class="col-md-4 col-sm-6 col-12">
            <div class="info-box">
              {/* <span class="info-box-icon bg-success"><i class="fa fa-flag"></i></span> */}

              <div class="info-box-content">
                <span class="info-box-text">% Service Charges</span>
                <span class="info-box-number">{item.per_deduct}% = Rs:{item.com_deduct}</span>
              </div>
             
            </div>
          
          </div>
          
          {/* <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
              <span class="info-box-icon bg-warning"><i class="fa fa-copy"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Service Charge</span>
                <span class="info-box-number">₹ {item.com_deduct}</span>
              </div>
            
            </div>
            
          </div>
          */}
          <div class="col-md-3 col-sm-6 col-12">
            <div class="info-box">
              {/* <span class="info-box-icon bg-danger"><i class="fa fa-star"></i></span> */}

              <div class="info-box-content">
                <span class="info-box-text">Net Payable</span>
                <span class="info-box-number">₹ {item.net_payable}</span>
              </div>
             
            </div>
           
          </div>
          
        </div>
            <p>
              <a href="#" class="link-black text-sm"><i class="fa fa-link mr-1"></i>View</a>
            </p>
          </div>
        })}
        
    </div>)
}
export default Comp_Merchant_Payment_List;