import  $ from 'jquery';
import React from 'react';
import ImgLogin from '../assets/images/login_img.png';
import {btnSetColor_CBack,CallMapMethod_CBack,HideModal} from '../function_lib/common_lib'; 
import './comp_login.css';
export function setHiddenLogin(modelId){
    HideModal(modelId)
}
export function getLoginAuth(){
    let map=[];
    map.push({name:"curl_type",value:"getAccessToken"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"txtUserName",value:document.getElementById("frmLoginModal_contact_no").value});
    map.push({name:"txtPassword",value:document.getElementById("frmLoginModal_password").value});
    
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getAccessToken"; 
    CallMapMethod_CBack(this,map,function(data,msg){
        if(data==="not_validated"){
            $("#frmLoginModal_getRegLogn").html(msg);
          }else{
 
   if(data!=="0" && data!=="" && data!=="not_validated"){
    $("#frmLoginModal_getRegLogn").html("Login Successfully..");
   localStorage.setItem('userDS',data);
   window.location.href="/home";
  }else{
    $("#frmLoginModal_getRegLogn").html("Invalid UserName or Password !");
  }
      }
    })
}
export const Comp_Login=(props)=>{
return(<div className="modal fade" id="compLoginModal" tabindex="-1" role="dialog">
    
<div className="modal-dialog modal-dialog-sm" role="document">
    <div className="modal-content">
        <div className="modal-header">
        
            <button type="button" className="close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">x</span></button>
        </div>
        <div className="modal-body container">
            <p>
        <h5 className="white1 center">Welcome to Advaiet Health Services</h5>
        </p>
            <div className="row">
                <div className="col-md-6 bg-color311 b-radius5 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px  hide-mob">
                    
                    <img src={ImgLogin} style={{"max-width":"90%"}}  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                    <div className="product-details-content quickview-content">
                        <h2>Login</h2>
                        <p>Enter your Contact No. and Password to Proceed</p>
                <form id="frmLoginModal" name="frmLoginModal" className="form-control11">
                    <div>
                    <input type="hidden" name="curl_type" id="curl_type" value="getAccessToken" />  
            <input type="hidden" name="m_type" id="m_type" value="getAccessToken" />
            <input type="hidden" name="res_func" id="res_func" value="okSuccLogin" />
                    </div>
                    <div className="row">

                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                        <div className="form-group">
                            {/* <label>Contact No</label> */}
                            <input className="form-control form-control-sm no-input-border-sm required" autocomplete="off" type="text" id="frmLoginModal_contact_no" name="frmLoginModal_contact_no" placeholder="Contact No." />
                        </div>
                     </div>
                    
                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                        <div className="form-group">
                            {/* <label>Password</label> */}
                            <input className="form-control form-control-sm no-input-border-sm required" autocomplete="off" type="password" id="frmLoginModal_password" name="frmLoginModal_password" placeholder="password" />
                        </div>
                     </div>
                    
                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                     <div id="frmLoginModal_getRegLogn" style={{color:"red"}}></div>
                        <div className="form-group">
                            <button type="button" onClick={()=>getLoginAuth()} className="btn text-center btn-primary btn-hover-primary ml-4 float-right">Login</button>
                        </div>
                     </div>

                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2 d-flex">
                     <p className="copy-text col"><a className='linkclass' onClick={()=>setHiddenLogin("compLoginModal")} data-toggle="modal" data-target="#compLoginOtpModal" href="javascript:void(0)">Login with OTP</a></p>
                     <p className="copy-text col float-right"><a className='linkclass' onClick={()=>setHiddenLogin("compLoginModal")} data-toggle="modal" data-target="#compUserRegModal" href="javascript:void(0)">Register as New User.</a></p>
                     </div>
                        

                    </div>
                     </form>
                        
                        
                 </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>)
}
export default Comp_Login;