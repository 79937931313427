import React from 'react';
import './home_banner.css';
import { appGetUrlPath } from '../function_lib/common_cms';
import { getRandomImagesPath } from '../function_lib/random_images';
export function Home_Banner_Sm_Landing(lsData){
    var appPath=appGetUrlPath();
    var strResp="";

    let randomImg1=getRandomImagesPath("vendor_landing",9,"jpeg");
    let randomImg2=getRandomImagesPath("vendor_landing",9,"jpeg");
    let randomImg3=getRandomImagesPath("vendor_landing",9,"jpeg");
    var strHeader="<section class=\"my-4\">"+
	"<div class=\"container\">"+
		"<div class=\"row\">";

    let strSlideHeader="<div class=\"col-md-8\">"+
    "<div class=\"shadow-sm p-2 mb-5 bg-body rounded\">"+
        "<div id=\"carouselExampleControls\" class=\"carousel slide\" data-bs-ride=\"carousel\">"+
        "<div class=\"carousel-inner\">";
 
    let sliderPreNext="<button class=\"carousel-control-prev\" type=\"button\" data-bs-target=\"#carouselExampleControls\" data-bs-slide=\"prev\">"+
    "<span class=\"carousel-control-prev-icon\" aria-hidden=\"true\"></span>"+
    "<span class=\"visually-hidden\">Previous</span>"+
    "</button>"+
    "<button class=\"carousel-control-next\" type=\"button\" data-bs-target=\"#carouselExampleControls\" data-bs-slide=\"next\">"+
    "<span class=\"carousel-control-next-icon\" aria-hidden=\"true\"></span>"+
    "<span class=\"visually-hidden\">Next</span>"+
    "</button>";
    let strSliderFooter="</div>"+sliderPreNext+"</div></div></div>";    
        
    var strFooter="</div></div></section>";

let strRespBanner="<div class=\"carousel-item active\">"+
"<img src=\"../"+randomImg1+"\" class=\"d-block w-100\" alt=\"...\">"+
"</div>";

strRespBanner+="<div class=\"carousel-item\">"+
"<img src=\"../"+randomImg2+"\" class=\"d-block w-100\" alt=\"...\">"+
"</div>"+
"<div class=\"carousel-item\">"+
"<img src=\"../"+randomImg3+"\" class=\"d-block w-100\" alt=\"...\">"+
"</div>";
//     for(var i=0;i<lsData.length;i++){
//        strResp+="<div class=\"carousel-item active\">"+
//        "<img src=\"images/slide_home_1.jpg\" class=\"d-block w-100\" alt=\"...\">"+
//    "</div>";
//   }
/* Part Col-4 */
let strPartSide="<div class=\"col-md-4\">"+
"<div class=\"shadow-sm p-3 mb-4 bg-body rounded div-height\">"+
    "<h5>"+lsData[0]["company_name"]+"</h5>"+
    "<p>"+lsData[0]["details"]+"</p>"+
"</div>"+
"<div class=\"shadow-sm p-3 mb-4 bg-body rounded div-height\">"+
    "<h5>Contact Information</h5>"+
    "<p>Contact No:</p>"+
    "<p>Address:</p>"+
"</div>"+
"</div>";

    return strHeader+strSlideHeader+strRespBanner+strSliderFooter+strPartSide+strFooter;
}
export function Home_Banner_Sm(lsData){
    var appPath=appGetUrlPath();
    var strResp="";
    var strHeader="<section class=\"my-4\">"+
	"<div class=\"container\">"+
		"<div class=\"row\">";

    let strSlideHeader="<div class=\"col-md-8\">"+
    "<div class=\"shadow-sm p-2 mb-5 bg-body rounded\">"+
        "<div id=\"carouselExampleControls\" class=\"carousel slide\" data-bs-ride=\"carousel\">"+
        "<div class=\"carousel-inner\">";
 
    let sliderPreNext="<button class=\"carousel-control-prev\" type=\"button\" data-bs-target=\"#carouselExampleControls\" data-bs-slide=\"prev\">"+
    "<span class=\"carousel-control-prev-icon\" aria-hidden=\"true\"></span>"+
    "<span class=\"visually-hidden\">Previous</span>"+
    "</button>"+
    "<button class=\"carousel-control-next\" type=\"button\" data-bs-target=\"#carouselExampleControls\" data-bs-slide=\"next\">"+
    "<span class=\"carousel-control-next-icon\" aria-hidden=\"true\"></span>"+
    "<span class=\"visually-hidden\">Next</span>"+
    "</button>";
    let strSliderFooter="</div>"+sliderPreNext+"</div></div></div>";    
        
    var strFooter="</div></div></section>";

let strRespBanner="<div class=\"carousel-item active\">"+
"<img src=\"../img_f/slide_home_1.jpg\" class=\"d-block w-100\" alt=\"...\">"+
"</div>";

strRespBanner+="<div class=\"carousel-item\">"+
"<img src=\"../img_f/slide_home_2.jpg\" class=\"d-block w-100\" alt=\"...\">"+
"</div>"+
"<div class=\"carousel-item\">"+
"<img src=\"../img_f/slide_home_3.jpg\" class=\"d-block w-100\" alt=\"...\">"+
"</div>";
//     for(var i=0;i<lsData.length;i++){
//        strResp+="<div class=\"carousel-item active\">"+
//        "<img src=\"images/slide_home_1.jpg\" class=\"d-block w-100\" alt=\"...\">"+
//    "</div>";
//   }
/* Part Col-4 */
let strPartSide="<div class=\"col-md-4\">"+
"<div class=\"shadow-sm p-3 mb-4 bg-body rounded div-height\">"+
    "<h5>Vendor Name</h5>"+
    "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>"+
"</div>"+
"<div class=\"shadow-sm p-3 mb-4 bg-body rounded div-height\">"+
    "<h5>Contact Information</h5>"+
    "<p>Contact No:</p>"+
    "<p>Address:</p>"+
"</div>"+
"</div>";

    return strHeader+strSlideHeader+strRespBanner+strSliderFooter+strPartSide+strFooter;
}
export default Home_Banner_Sm;