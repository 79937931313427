import React from 'react';
import './search_filters.css';
import {TableDatePicker} from '../function_lib/common_lib';

const Reports_Search_Filter=(props)=>{
    // set reports filter props 
    let statusList=[];
    let isFromDate=false;
    let isToDate=false;


    if(typeof props.statusList!=="undefined"){
        statusList=props.statusList;
    }
    if(typeof props.isFromDate!=="undefined"){
        isFromDate=props.isFromDate;
    }
    if(typeof props.isToDate!=="undefined"){
        isToDate=props.isToDate;
    }



    // end reports filter props 
   
    //alert(JSON.stringify(props));
    return (
    // <section class="start-left-image-right-text">
    // <div class="container">
        // <div class="row">
            <aside class="col-lg-3" id="sidebar_fixed">
                <div class="mobile-filter-button">
 <a href="#0" class="btn btn-warning open_filters filter-button">
     <i class="ion-ios-color-filter-outline12 ti-filter12 fa fa-sliders white my-float"></i>
 </a>
</div>
                <div class="layer"></div>
                {/* <!-- Mobile menu overlay mask --> */}
                <div class="filter_col">
                    <div class="inner_bt"><a href="#" class="open_filters"><i class="ion-android-close"></i></a></div>
                    <div class="filter_type version_2">
                        <h4><a href="#filter_2" data-toggle="collapse" class="opened">Search</a></h4>
                        <div class="collapse show" id="filter_2">
                            <ul>
                                <li>
                                    <select id="filter_status" name="filter_status" class="form-control hevent_flt_rightbar_nn hcls_flt_color">
                                        
                                        {statusList.map((item,index)=>{
                                            return <option value={item.value}>{item.display}</option>
                                        })}
                                        
                                    </select>
                                </li>
                                {isFromDate===true?<li>
                                <TableDatePicker refName="from_dt"/>
                                </li>:''}
                                {isToDate===true?<li>
                                <TableDatePicker refName="to_dt"/>
                                </li>:''}
                            </ul>
                        </div>
                    </div>
                    {/* <!-- /filter_type --> */}
                    {typeof props.invoice_type!=="undefined"?
                    <div class="filter_type version_2">
                        <h4><a href="#filter_3" data-toggle="collapse" class="opened">Order Type</a></h4>
                        <div class="collapse show" id="filter_3">
                            <ul>
                            <li>
                                    <select id="filter_invoice_type" name="filter_invoice_type" class="form-control hevent_flt_rightbar_nn hcls_flt_color">
                                        
                                        {props.invoice_type.map((item,index)=>{
                                            return <option value={item.value}>{item.display}</option>
                                        })}
                                        
                                    </select>
                                </li>
                              
                            </ul>
                        </div>
                    </div>:''}
                    
                   
                    {/* <!-- /filter_type --> */}
                    {/* <div class="filter_type version_2">
                        <h4><a href="#filter_3" data-toggle="collapse" class="closed">Duration</a></h4>
                        <div class="collapse" id="filter_3">
                            <ul>
                                {props.filters!=="undefined"?props.filters.map((item,index)=>{
                                    if(item.top_code==="brands"){
                                        return (<li>
                                        <label class="container_check">{item.category_name}<small></small>
                                            <input type="checkbox" className="hevent_flt_rightbar hcls_flt_brand" name="hcls_flt_brand" value={item.category_code}/>
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>)
                                    }
                                }):''}
                              
                            </ul>
                        </div>
                    </div> */}
                    {/* <!-- /filter_type --> */}
                    {/* <div class="filter_type version_2">
                        <h4><a href="#filter_4" data-toggle="collapse" class="opened">Price</a></h4>
                        <div class="collapse show" id="filter_4">
                            <ul>
                                <li>
                                    <label class="container_check">₹0 — ₹1,000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="0,1000"  />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹1,000 — ₹2,000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="1000,2000" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹2,000 — ₹5,000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="2000,5000" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹5,000 — ₹8,000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="5000,8000" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹8,000 — ₹12,000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="8000,12000" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                     {typeof props.onSearchClick!=="undefined"?<button type="button" onClick={()=>props.onSearchClick()} class="btn btn-primary mr-2">Search</button>:''}
                     {typeof props.onExportClick!=="undefined"?<button type="button" onClick={()=>props.onExportClick()} class="btn btn-primary">Export CSV</button>:''}
                     
                </div>
            </aside>

            // {/* <!--end mobile view filter button --> as of now place below code to applier compoent */}

            // <div class="mobile-filter-button">
            //     <a href="#0" class="btn btn-warning open_filters filter-button">
            //         <i class="fa-sharp fa-solid fa-filter my-float"></i>
            //     </a>
            // </div>
            
            // {/* <!--end mobile view filter button --> */}
//         </div>
//     </div>
// </section>
)
}
export default Reports_Search_Filter;