import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod_CBack,RespBkMthdMapp,AjaxCallMethod,listClock} from '../function_lib/common_lib';
import {StateMaster} from '../function_lib/city_india';
import User_Header from './user_header';
import User_Footer from './user_footer';
import Mobile_Widgets from '../sample_comp/mobile_widgets';
import Comp_Emp_Dashboard from '../user_components/comp_emp_dashboard';
import Comp_Vendor_Dashboard from '../user_components/comp_vendor_dashboard';
import Comp_Admin_Dashboard from '../user_components/comp_admin_dashboard';

import Comp_Approve_Packages from '../user_components/comp_approve_packages';



import pdfIcon from '../assets/images/pdf_icon.png';
import './common.css';

let logInObj;
let stateList;
let clsStaffUsers;
let lsClock=[];


class Staff_Users extends React.Component{
    
forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!=null){
            //logInObj=JSON.parse(localStorage.getItem("userDS"));
            logInObj=getUserObjData();
          }
          clsStaffUsers=this;
          this.state={stVendorList:[],stVendorId:0,stVendor:{}}
          stateList=StateMaster();
          lsClock=listClock(15);
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

   getStaffUser(){
    let map=[];
	map.push({name:"curl_type",value:"getUserStaff"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:logInObj[0]["app_user_id"]});
    map.push({name:"local_token",value:logInObj[0]["id"]});
    
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getUserStaff";
    CallMapMethod_CBack(this,map,function(data,msg){
       // alert(data+msg);
        if(data!=="-001" && data!=="0"){
            let lsData=JSON.parse(data);
            clsStaffUsers.setState({stVendorList:lsData},function(){
                clsStaffUsers.forceUpdateHandler();
                
            })
        }
    })

   }
   exportCsv(){

    let map=[];
	map.push({name:"curl_type",value:"getMerchantPayments"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:logInObj[0]["app_user_id"]});
    map.push({name:"local_token",value:logInObj[0]["id"]});
    map.push({name:"isCSV",value:"true"});
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getMerchantPayments";
    CallMapMethod_CBack(this,map,function(data,msg){
        //alert(data+msg);
        if(data!=="-001" && data!=="0"){
           
    let csvContent = atob(data);
var blob = new Blob([csvContent], {type: "data:application/octet-stream;base64"});
var url  = window.URL.createObjectURL(blob);
// you need to precise a front-end button to have a name
var link = document.createElement("a");
link.setAttribute("href", url);
let getDt=new Date();
link.setAttribute("download", "_data" + getDt.getDate()+"_"+getDt.getMonth()+"_"+getDt.getFullYear()+"_"+"_"+getDt.getHours()+"_"+getDt.getMinutes() + ".csv");
link.click();
            
        }
    })

   
   }
   onClickDetails(data){
    //alert(data);
    //alert(JSON.stringify(data));
    clsStaffUsers.setState({stVendorId:data.encKey,stVendor:data});
   }
  

    componentDidMount(){
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
       
        this.getStaffUser();
        AjaxCallMethod(this,"okSuccCNOT");
    }
    render(){
       
        return(<div>
            <div id="tmpSt"></div>
               <User_Header />
             
<div className="container">
<div className="card card-outline cover-height">
<div className="card-header">
{/* <div class="parent_tb">
 <div class="line_tb">
<div id="l_pro_counts" class="left_tb">
                <h5 class="card-title">Document List</h5>
            </div>
    </div>
    </div>  */}
<div class="parent_tb">
                <div class="line_tb">
            <div id="l_pro_counts" class="left_tb">
            {/* <span class="float-left badge ">
                <select id="serFilterYear" name="serFilterYear" className="form-control year_master">
                </select>
            </span> */}
            {/* <span class="float-left  badge ">
                <select id="serFilterHeader" name="serFilterHeader" className="form-control doc_header">
                </select>
            </span> */}
            <span class="float-left badge ">    
                <input id="serTxtSearch" name="serTxtSearch" type="text" placeholder="search by company,person,contact,email" />
            </span>
            <span class="float-left  badge "> 
            <button type="button" onClick={()=>this.getVendorList("#frmEntityDoc")} class="btn btn-primary">Search</button>
            </span>
            
            </div>
            <div class="right_tb">
            <span class="float-left  badge "> 
            <button type="button" onClick={()=>this.exportCsv()} class="btn btn-primary">Export CSV</button>
            </span>
            </div>
        </div>
                </div>
    </div>
<div class="card-body">

<table class="dtgrid set-min-height table table-bordered table-hover text-sm text-nowrap12 table-responsive">
    <thead>
      <tr class="border">
          <th>S.No.</th>
          <th>First Name.</th>
          <th>Last Name</th>
          <th>% Ded.</th>
          <th>Ded. Amount</th>
          <th>Net Payable</th>
          <th>Vendor Name</th>
         <th>Date</th>
         <th>Status</th> 
         <th>Action</th>
      </tr>
    </thead>
    <tbody id="tbData">
      {(this.state.stVendorList.map((item,index)=>{
          return(<tr>
              <td>{index+1}</td>
              <td>{item["invoice_no"]}</td>
              <td>{item["invoice_amt"]}</td>
              <td>{item["per_deduct"]+"%"}</td>
              <td>{item["com_deduct"]}</td>
              <td>{item["net_payable"]}</td>
              <td>{item["company_name"]}</td>
              <td>{item["entry_date"]}</td>
              <td>{item["status"]}</td>
              <td>
              <div className="main-menu manu-color-white">
          <ul>
            <li class="dropdown">
          <a className="font-sm" href="#"><span className="badge bg-primary">Action <i class="ion-ios-arrow-down"></i></span></a>
          <ul class="sub-menu">
          <li><a class="dropdown-item" data-toggle="modal" data-target="#divApprovePackages" href="javascript:void(0)" onClick={()=>this.onClickDetails(item)}><i class="fa fa-pencil text-inverse m-r-10">Approve</i></a></li>
          <li><a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#divQustMapping" onClick={()=>this.setQuestMapData(item)}>View.</a></li>
          {/* <li><a class="dropdown-item" href="javascript:void(0)" onClick={()=>this.setDeleteLoanDoc(item)}><i class="fa fa-window-close text-danger">Delete</i></a></li> */}
            </ul>
            </li>
            </ul>
            </div>
              </td>
             
          </tr>)
      }))}
    </tbody>
  </table>


</div>
</div>
</div>
<Comp_Approve_Packages userObject={logInObj} stateList={stateList} vendorId={this.state.stVendorId} vendor={this.state.stVendor} />
               {/* <Mobile_Widgets/> */}
               <User_Footer/>
        </div>)
    }
}
export default Staff_Users; 