import React from 'react';
//import {appGetUrlPath} from '../function_lib/common_cms';
import './feature_card_n1.css';
export function Feature_Card_n1(lsData){
   // alert(lsData);
    //var appUrtPath=appGetUrlPath();
    var appUrtPath="../";
var listData=lsData;
var strResp="";
var strHeader="<div class=\"section mb-50px pt-50px pb-50px mb-10px\">"+
              "<div class=\"container12\">"+
              "<div class=\"row custome-flex\">";

var strFooter="</div></div></div>";
for(var i=0;i<listData.length;i++){
    strResp+="<div class=\"col-6 col-sm-6 col-lg-3\"><div class=\"carditem_n1 feature_card1_n1\">"+
    "<div class=\"row1\">"+
        "<div class=\"imgtag\" style=\"background-image: url('./flat.jpg');\">"+
           "Flat 30% off"+
        "</div>"+
        "<div class=\"boxname\">"+
        lsData[i]["col1"]+
        "</div>"+
    "</div>"+
    // "<div class=\"row2\">"+
    //     "<button class=\"button1\">"+
    //         "Order Now"+
    //     "</button>"+
    // "</div>"+
    "<div class=\"sideimg\">"+        
    "<img src=\""+appUrtPath+lsData[i]["col4"]+"\" alt=\"medicine\">"+
    "</div>"+
"</div></div>";
}
//alert(strHeader+strResp+strFooter);
return strHeader+strResp+strFooter;
}
export default Feature_Card_n1;