import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import User_Header from './user_header';
import User_Footer from './user_footer';
import Mobile_Widgets from '../sample_comp/mobile_widgets';
import {getUserObjData} from '../function_lib/userToken';

import {User_OrderList} from '../sample_comp/product_listing';
import {CallMapMethod,SetResponseDiv,AjaxCallMethod,TableDatePicker} from '../function_lib/common_lib';

let filtStatus;
let queries;
let getUser;
class EndUser_OrderList extends React.Component{
    constructor(props){
        super(props);
        queries = QueryString.parse(this.props.location.search);
        filtStatus="order";
        if(queries.rf==="collection")
            {
              filtStatus="collection";
            }
            getUser=getUserObjData();
            
      }
    getOrderInvst(){
    //alert("testing i m in click");
        //get Values from SearchParam Object
        var fromDt=$("#from_dt").val();
        var toDt=$("#to_dt").val();
     
       
       //console.log(this.props.location.search);
       //queryString.parse(location.search);
       var map=[];
       //alert(this.props);
       if(typeof this.props.location!=="undefined"){
         queries = QueryString.parse(this.props.location.search);
       }
       
       //console.log(queries.rf); 
       let filtStatus=$("#collection_status").val();
       //filtStatus="0";
     //   if(queries.rf==="collection")
     //   {
     //     filtStatus="collection";
     //     map.push({name:"collection_status",value:"Pending Assign"});
     //   }else{
     //     map.push({name:"collection_status",value:"Pending Accession"});
     //   }
       map.push({name:"curl_type",value:"getOrders"});
       map.push({name:"res_func",value:"callBackNg"});
       //map.push({name:"from_dt",value:fromDt});
       //map.push({name:"to_dt",value:toDt});
       map.push({name:"collection_status",value:filtStatus});
       map["res_func"]="callBackNg";
       map["curl_type"]="getOrders";
       if(getUser!==""){
           //alert("testing this is me");
           map.push({name:"c_id",value:getUser[0]["id"]});
           CallMapMethod(this,map);
       }
       

    }   
    componentDidMount(){
        //alert("i m in listing");
         this.getOrderInvst();
         AjaxCallMethod(this,"callBackNg");

         const script = document.createElement("script");
         script.src = "/main.js";
         script.async = true;
         //script.onload = () => clsObject.scriptLoaded();
       
         document.body.appendChild(script);
    }
    callBackNg(data,msg){
         // alert(data+msg);
        //console.log(data);
        if(data.trim()!=="0"){
           // divOrder
         var checkResp= User_OrderList(data);
         $("#divOrder").html(checkResp);
         //alert(checkResp);
         //SetResponseDiv("ordItems",checkResp);
        }else{
         $("#divOrder").html("<center>No records found</center>")
        }
      
        //$("#ordItems").html(checkResp);
     }
    render(){
        return(<div>
               <User_Header />
               
<div className="container">
<div class="parent_tb">
                <div class="line_tb">
            <div id="l_pro_counts" class="left_tb">
          
            <span class="float-left badge ">    
            <TableDatePicker refName="from_dt"/>
            </span>
            <span class="float-left badge ">    
            <TableDatePicker refName="to_dt"/>
            </span>
            <span class="float-left badge ">
            <select className="form-control" id="collection_status" name="collection_status">
            <option value="0">All</option>
                   <option value="Pending Assign">Pending Assign</option>
                   <option value="Pending Collection">Pending Collection</option>
                   <option value="Pending Assign">Pending Assign</option>
                   <option value="Bill Paid">Pending Accession</option>
                   <option value="Sample Taken">Pending Complete</option>
                   <option value="Completed">Pending Verification</option>
                   <option value="Verified">Pending Approval</option>
                   <option value="Approved">Pending Print</option>
                   <option value="Printed">Printed</option>
                  
                 </select>
            </span>
            <span class="float-left  badge "> 
            <button type="button" onClick={()=>this.getOrderInvst()} class="btn btn-primary">Search</button>
            </span>
            
            </div>
            <div class="right_tb">
          
            </div>
        </div>
                </div>
<div id="divOrder">

</div>
</div>
    
               {/* <Mobile_Widgets/> */}
               <User_Footer/>
        </div>)
    }
}
export default EndUser_OrderList; 