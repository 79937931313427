import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod_CBack,RespBkMthdMapp,AjaxCallMethod,listClock} from '../function_lib/common_lib';
import {StateMaster} from '../function_lib/city_india';
import User_Header from './user_header';
import User_Footer from './user_footer';
import Mobile_Widgets from '../sample_comp/mobile_widgets';
import Comp_Emp_Dashboard from '../user_components/comp_emp_dashboard';
import Comp_Vendor_Dashboard from '../user_components/comp_vendor_dashboard';
import Comp_Admin_Dashboard from '../user_components/comp_admin_dashboard';

import Comp_Approve_Packages from '../user_components/comp_approve_packages';



import pdfIcon from '../assets/images/pdf_icon.png';
import './common.css';

let logInObj;
let stateList;
let clsVendorList;
let lsClock=[];


class Vendor_List extends React.Component{
    
forceUpdateHandler(){
    this.forceUpdate();
  };
    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!=null){
            //logInObj=JSON.parse(localStorage.getItem("userDS"));
            logInObj=getUserObjData();
          }
          clsVendorList=this;
          this.state={stVendorList:[],stVendorId:0,stVendor:{}}
          stateList=StateMaster();
          lsClock=listClock(15);
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    }

   getVendorList(){
    let map=[];
	map.push({name:"curl_type",value:"getVendorSignList"});
	map.push({name:"res_func",value:"callBackNg"});
	map.push({name:"app_token",value:logInObj[0]["app_user_id"]});
    map.push({name:"local_token",value:logInObj[0]["id"]});
    map.push({name:"type_id",value:"27"});
   
	map["res_func"]="callBackNg";
	map["curl_type"]="getVendorSignList";
    CallMapMethod_CBack(this,map,function(data,msg){
       // alert(data+msg);
        if(data!=="-001" && data!=="0"){
            let lsData=JSON.parse(data);
            clsVendorList.setState({stVendorList:lsData},function(){
                clsVendorList.forceUpdateHandler();
            })
        }
    })

   }

  

   onClickDetails(data){
    //alert(data);
    //alert(JSON.stringify(data));
    clsVendorList.setState({stVendorId:data.encKey,stVendor:data});
   }
  

    componentDidMount(){
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);
       
        this.getVendorList();
        AjaxCallMethod(this,"okSuccCNOT");
    }
    render(){
       
        return(<div>
            <div id="tmpSt"></div>
               <User_Header />
             
<div className="container">


<div class="parent_tb">
                <div class="line_tb">
            <div id="l_pro_counts" class="left_tb">
            {/* <span class="float-left badge ">
                <select id="serFilterYear" name="serFilterYear" className="form-control year_master">
                </select>
            </span> */}
            {/* <span class="float-left  badge ">
                <select id="serFilterHeader" name="serFilterHeader" className="form-control doc_header">
                </select>
            </span> */}
            <span class="float-left badge ">    
                <input id="serTxtSearch" name="serTxtSearch" type="text" placeholder="search by company,person,contact,email" />
            </span>
            <span class="float-left  badge "> 
            <button type="button" onClick={()=>this.getVendorList("#frmEntityDoc")} class="btn btn-primary">Search</button>
            </span>
            
            </div>
            <div class="right_tb">
          
            </div>
        </div>
                </div>
    </div>
<div class="card-body">
      {(this.state.stVendorList.map((item,index)=>{
          return( <div class="col-md-12 pb-2">
          <form>
            <div class="card p-1 shadow">
              <div class="row">
                  <div class="col-sm-5">
                  <div class="card-block">
                      <div class="p-2 mb-1 alert-warning mybg text-white">
                      <h5 class="mb-0">Company Name:{item["company_name"]}</h5>
                      </div>
                      <p class="mb-0">UserName : <b>{item["user_name"]}</b></p>
                      <p class="mb-0">Person Name : <b>{item["person_name"]}</b></p>
                      <p class="mb-0">Contact No : <b>{item["contact_no"]}</b></p>
                      {/* <p class="text-danger mb-0">Payment Status : <b>"+listData[i]["Payment Status"]</b></p> */}
                      
                  </div>
              </div>
                      <div class="col-sm-4 col-md-4">
                      <div class="card-block">
                      <p class="mb-0">Assign Date : <b>{item["enc_entry_date"]}</b></p>
                      <p class="mb-0">Recent Update : <b>{item["enc_update_date"]}</b></p>
                      <p class="text-danger mb-0">Package :<b>{item["col1"]+"%"}</b></p>
                    </div>
              </div>
              <div className="col-md-3 col-sm-3">
              <p class="mb-0">Status : <b>{item["status"]}</b></p>
              <div className="main-menu manu-color-white">
          <ul>
            <li class="dropdown">
          <a className="font-sm" href="#"><span className="badge bg-primary">Action <i class="ion-ios-arrow-down"></i></span></a>
          <ul class="sub-menu">
          <li><a class="dropdown-item" data-toggle="modal" data-target="#divApprovePackages" href="javascript:void(0)" onClick={()=>this.onClickDetails(item)}><i class="fa fa-pencil text-inverse m-r-10">Approve</i></a></li>
          <li><a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#divQustMapping" onClick={()=>this.setQuestMapData(item)}>View.</a></li>
          {/* <li><a class="dropdown-item" href="javascript:void(0)" onClick={()=>this.setDeleteLoanDoc(item)}><i class="fa fa-window-close text-danger">Delete</i></a></li> */}
            </ul>
            </li>
            </ul>
            </div>

              </div>
                      
                    
                  </div>
              </div>
          </form></div>)
      }))}
   
  {/* new component */}
 
{/*  end of component */}
</div>
<Comp_Approve_Packages userObject={logInObj} stateList={stateList} vendorId={this.state.stVendorId} vendor={this.state.stVendor} />
               {/* <Mobile_Widgets/> */}
               <User_Footer/>
        </div>)
    }
}
export default Vendor_List; 