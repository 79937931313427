import React from 'react';
import './home_banner.css';
import { appGetUrlPath } from '../function_lib/common_cms';
export function Home_Banner2Div(lsData){
    var appPath=appGetUrlPath();
    var strResp="";
  
    var strHeader="<div data-aos=\"zoom-in-down\" class=\"hero_home section\">"+
    "<div class=\"hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white\">"+
         "<div class=\"swiper-wrapper\">";
    var strFooter="</div><div class=\"swiper-pagination swiper-pagination-white\"></div>"+
            "<div class=\"swiper-buttons\">"+
        "<div class=\"swiper-button-next\"></div>"+
        "<div class=\"swiper-button-prev\"></div>"+
    "</div>"+
"</div>"+
"</div>";
    for(var i=0;i<lsData.length;i++){
        let styleRBg=""; 
        if(lsData[i]["col15"].trim()!==""){
            styleRBg=lsData[i]["col15"].trim();
            //alert("i m in sytle=="+styleRBg);
         }
         let rfUrl="javascript:void(0)";
         if(lsData[i]["col7"].trim()!==""){
            rfUrl=lsData[i]["col7"].trim();
         }
         //style=\"background:url("+appPath+lsData[i]["col4"]+") right no-repeat;\"
       strResp+="<div  class=\"hero-slide-item bg-area-medico slider-height swiper-slide d-flex\">"+
      "<div  class=\"container align-self-center\">"+
      "<div class=\"row\">"+
          "<div class=\"col-xl-6 col-lg-7 col-md-7 col-sm-7 order-md-2 align-self-center\">"+
              "<div class=\"hero-slide-content slider-animated-1 bg-banner-text\">"+
                //   "<span class=\"category\">New Products</span>"+
                  "<h2 class=\"title white\">"+lsData[i]["col1"]+"</h2>"+
                  "<p class=\"white\">"+lsData[i]["col2"]+"</p>"+
                  "<a href=\"../products\" class=\"btn btn-sm btn-primary rounded mt-2 py-2 px-4 btn-hover-dark\">Shop Now</a>"+
              "</div>"+
          "</div>"+
          "<div class=\"col-xl-6 col-lg-5 col-md-5 col-sm-5 order-md-1 bg-rectangle-animation\">"+
              "<div class=\"hero-slide-image\">"+
                  "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\" />"+
              "</div>"+
          "</div>"+
      "</div>"+
  "</div>"+ 
      
   "</div>";
    }
    return strHeader+strResp+strFooter;
}
export function Home_Banner(lsData){
    var appPath=appGetUrlPath();
    var strResp="";
    var strHeader="<div class=\"hero_home section\">"+
    "<div class=\"hero-slider swiper-container slider-nav-style-1 slider-dot-style-1 dot-color-white\">"+
         "<div class=\"swiper-wrapper\">";
    var strFooter="</div><div class=\"swiper-pagination swiper-pagination-white\"></div>"+
            "<div class=\"swiper-buttons\">"+
        "<div class=\"swiper-button-next\"></div>"+
        "<div class=\"swiper-button-prev\"></div>"+
    "</div>"+
"</div>"+
"</div>";
    for(var i=0;i<lsData.length;i++){
        let styleRBg=""; 
        if(lsData[i]["col15"].trim()!==""){
            styleRBg=lsData[i]["col15"].trim();
            //alert("i m in sytle=="+styleRBg);
         }
       strResp+="<div class=\"hero-slide-item slider-height-2 swiper-slide d-flex\">"+
       
       "<div class=\"hero-bg-image\">"+
           "<img src=\""+appPath+lsData[i]["col4"]+"\" alt=\"\"/>"+
       "</div>"+
       "<div class=\"container1\" style=\"background-color:"+styleRBg+";width:100%\">"+
           "<div class=\"row\">"+
               "<div class=\"col-md-5 align-self-center txt_on_slider\">"+
                   "<div class=\"hero-slide-content hero-slide-content-2 slider-animated-1 text-center\">"+
                       "<span class=\"category\">"+lsData[i]["col1"]+"</span>"+
                       "<p class=\"w-100\">"+lsData[i]["col2"]+"</p>"+
                        "<a href=\"../products\" class=\"btn btn-lg btn-primary btn-hover-dark mt-5 py-2 px-4\">Shop Now</a>"+
                   "</div>"+
               "</div>"+
           "</div>"+
       "</div>"+
      
   "</div>";
    }
    return strHeader+strResp+strFooter;
}
export default Home_Banner;