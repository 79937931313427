import $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import {getRoleDetails,CallCMSMapMethod} from '../function_lib/common_cms';
import {TableDatePicker} from '../function_lib/common_lib';
import {getUserObjData} from '../function_lib/userToken';
import {Vendor_Listing_Horizontal} from '../sample_comp/product_listing';
import Product_Listing from '../sample_comp/product_listing';
import Front_Header from './front_header';
import Footer from './footer';
import Top_Banner from '../sample_comp/top_banner';
import Top_Specified from './top_specified';
import Home_Product from './home_product';
import Product_Slider from '../sample_comp/product_slider';
import Features_Slider  from '../sample_comp/features_slider';
import Feature_Web_List from '../sample_comp/feature_web_list';
import Article_Listing from '../sample_comp/article_listing';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import ImageGallery from '../sample_comp/image_gallery';
import Video_Slider from '../sample_comp/video_slider';
import Collapse_Pan from '../sample_comp/collapse_pan';
import Feature_Card from '../sample_comp/feature_card';

import { Player } from 'video-react';
// import AOS from 'aos';
import LogoImg from '../assets/images/my_logo.jpg';
import titleVid from '../assets/videos/imp_water.mp4';

//import titleVid from '../assets/images/backg.gif';
import './index.css';
/* Media */
import logoImg from '../assets/images/logo/logo.png';

import ban1 from '../assets/images/slider-image/02.jpg';
import ban2 from '../assets/images/slider-image/02.jpg';

import why_medi from '../assets/images/icons/capsule.png';
import why_edu from '../assets/images/icons/fist.png';
import why_care from '../assets/images/icons/heart.png';
import why_confi from '../assets/images/icons/discretion.png';
import why_trans from '../assets/images/icons/eye.png';

//import pImg1 from '../assets/images/product-image/AsthmaticaOil.png'
import Left_Menu_Option from '../sample_comp/left_menu_option';
import Blogs from '../sample_comp/blogs';
import Time_Line from '../sample_comp/time_line';
import TkMeter from '../assets/images/camera.svg';
import '../sample_comp/why_us.css';
import './dialysis_module.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let rightBarLists;

let defaultPurchaseCart=[];


let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];
let Razorpay;
let redirectConfirmation="";
class Dialysis_Module extends React.Component{
    constructor(props){
        super(props);
        this.state={viewData:"0"};
        this.multiselectRef = React.createRef();
        loc=props.location["pathname"].substr(1);
        //alert(loc);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
         var listRoles= UserToken.getAccessRoles();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          rightBarLists= getRoleDetails(listRoles,loc);
         
          //window.location.href="./Dashboard";
        }
        
      }
      submitInv(frmNm){

       // alert($("input[type='radio'][name='flexRadioDefault']:checked").val());
       // return;
        /* for validation control */
   var strFrmNm=frmNm.substr(1);
   var isValidated=true;
   //alert(strFrmNm);
   var objElems=document.getElementById(strFrmNm).getElementsByClassName("required");
   for(var r=0;r<objElems.length;r++){
     
     if(objElems[r].value==="" || objElems[r].value==="0"){
       //alert(objElems[r].type);
       objElems[r].style.border="1px solid #e87d1e";
       isValidated=false;
     }
   }
   if(!isValidated){
    return;
  }
  
if($("#lsImages").val()===""){
  alert("Important to Upload Doctor Prescription");
  return;
}
        var cartItems= localStorage.getItem("_cart");
       
        if(defaultPurchaseCart.length>0)
        {
          cartItems="[{\"item_name\":\""+defaultPurchaseCart[0]["name"]+"\","+
                    "\"item_code\":\""+defaultPurchaseCart[0]["Item Code"]+"\","+
                    "\"item_rate\":\""+defaultPurchaseCart[0]["Sales Rate"]+"\","+
                    "\"company_name\":\""+defaultPurchaseCart[0]["company_name"]+"\","+
                    "\"item_ref_id\":\""+defaultPurchaseCart[0]["id"]+"\","+
                    "\"item_id\":\""+defaultPurchaseCart[0]["id"]+"\""+
                    "}]";
        }
        var purchaseForUserId=localStorage.getItem("_cartP");
        let pItemList=JSON.stringify(cartItems);
        //alert(pItemList);
        pItemList="{\"tt\":["+pItemList+"]}";
        var map=[];
       map.push({name:"curl_type",value:"createAppInvoice"});
       map.push({name:"res_func",value:"callBackInvoice"});
       if(userObject && userObject.length>0){
        map.push({name:"party_info_id",value:userObject[0].id})
       }else{
        map.push({name:"party_info_id",value:"0"});
       }
       map.push({name:"company_name",value:$("#company_name").val()});
       //map.push({name:"person_name",value:$("#person_name").val()});
       map.push({name:"contact_no",value:$("#contact_no").val()});
       map.push({name:"age",value:$("#age").val()});
       map.push({name:"category_id",value:"3"});
       map.push({name:"address",value:$("#address").val()});


       
       map.push({name:"inv_amount",value:"1500"});
       map.push({name:"per_discount",value:"0"});
       map.push({name:"discount",value:"0"});
       map.push({name:"net_amount",value:"1500"});
       map.push({name:"collection_status",value:"Pending Accession"});
       map.push({name:"app_user_id",value:"31"});
       map.push({name:"location_id",value:$("#location_id").val()});
       if($("#payment_mode").val()==="online"){
        map.push({name:"status",value:"initiated"});
       }else{
        map.push({name:"stauts",value:$("#payment_mode").val()});
       }
       //alert(pItemList);
       map.push({name:"p_item_list",value:pItemList});
       map.push({name:"lsImages",value:$("#lsImages").val()});
    
       //with PurchaseItem 
       map.push({name:"date_of_booking",value:$("#date_for").val()});
       map.push({name:"time_of_booking",value:$("input[type='radio'][name='flexRadioDefault']:checked").val()});
      // map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
      // map.push({name:"hosp_id",value:refId});
      map["res_func"]="callBackInvoice";
      map["curl_type"]="createAppInvoice";
          CallMapMethod(clsObject,map);
      }

      callBackInvoice(data,msg){
         
        //alert(data+msg);
         if(data!=="0"){
        
   /* Go for Payment Gateway */
           /* Check payment gateway */
          if($("#payment_mode").val()==="online"){
            let p_contactNo="+91"+$("#contact_no").val();

            const script1 = document.createElement("script");
            script1.src = "https://checkout.razorpay.com/v1/checkout.js";
            script1.async = true;
           
            script1.onload = () =>{
              var options = {
                "key": "rzp_live_9jpRWSg5EIxaVl", // Enter the Key ID generated from the Dashboard
                "amount": "5000",
                "currency": "INR",
                "description": "Advaiet Health Care-Hemo Dialysis",
                "image": "https://advaiet.com/logo.png",
                "prefill":
                {
                  "email": "care@advaiet.com",
                  "contact": p_contactNo,
                },
                config: {
                  display: {
                    blocks: {
                      hdfc: { //name for HDFC block
                        name: "Pay using HDFC Bank",
                        instruments: [
                          {
                            method: "card",
                            issuers: ["HDFC"]
                          },
                          {
                            method: "netbanking",
                            banks: ["HDFC"]
                          },
                        ]
                      },
                      other: { //  name for other block
                        name: "Other Payment modes",
                        instruments: [
                          {
                            method: "card",
                            issuers: ["ICIC"]
                          },
                          {
                            method: 'netbanking',
                          }
                        ]
                      }
                    },
                    // hide: [
                    //   {
                    //   method: "upi"
                    //   }
                    // ],
                    sequence: ["block.hdfc", "block.other"],
                    preferences: {
                      show_default_blocks: true // Should Checkout show its default blocks?
                    }
                  }
                },
                "handler": function (response) {
                  alert("Order Submitted Successfully");
                  //redirectConfirmation="orderConfirmation?rf="+data;
                  clsObject.updatePaymentStatus(response,data);
                  //window.location="orderConfirmation?rf="+data;
                  //alert(JSON.stringify(response));
                  //alert(response.razorpay_payment_id);
                },
                "modal": {
                  "ondismiss": function () {
                    if (window.confirm("Are you sure, you want to close the form?")) {
                      var txt = "You pressed OK!";
                      console.log("Checkout form closed by the user");
                    } else {
                      var txt = "You pressed Cancel!";
                      console.log("Complete the Payment")
                    }
                  }
                }
              };
        
        
              var rzp1 = new window.Razorpay(options);
              rzp1.open();
            };
          
            document.body.appendChild(script1);
          }else{
            alert("Order Submitted Successfully");
            window.location="orderConfirmation?rf="+data;
          }
           

    
    //alert("Payment testing");
       

          
         }else{
          alert(msg);
         }
         
       }

updatePaymentStatus(response,vref){
  //alert("On status update =="+vref);
  var map=[];
      map.push({name:"curl_type",value:"checkPaymentStatus"});
      map.push({name:"res_func",value:"callBackPUpd"});
      map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
      map.push({name:"invoice_id",value:vref});
      map.push({name:"status",value:"Paid"});
      map.push({name:"payment_trans_id",value:response.razorpay_payment_id})
      map["res_func"]="callBackPUpd";
      map["curl_type"]="checkPaymentStatus";
      CallMapMethod(clsObject,map);
}
callBackPUpd(data,resmsg){
  //alert(data+resmsg);
  if(data!=="0" && data!==""){
   window.location="orderConfirmation?rf="+data;
  }
}      
componentDidMount(){
  clsObject=this;
  if(loc===""){
    loc="home";
}
this.GetVendorList();
  this.callBackProList("-1","-1");
/*
  var mapCat=[];
  mapCat.push({name:"curl_type",value:"getProducts"});
  mapCat.push({name:"res_func",value:"callBackProList"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"p1",value:"new arrivals"});
  mapCat.push({name:"p2",value:"0"});
 // mapCat.push({name:"emp_id",value:userObject[0].id});
  mapCat["res_func"]="callBackProList";
  mapCat["curl_type"]="getProducts";
  CallMapMethod(clsObject,mapCat);
  */
  AjaxCallMethod(clsObject,"okSuccCNOT");

  
  if(loc===""){
      loc="home";
  }
  //alert(loc);
  //loc="blogs_article"
  
  
}
ImageThumbBack(flImg, flName,fObject,othInfo="") {
  //alert(flImg);
 
 
  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!=-1 || flImg.indexOf("data:video/mp4")!=-1){
     strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"icon-close _removeBtn"+indexExisting+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }
    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  objImages.push({ name: arrNmValue[1], value: flImg });
  $("#lsImages").val("{\"objs\":" + JSON.stringify(objImages) + "}");
  //alert(strSet);            
  $(".imgLibThumb").append(strSet);
  if(fObject!==null){
    fObject.val('');
  }
  $('.gallery-item').magnificPopup({
    type: 'image',
    gallery: {
        enabled: true
    }
});
RespBkMthdMapp(clsObject, "_removeBtn"+indexExisting, "rmFileProc", "2");
  //imgLibThumb
}
callBackpgData(data,msg){
    //alert("testing of2 "+data);
    //$("#pgComp").html(data);
    var listObj= JSON.parse("{\"access\":"+data+"}");
    //alert("successfully parse");
    var mainHt="";
    if(loc==="home"){
       // mainHt+=Video_Banner_Comp();
    }

    var injextI=0;
    var injCondition=false;
    var ifImgGallery=false;
    
    var strTopBanner="";
    for(var _dlist=0;_dlist<50;_dlist++){
     
        if(listObj.access[_dlist]){
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               strTopBanner=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
               // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
            }else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }else if(listObj.access[_dlist].image_gallery){
                injCondition=true;
                ifImgGallery=true;
                mainHt+=ImageGallery(listObj.access[_dlist].image_gallery);
            }else if(listObj.access[_dlist].video_slider){
                //alert("I am in testing");
                injCondition=true;
                mainHt+=Video_Slider(listObj.access[_dlist].video_slider);
            }
            else if(listObj.access[_dlist].blogs){
                
                injCondition=true;
                mainHt+=Blogs(listObj.access[_dlist].blogs);
            }else if(listObj.access[_dlist].feature_web_list){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Feature_Card(listObj.access[_dlist].feature_web_list);
            }else if(listObj.access[_dlist].article_listing){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Article_Listing(listObj.access[_dlist].article_listing);
            }else if(listObj.access[_dlist].collapse_pan){
                injCondition=true;
                mainHt+=Collapse_Pan(listObj.access[_dlist].collapse_pan);
            }else if(listObj.access[_dlist].time_line){
                injCondition=true;
                mainHt+=Time_Line(listObj.access[_dlist].time_line);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    mainHt+=Features_Slider();
                    //alert(products);
                    mainHt+=products;
                }
           }
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    //alert(mainHt);
    //mainHt+=Product_Hori_Slider();

    //Condition for leftMenuOpt
    if(rightBarLists){
        var getLeftOpt=Left_Menu_Option(rightBarLists,loc);
    var mainDiv="<div class=\"shop-category-area pb-100px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">";
    var mainCloseDiv="</div></div></div>";
    mainHt="<div class=\"col-lg-9 order-lg-last col-md-12 order-md-first\">"+
            mainHt+"</div>";
    mainHt=mainDiv+getLeftOpt+mainHt+mainCloseDiv;
      }
   mainHt=strTopBanner+mainHt;

    $("#pgComp").html(mainHt);

    RespBkMthdMapp(clsObject,"_addToCart","setCartBk");

    // AOS.init();
    // AOS.init({
    //   once: true
    // });
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);

    
      //this.forceUpdateHandler();
    if(ifImgGallery){
        $('.gallery-item').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }
  
}
setCartBk(refId){
   // alert(refId);
    this.setState(state =>({viewData:refId}));
    this.forceUpdate();
    $("#offcanvas-cart").addClass("offcanvas-open");
  }
callBackProList(data,msg){
       //alert("testing=="+data);
    if(data!=="0"){
    // var resPs= Product_Slider(data);
   
    // products+=resPs;
    
    }
    //alert("i am in lcick");
    var pgMap=[];
  pgMap.push({name:"curl_type",value:"getPgData"});
  pgMap.push({name:"res_func",value:"callBackpgData"});
  pgMap.push({name:"loc",value:loc});
 // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
  //mapCat.push({name:"emp_id",value:userObject[0].id});
  pgMap["res_func"]="callBackpgData";
  pgMap["curl_type"]="getPgData";
  CallCMSMapMethod(clsObject,pgMap);
  }
saveEdu(){
  GetImgCertUser(clsObject,"#frmEdu","getTest","okEdut");
}
okEdut(data,datares,userResp){
  //alert(data);
}
GetVendorList(){
  
      var map=[];
      map.push({name:"curl_type",value:"vendorsList"});
      map.push({name:"res_func",value:"callBackHospitals"});
      map.push({name:"sec",value:"27KeHNL8l225KpBePgi8Hg=="});
      map.push({name:"item_id",value:"hemo_dialysis"});
      map["res_func"]="callBackHospitals";
      map["curl_type"]="vendorsList";
      CallMapMethod(clsObject,map);
  
}
callBackHospitals(data,msg){
  //alert(data);
  if(data!=="0" && data!==""){
    defaultPurchaseCart=JSON.parse(data);
  }
  //alert(data);
   //in dialysis request case we have to push data manually;
   //after booknow process it should with default addtocart
}
    render(){
        return(<div>
          <Front_Header/>

          <div className="offcanvas-overlay"></div>
        
         <div id="pgComp">

         </div>
     
   

          <Footer/>
          
          
          
          
          <div className="modal fade" id="dialysis_booking" tabindex="-1" role="dialog">
          <div className="modal-dialog modal-xl" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                      <button type="button" className="close"  data-dismiss="modal" aria-label="Close"><span
                              aria-hidden="true">x</span></button>
                  </div>
                  <div className="modal-body">
                  <div class="container-fluid px-1 py-5 mx-auto">
    <div class="row d-flex justify-content-center">
        <div class="col-xl-10 col-lg-8 col-md-9 col-11 text-center">
            <h3>Online Booking</h3>
            <p class="blue-text">Advaiet Nephro Care Center.</p>
            <div class="card dia_cart">
                <h5 class="text-center mb-4">Patient Details</h5>
                <form id="frmEdu" name="frmEdu" enctype="multipart/form-data" method="POST" action="#" class="form-card" onsubmit="event.preventDefault()">
                <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                        <input type="hidden" name="curl_type" id="curl_type" value="insEducationDetails" />
                        <input type="hidden" name="m_type" id="m_type" value="insEducationDetails" />
                        <input  type="hidden" name="type_id" id="type_id" value="19" />
                        <input type="hidden" name="host_id" id="host_id" value="0"/>
                        <input type="hidden" name="emp_id" id="emp_id" value="0"/>
                        <input type="hidden" name="col6" id="col6" value=""/>
                        <input type="hidden" name="lsImages" id="lsImages" />
                        <div class="row justify-content-between text-left">
                    
                        <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Dialysis Center<span class="text-danger"> *</span></label>    
                        <select className="form-control required" id="location_id" name="location_id">
          <option value="0">-Select-</option>
            <option value="30468">Aastha Hospital Jhunjhnu</option>
            <option value="30469">Hinduja Hospital Khandwa</option>
            
          </select> </div>
          <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Patient Name<span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="company_name" name="company_name" placeholder="Name" onblur="validate(1)" /> </div>
          
                    </div>
                    {/* <div class="row justify-content-between text-left">
                        <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Patient Name<span class="text-danger"> *</span></label> <input className="form-control required" autoComplete='off'  type="text" id="company_name" name="company_name" placeholder="Name" onblur="validate(1)" /> </div>
                        <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Last Name<span class="text-danger"> *</span></label> <input className="form-control required" type="text" id="person_name" name="person_name" placeholder="Last Name" onblur="validate(2)" /> </div>
                    </div> */}
                    <div class="row justify-content-between text-left">
                    <div class="form-group col-sm-5 flex-column d-flex"> <label class="form-control-label px-3">Contact No<span class="text-danger"> *</span></label> <input className="form-control required" type="text" id="contact_no" name="contact_no" placeholder="" onblur="validate(4)" /> </div>
                        <div class="form-group col-sm-4 flex-column d-flex"> <label class="form-control-label px-3">Gender<span class="text-danger"> *</span></label>    <select className="form-control required" id="gender" name="gender">
          <option value="0">-Select-</option>
            <option>Male</option>
            <option>Female</option>
            <option>TG/TS</option>
          </select> </div>
          <div class="form-group col-sm-3 flex-column d-flex"> <label class="form-control-label px-3">Age<span class="text-danger"> *</span></label> <input className="form-control required" type="text" id="age" name="age" placeholder="Age" onblur="validate(4)" /> </div>
                    </div>
                    <div class="row justify-content-between text-left">
                        <div class="form-group col-12 flex-column d-flex"> <label class="form-control-label px-3">Present Address?<span class="text-danger"> *</span></label> <input className="form-control required" type="text" id="address" name="address" placeholder="" onblur="validate(6)" /> </div>
                    </div>
                    <div className="row audioLibThumb imgLibThumb">
                      
</div>
                    <table className="table mb-0">
    <tbody>
      <tr>
        <td>
          <img setTo="customFileBr" className="_brFileThumb updthumb" src={TkMeter} alt=""/>
          <div id="resCount" className="resCount"></div>
          <input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" className="updImgThumb" />
        </td>
        <td><a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb" style={{fontSize:"0.75rem"}}>Upload Prescription,Important Document (Max 10) Only Image or Pdf</a></td>
      </tr>
     
    </tbody>
  </table>
  <div class="row justify-content-between text-left mt-5">
    <div className='form-group col-sm-6 flex-column d-flex'>
    <label class="form-control-label px-3 mb-5">Date<span class="text-danger"> *</span></label>
    <TableDatePicker refName="date_for"/>
    
    </div>
  <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3 mb-5">Select Available Time-Slot<span class="text-danger"> *</span></label>
  <div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" value="09:00 hrs - 13:00 hrs" />
  <label class="form-check-label" for="flexRadioDefault1">
  09:00 hrs - 13:00 hrs
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="14:00 hrs - 18:00 hrs" checked />
  <label class="form-check-label" for="flexRadioDefault2">
  14:00 hrs - 18:00 hrs
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Emergency Dialysis" checked />
  <label class="form-check-label" for="flexRadioDefault2">
  Emergency Dialysis
  </label>
</div>
</div>
  </div>
  <div class="row justify-content-between text-left">
                    
                    <div class="form-group col-sm-6 flex-column d-flex"> <label class="form-control-label px-3">Pay Amount *Rs 1500/-<span class="text-danger"> *</span></label>    
                    <select className="form-control required" id="payment_mode" name="payment_mode">
      <option value="0">-Payment Mode-</option>
        <option value="online">Online</option>
        <option value="pdc">Pay at Dialysis Center</option>
        
      </select> </div>
      
                </div>

                    <div class="row justify-content-end">
                        <div class="form-group col-sm-6"> <button type="button" onClick={()=>this.submitInv("#frmEdu")} class="btn-block btn-primary">Book Now</button> </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
                  </div>
              </div>
          </div>
          </div>
          </div>
          
          );
    }
}
export default Dialysis_Module