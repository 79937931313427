import $ from 'jquery';
//import 'jquery-ui';
import React,{ useState } from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import DatePicker from "react-datepicker";
import { Accordion,Card,Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {CanvasJSReact} from '../assets/canvasjs.react';
import CorrImg from '../assets/images/doc-green-sign.jpg';
import WrgImg from '../assets/images/doc-red-sign.jpg';
import OrgChart from '@balkangraph/orgchart.js';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
var XMLParser = require('react-xml-parser');
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

window.jQuery = $;
window.$ = $;
global.jQuery = $;
let jQuery=$;


//  const appUrl="https://localhost:44339/AppProcess.asmx/";
//  const appUrtPath="https://localhost:44339/";
//  const webUrl="https://advaiet.com/";

const appUrl="https://app.advaiet.com/AppProcess.asmx/";
const appUrtPath="https://app.advaiet.com/";
const webUrl="https://advaiet.com/";


/*
const appUrl="https://test.advaiet.com/AppProcess.asmx/";
const appUrtPath="https://test.advaiet.com/";
const webUrl="https://advaiet.com/";
*/

export const appGetUrlPath=()=>{
  return appUrtPath;
}
export const getMainUrl=()=>{
  return webUrl;
}
export const drawChart=(divId)=>{
  OrgChart.templates.ana.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
  + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';

OrgChart.templates.invisibleGroup.padding = [20, 0, 0, 0];

var chart = new OrgChart(document.getElementById(divId), {
  template: "ana",
  enableDragDrop: true,
  assistantSeparation: 170,
  menu: {
      pdfPreview: {
          text: "Export to PDF",
          icon: OrgChart.icon.pdf(24, 24, '#7A7A7A'),
          onClick: preview
      },
      csv: { text: "Save as CSV" }
  },
  nodeMenu: {
      details: { text: "Details" },
      edit: { text: "Edit" },
      add: { text: "Add" },
      remove: { text: "Remove" }
  },
  align: OrgChart.ORIENTATION,
  toolbar: {
      fullScreen: true,
      zoom: true,
      fit: true,
      expandAll: true
  },
  nodeBinding: {
      field_0: "name",
      field_1: "title",
      img_0: "img"
  },
  tags: {
      "top-management": {
          template: "invisibleGroup",
          subTreeConfig: {
              orientation: OrgChart.orientation.bottom,
              collapse: {
                  level: 1
              }
          }
      },
      "it-team": {
          subTreeConfig: {
              layout: OrgChart.mixed,
              collapse: {
                  level: 1
              }
          },
      },
      "hr-team": {
          subTreeConfig: {
              layout: OrgChart.treeRightOffset,
              collapse: {
                  level: 1
              }
          },
      },
      "sales-team": {
          subTreeConfig: {
              layout: OrgChart.treeLeftOffset,
              collapse: {
                  level: 1
              }
          },
      },
      "seo-menu": {
          nodeMenu: {
              addSharholder: { text: "Add new sharholder", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: addSharholder },
              addDepartment: { text: "Add new department", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: addDepartment },
              addAssistant: { text: "Add new assitsant", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: addAssistant },
              edit: { text: "Edit" },
              details: { text: "Details" },
          }
      },
      "menu-without-add": {
          nodeMenu: {
              details: { text: "Details" },
              edit: { text: "Edit" },
              remove: { text: "Remove" }
          }
      },
      "department": {
          template: "group",
          nodeMenu: {
              addManager: { text: "Add new manager", icon: OrgChart.icon.add(24, 24, "#7A7A7A"), onClick: addManager },
              remove: { text: "Remove department" },
              edit: { text: "Edit department" },
              nodePdfPreview: { text: "Export department to PDF", icon: OrgChart.icon.pdf(24, 24, "#7A7A7A"), onClick: nodePdfPreview }
          }
      }
  }
});

chart.on("added", function (sender, id) {
  sender.editUI.show(id);
});

chart.on('drop', function (sender, draggedNodeId, droppedNodeId) {
  var draggedNode = sender.getNode(draggedNodeId);
  var droppedNode = sender.getNode(droppedNodeId);

  if (droppedNode.tags.indexOf("department") != -1 && draggedNode.tags.indexOf("department") == -1) {
      var draggedNodeData = sender.get(draggedNode.id);
      draggedNodeData.pid = null;
      draggedNodeData.stpid = droppedNode.id;
      sender.updateNode(draggedNodeData);
      return false;
  }
});

chart.editUI.on('field', function (sender, args) {
  var isDeprtment = sender.node.tags.indexOf("department") != -1;
  var deprtmentFileds = ["name"];

  if (isDeprtment && deprtmentFileds.indexOf(args.name) == -1) {
      return false;
  }
});

chart.on('exportstart', function (sender, args) {
  args.styles = document.getElementById('myStyles').outerHTML;
});

chart.load([
  { id: "top-management", tags: ["top-management"] },
  { id: "hr-team", pid: "top-management", tags: ["hr-team", "department"], name: "HR department" },
  { id: "it-team", pid: "top-management", tags: ["it-team", "department"], name: "IT department" },
  { id: "sales-team", pid: "top-management", tags: ["sales-team", "department"], name: "Sales department" },

  { id: 1, stpid: "top-management", name: "Rock Egnine", title: "CEO", img: "/9.png", tags: ["seo-menu"] },
  { id: 2, pid: 1, name: "Share1", title: "Shareholder (51%)", img: "/9.png", tags: ["menu-without-add"] },
  { id: 3, pid: 1, name: "Share2", title: "Shareholder (49%)", img: "/9.png", tags: ["menu-without-add"] },

  { id: 4, stpid: "hr-team", name: "Shailendra", title: "HR Manager", img: "/9.png" },
  { id: 5, pid: 4, name: "Aakash", title: "Senior HR", img: "/9.png" },
  { id: 6, pid: 4, name: "Aman", title: "Junior HR", img: "/9.png" },

  { id: 7, stpid: "it-team", name: "TopTeam2", title: "Core Team Lead", img: "/9.png" },
  { id: 8, pid: 7, name: "TopTeam1", title: "Backend Senior Developer", img: "/9.png" },
  { id: 9, pid: 7, name: "TopTeam3", title: "C# Developer", img: "/9.png" },
  { id: 10, pid: 7, name: "TopTeam4", title: "Backend Junior Developer", img: "/9.png" },

  { id: 11, stpid: "it-team", name: "Testing De1", title: "UI Team Lead", img: "/9.png" },
  { id: 12, pid: 11, name: "Testing JN2", title: "JS Developer", img: "/9.png" },
  { id: 13, pid: 11, name: "jan1", title: "Graphic Designer", img: "/9.png" },
  { id: 14, pid: 11, name: "jan2", title: "UX Expert", img: "/9.png" },
  { id: 15, stpid: "sales-team", name: "sc topper1", title: "Sales Manager", img: "/9.png" },
  { id: 16, pid: 15, name: "Aman23", title: "Sc Topper2", img: "/9.png" },
  { id: 17, pid: 15, name: "Aman24", title: "Sc Topper3", img: "/9.png" },
  { id: 18, pid: "top-management", name: "Senior", title: "Personal assistant", img: "/9.png", tags: ["assistant", "menu-without-add"] }
]);

function preview() {
  OrgChart.pdfPrevUI.show(chart, {
      format: 'A4'
  });
}

function nodePdfPreview(nodeId) {
  OrgChart.pdfPrevUI.show(chart, {
      format: 'A4',
      nodeId: nodeId
  });
}

function addSharholder(nodeId) {
  chart.addNode({ id: OrgChart.randomId(), pid: nodeId, tags: ["menu-without-add"] });
}

function addAssistant(nodeId) {
  var node = chart.getNode(nodeId);
  var data = { id: OrgChart.randomId(), pid: node.stParent.id, tags: ["assistant"] };
  chart.addNode(data);
}


function addDepartment(nodeId) {
  var node = chart.getNode(nodeId);
  var data = { id: OrgChart.randomId(), pid: node.stParent.id, tags: ["department"] };
  chart.addNode(data);
}

function addManager(nodeId) {
  chart.addNode({ id: OrgChart.randomId(), stpid: nodeId });
}
}


export const setProfilePic=(obj, bdata)=> {
  //alert(bdata);
  if(bdata!= null && bdata!=="0"){
    obj.src =  bdata;
  }
}


export const customAlert=(title,msg,urls,oks="0",yesno="0")=>{
   var buttons_=[];
   if(yesno!=="0"){
     buttons_= [
         {
           label: 'Yes',
           onClick: () => window.location.href=urls
         },
         {
           label: 'No',
           onClick: () => window.location.href=urls
         }
       ];
   }else if(oks!=="0"){
     if(urls!=="0"){
      buttons_=[
        {
          label: 'ok',
          onClick: () => window.location.href=urls
        }];
     }else{
      buttons_=[
        {
          label: 'ok',
        }];
     }
      
   }

   confirmAlert({
      title: title,
      message: msg,
      buttons: buttons_
    });
}

export function readURL(input) {
  var attr = $(input).attr('ref_count');
  if(typeof attr!=='undefined' && attr !== false){
    $('.'+$(input).attr("ref_count")).text("Uploaded Count -"+input.files.length);
 }

   if (input.files && input.files[0]) {
     //alert("i am read url");
       var reader = new FileReader();
       reader.onload = function (e) {
         //alert($(input).attr("ref_img"));
           $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
       }

       reader.readAsDataURL(input.files[0]);
   }
}

export const getAttendanceList=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["month_nm"]+"</td><td>P:["+lists[i]["Present"]+"]</td><td>A:["+lists[i]["Absents"]+
      "]</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table class=\"table table-sm table-borderless\"></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
}

export const GetTripReports=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      var strToClose="";
      if(lists[i]["tip_status"]==="Open"){
            strToClose="<tr><td style=\"color:red\">Not Completed: </td><td>"+
            "<a href=\"/OnTripComp?ref="+lists[i]["id"]+"\">Click to Mark as Completed</a></td></tr>"
      }
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["entry_date"]+"</td><td>Status :"+lists[i]["tip_status"]+"</td>"+
      "</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table  class=\"table table-sm table-borderless\"><tr>"+"<td>Started From :</td>"+
          "<td>"+lists[i]["trip_start_from"]+"</td></tr><tr>"+
          "<td>Initial Reading : </td>"+"<td>"+lists[i]["init_km_read"]+"</td></tr><tr>"+
          "<td>Destination To :</td>"+"<td>"+lists[i]["trip_desti_to"]+"</td></tr><tr>"+
          "<td>Trip End Km :</td>"+"<td>"+lists[i]["comp_km_read"]+"</td></tr><tr>"+
          "<td>Status :</td>"+"<td>"+lists[i]["tip_status"]+"</td>"+strToClose+
          "</tr></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
}

export const GetLeaveRequest=(lists)=>{
   var headStr="<div class=\"accordion\" id=\"accordionExample\">";
   lists=JSON.parse(lists);
   var str="";
   for(var i=0;i<lists.length;i++){
      str+="<div class=\"card\">"+
      "<div class=\"card-header\" class=\"btn btn-link\" type=\"button\" data-toggle=\"collapse\" data-target=\"#collapse"+i+"\" aria-expanded=\"true\" aria-controls=\"collapse"+i+"\" id=\"heading"+i+"\">"+
      "<table class=\"table table-sm table-borderless\">"+
      "<tr><td>"+lists[i]["col1"]+"</td><td>"+lists[i]["date"]+"</td><td>"+lists[i]["status"]+
      "</td></tr>"+
      "</table>"+
      "</div>"+
      "<div id=\"collapse"+i+"\" class=\"collapse show\" aria-labelledby=\"heading"+i+"\" data-parent=\"#accordionExample\">"+
        "<div class=\"card-body\">"+
          "<table  class=\"table table-sm table-borderless\"><tr><td colspan=\"3\">"+ lists[i]["details"]+"</td></tr>"+
          "<tr><td>From</td><td>To</td><td>Status</td></tr>"+
          "<tr>"+"<td>"+lists[i]["c_req_from"]+"</td>"+
          "<td>"+lists[i]["c_req_to"]+"</td>"+
          "<td>"+lists[i]["status"]+"</td>"+
          "</tr></table>"+
        "</div>"+
      "</div>"+
    "</div>";
   }
  
 str=headStr+str+"</div>";
     return str;
   
}

export function GetGraphData(setData){
  
  //alert(JSON.stringify(titleg));
  const options = {
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "Result Score : "+setData.titleg+"%"
    },
          height:300,
    data: [{
      type: "pie",
              radius:"50%",
      startAngle: 30,
      toolTipContent: "<b>{label}</b>: {y}%",
      showInLegend: "true",
      legendText: "{label}",
      indexLabelFontSize: 16,
      indexLabel: "{label} - {y}%",
      dataPoints: setData.setData
    }]
  }
  return(
    <CanvasJSChart options = {options} />
  );
}

export function TableDatePicker(refName) {
   //alert(JSON.stringify(refName.refName));
   var refNm= refName.refName;
   const [date, setDate] = useState(new Date());
   //minDate={date} for now commented
   return (
     <DatePicker  className="form-control" dateFormat="dd/MM/yyyy" id={refNm} name={refNm}  format='dd-MM-yyyy' selected={date} onChange={date => setDate(date)} />
   );
  }

export const SetResponseDiv=(divName,respTxt)=>{
   $("#"+divName).html(respTxt);
   return "done";
}

export const defaultClick=(obCls,appliedString)=>{
   $(appliedString).click(function(){
   var appFunction =$(this).attr("attEvt");
      obCls[appFunction]("test");
   });
   //return obCls[clFunction]();
}
export function listClock(chInterval){
	var x = chInterval; //minutes interval
var times = []; // time array
var tt = 0; // start time
var ap = ['AM', 'PM']; // AM-PM

//loop to increment the time and push results in array
for (var i=0;tt<24*60; i++) {
  var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
  var mm = (tt%60); // getting minutes of the hour in 0-55 format
  times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
  tt = tt + x;
}

return times;

//console.log(times);
}

export const dateString2Date=(dateString,addMinits=0)=> {
  /*
  const dt = dateString.split(/\-|\s/);
  return new Date(dt.slice(0, 3).reverse().join('-') + ' ' + dt[3]);
  */
  
  let existingDate = new Date(Date.parse(dateString));
  let newDate=existingDate;
  if(addMinits!==0){
    existingDate.setMinutes(existingDate.getMinutes() + 90);
    //alert("i m incond");
    newDate=existingDate;
  }
  //alert(existingDate+"==="+newDate);
  return existingDate;
}

export const GetCurrentDtTime=()=>{
  var currentDate=new Date();
  return currentDate.toJSON().slice(0,19);
}
export const SetDate=(date)=> {
   //z = $(date).attr('value');
  
   var today = new Date();
   var dd = today.getDate();
   var mm = today.getMonth() + 1; //January is 0!

   var yyyy = today.getFullYear();
   if (dd < 10) { dd = '0' + dd }
   if (mm < 10) { mm = '0' + mm }
   //today = yyyy + '-' + mm + '-' + dd;
   today = dd + '/' + mm + "/" + yyyy;
   //today=mm+"/"+dd+"/"+yyyy;
   //alert("check== " + today);
   $(date).text(today);
   //$(date).datepicker({dateFormate:'dd/mm/yy'}).val();
}

export const GetImgCert=(obCls,frmNm,curl,resfun)=>
{
   var options={
      url     : appUrl+curl,
      success : function(data)
      {
        var resData=data.documentElement.childNodes[0].nodeValue;
       
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
      
         return obCls[resfun](checkData,checkMsg);
          
   },
      error: function(data) {
      
         return obCls[resfun](0,"error "+data);
       }
     };
  
   $(frmNm).ajaxSubmit( options );
}
export const RespBkMthdMapp=(obCls,htCls,cBack,groupwhen="")=>{
  
  if(htCls==="onchangeselect"){
    $(".onchangeselect").change(function(){
      return obCls[cBack](this.value);
    });
    
  }

  
  /* filterevents */
  if(htCls==="hevent_flt_rightbar"){
    let fltCategories="";
    let fltColors="";
    let fltBrands="";
    
    let fltPush={};
    let lowRange=-1;
    let highRange=-1;

    $(".hcls_flt_color").change(function(){
      fltColors="";
      if(this.value!==0 && this.value!=="0"){
        // if(fltColors!==""){
        //   fltColors+=",";
        // }
        fltColors=this.value;
        fltPush={"categories":fltCategories,
        "brands":fltBrands,
        "color":fltColors,
        "lowRange":lowRange,
        "highRange":highRange};
  
  cBack(fltPush);//callback
      }
    });
    /*
    let colors=document.getElementsByClassName("hcls_flt_color");
    for(let i=0;i<colors.length;i++){
      if(colors[i].value!==0 && colors[i].value!=="0"){
        if(fltColors!==""){
          fltColors+=",";
        }
        fltColors+=colors[i].value;
      }
    }
*/
    $(".hevent_flt_rightbar").click(function(){
      fltCategories="";
      fltBrands="";
      lowRange=-1;
      highRange=-1;

      let listCat=document.getElementsByClassName("hcls_flt_cate");
      for(let i=0;i<listCat.length;i++){
        if(listCat[i].checked===true){
          //alert("yes select "+listCat[i].value);
          fltCategories=listCat[i].value
        }
      }

     

      let listBrands=document.getElementsByClassName("hcls_flt_brand");
      for(let i=0;i<listBrands.length;i++){
        if(listBrands[i].checked===true){
          if(fltBrands!==""){
            fltBrands+=",";
          }
          fltBrands+=listBrands[i].value;
        }
      }

      let listPrice=document.getElementsByClassName("hcls_flt_price");
     
      for(let i=0;i<listPrice.length;i++){
        if(listPrice[i].checked===true){
         
          let chkRange=listPrice[i].value.trim().split(',');
          if(parseInt(lowRange)==-1){
            lowRange=parseInt(chkRange[0]);
          }
          if(parseInt(lowRange)>parseInt(chkRange[0])){
            lowRange=parseInt(chkRange[0]);
          }
          if(parseInt(highRange)<parseInt(chkRange[1])){
            highRange=parseInt(chkRange[1]);
          }
          if(chkRange[1]==="-11" || chkRange[1]===-11){
            highRange=parseInt(chkRange[1]);
          }

        }
      }
     //alert(highRange);
      fltPush={"categories":fltCategories,
      "brands":fltBrands,
      "color":fltColors,
      "lowRange":lowRange,
      "highRange":highRange};

cBack(fltPush);//callback
   
    });
}
  
  if(groupwhen==="2"){
    $("."+htCls).click(function(){
      var refId=$(this).attr("param1");
      let lsControl="";
      if(this.hasAttribute("param2")){
        lsControl=$(this).attr("param2");
      }
      return obCls[cBack](refId,lsControl);
   });
  }
  if(htCls==="filebr_name"){
   // $('#customFile').on('change', function(){ files = $(this)[0].files; name = ''; for(var i = 0; i < files.length; i++){ name += '\"' + files[i].name + '\"' + (i != files.length-1 ? ", " : ""); } $(".custom-file-label").html(name); });
   
  }
  if(htCls==="_setbackparam"){
    $("._setbackparam").click(function(){
      var refId=$(this).attr("param1");
      return obCls[cBack](refId);
   });
  }
  if(htCls==="select_file_ch"){
    $(".select_file_ch").change(function () {
    
     var nmFile= $(this).attr("param1");
     var URL = window.URL || window.webkitURL;
       var vidFile=this.files[0];
       var fileURL = URL.createObjectURL(vidFile);
      document.getElementById(nmFile).src=fileURL;
    });
  }
 if(htCls==="hid_file_ch"){
  var tabcontent = document.getElementsByClassName(htCls);
  return tabcontent;
  /*
  for(var t=0;t<tabcontent.length;t++){
   //alert("testing=="+tabcontent[t].value);
   var fileAttr=tabcontent[t].getAttribute("param1");
   return fileAttr;
   //alert(fileAttr+"=="+tabcontent[t].value);
   //document.getElementById(fileAttr).value=tabcontent[t].value;
  }
  */
 }else if(htCls==="_addToCart"){
  $('.'+htCls).click(function(){
    //  alert("I am in click");

     let ssFrm=$(this).closest('form');
     let getItem= localStorage.getItem("_cart");
     var items=[];
     if(getItem!=null && getItem!==""){
        getItem=JSON.parse(localStorage.getItem("_cart"));
     }else{
        getItem=[];
     }

     var itemName=$(ssFrm).find('input[name="item_name"]').val();
     var itemCode=$(ssFrm).find('input[name="item_code"]').val();
     var itemRate=$(ssFrm).find('input[name="item_rate"]').val();
     var compName=$(ssFrm).find('input[name="comp_name"]').val();
     var itemRfId=$(ssFrm).find('input[name="item_rf_id"]').val();
     let itemParentId=$(ssFrm).find('input[name="parent_id"]').val();
     var imgPath=$(ssFrm).find('input[name="imgs"]').val();
    
     items.push({"item_name":itemName,"item_code":itemCode,
     "item_rate":itemRate,"company_name":compName,"item_rf_id":itemRfId,"img_c":imgPath,
    "parent_id":itemParentId});
     for(var i=0;i<getItem.length;i++){
       //alert(getItem[i].item_name);
       items.push({"item_name":getItem[i].item_name,"item_code":getItem[i].item_code,
       "item_rate":getItem[i].item_rate,"company_name":getItem[i].company_name,"item_rf_id":getItem[i].item_rf_id,"img_c":getItem[i].img_c,
      "parent_id":getItem[i].parent_id});
     }
    
     localStorage.setItem("_cart",JSON.stringify(items));
  
    if(cBack!=="nn" && cBack!=""){
      //alert(cBack);
     obCls[cBack](items.length);
    }
    });
} 
}

export const displayQuest=(index,divClsName)=>{
  var tabcontent = document.getElementsByClassName(divClsName);
  for (var i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  if(index!=-1){
    tabcontent[index].style.display="block";
  }
  
}
export const setKeyUpMapp=(obCls,cBack)=>{
   
  $("._setSearchKey").keyup(function(e){
     var refId= $(this).val();
        return obCls[cBack](refId);
   });
   /*
  var obj=document.getElementsByClassName("_setSearchKey")[0];
  if(obj!=null){
     obj.onchange = function(){
        var refId= $(this).val();
        return obCls[cBack](refId);
      }
  }
  */
}


export const btnSetColor_CBack=(obCls,frmNm,curl,callback,extra_push=[])=>{
  /* Validation */
  let frmName=frmNm;
  // alert(typeof ssFrm);
  let objElems=document.getElementById(frmName).getElementsByClassName("required");
  let isValidated=true;
  // alert(objElems.length);
  for(var r=0;r<objElems.length;r++){
    if(objElems[r].value==="" || objElems[r].value==="0"){
      objElems[r].style.border="2px solid rgb(225 13 13)";
      isValidated=false;
    }
  }
  //var validRegexEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  let validRgEmail=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
 let objEmail=document.getElementById(frmName).getElementsByClassName("required_email");
 for(var r=0;r<objEmail.length;r++){
 //alert("emai=="+objEmail[r].value.match(validRgEmail));
  if(objEmail[r].value==="" || objEmail[r].value==="0" || !objEmail[r].value.match(validRgEmail)){
    objEmail[r].style.border="2px solid rgb(225 13 13)";
    isValidated=false;
  }
 }
 
 if(!isValidated){
  
   return callback(-10,"Fill blank records!"); 
 }

 //value objects
 ajaxindicatorstart("Loading Please Wait..");
var ssFrm=$("#"+frmNm);
var m_type= $(ssFrm).find('input[name="m_type"]').val();
var curl_type=$(ssFrm).find('input[name="m_type"]').val();
var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
 var ss = $("#"+frmNm).serializeArray();
 if(extra_push.length>0){
 // alert("i m in extra push");
  ss=ss.concat(extra_push);
 }
 
 let xs = "{'objs':" + JSON.stringify(ss) + "}";

 //let xs=ss;
 //alert(xs);
 //return;

if(!getCallFunc){
getCallFunc="";

 }
 $.ajax({
  crossDomain: true,
  type: "POST",
url: appUrl + curl_type,
data: xs,
contentType: "application/json;charset=utf-8",
dataType: "json",
   success: function (data) {
     ajaxindicatorstop();
      //alert(data.d);
       //ajaxindicatorstop();
          //alert("Registration Completed Successfully ");
          // document.getElementById("frmRegister").reset();
          //location.reload();
           if(getCallFunc!="")
           {
            //alert(getCallFunc);
              var checkData=$($($.parseHTML(data.d)).filter("#resP")).text();
              var checkMsg=$($($.parseHTML(data.d)).filter("#resPS")).text();
        //  eval(getCallFunc);
        //alert(checkData);
              return callback(checkData,checkMsg);
           }else
           {
             //parent.$.colorbox.close();
          }
      }
  });

}



export const getHmProducts=(lists)=>{
  var strProd="";
  var getLists=JSON.parse(lists);
  var count=0;
  for(var i=0;i<getLists.length;i++){
    strProd+="<div class=\"col-lg-3 col-md-6 col-sm-6 col-xs-6 mb-6\" data-aos=\"fade-up\" data-aos-delay=\"200\">"+
             "<div class=\"product\">"+
        "<div class=\"thumb\">"+
            "<a href=\"shop-left-sidebar.html\" class=\"image\">"+
                "<img src=\""+appUrtPath+getLists[i]["img_path"]+"\" alt=\"Product\" />"+
                "<img class=\"hover-image\" src=\""+appUrtPath+getLists[i]["img_path"]+"\" alt=\"Product\" />"+
            "</a>"+
            "<span class=\"badges\">"+
                "<span class=\"new\">New</span>"+
            "</span>"+
            "<div class=\"actions\">"+
                "<a href=\"wishlist.html\" class=\"action wishlist\" title=\"Wishlist\"><i class=\"icon-heart\"></i></a>"+
                "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
                "<a href=\"compare.html\" class=\"action compare\" title=\"Compare\"><i class=\"icon-refresh\"></i></a>"+
            "</div>"+
            "<button title=\"Add To Cart\" class=\"add-to-cart\">Add To Cart</button>"+
        "</div>"+
        "<div class=\"content\">"+
            "<h5 class=\"title\"><a href=\"shop-left-sidebar.html\">"+getLists[i]["product_name"]+"</a></h5>"+
            "<span class=\"price\">"+
                "<span class=\"new\">Rs."+getLists[i]["cost"]+"</span>"+
            "</span>"+
        "</div>"+
    "</div>"+
    "</div>";
              }
              return strProd;
}

export const getAttemptQuestions=(lists)=>{
  var strQuet="";
  var getLists=JSON.parse(lists);
  var count=0;
  //alert(getLists.length);
  for(var i=0;i<getLists.length;i++){
    count=i+1;
   // var obList=$data['user_list'][$qk];
  var divName="step"+(count);
  var nextDiv="step"+(count+1);
  var preDiv="step"+(count-1);
  
  //var cName=getLists[i]["id"]+"__Q";
  //var hName= getLists[i]["id"]+"__H";
  //var correctAns=getLists[i]["col6"];
var wrongImg="<img alt=\"wrong\" src=\"/doc-red-sign.jpg\"/>";
var rightImg="<img alt=\"right\" src=\"/doc-green-sign.jpg\"/>";
var lbCustA="custom-control-label";
var lbCustB="custom-control-label";
var lbCustC="custom-control-label";
var lbCustD="custom-control-label";


var corColorA="";
var corColorB="";
var corColorC="";
var corColorD="";
if(getLists[i]["col3"]=="a"){
  corColorA= " correct_ans_color";
}else if(getLists[i]["col3"]=="b"){
  corColorB= " correct_ans_color";
}else if(getLists[i]["col3"]=="c"){
  corColorC= " correct_ans_color";
}else if(getLists[i]["col3"]=="d"){
  corColorD= " correct_ans_color";
}

if(getLists[i]["col2"]==getLists[i]["col3"]){
   if(getLists[i]["col2"]=="a"){
     lbCustA="custome-lable-right";
   }else if(getLists[i]["col2"]=="b"){
     lbCustB="custome-lable-right";
   }else if(getLists[i]["col2"]=="c"){
     lbCustC="custome-lable-right";
   }else if(getLists[i]["col2"]=="d"){
     lbCustD="custome-lable-right";
   }
}else{
  if(getLists[i]["col2"]=="a"){
    lbCustA="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="b"){
    lbCustB="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="c"){
    lbCustC="custome-lable-wrong";
  }else if(getLists[i]["col2"]=="d"){
    lbCustD="custome-lable-wrong";
  }
}
    strQuet+="<div id=\""+divName+"\" style=\"display:block\"  class=\"tabcontent card border p-3 mt-3\">"+
    "<p class=\"ss-font-style-2\"><i className=\"icofont-listing-number\"></i>"+"Q."+count+")"+getLists[i]["question"]+"?"+"</p>"+
    "<div class=\"w-100\"></div>"+
    "<ul class=\"list-group\">"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustA+" "+corColorA+"\">"+getLists[i]["opt_a"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustB+" "+corColorB+"\">"+getLists[i]["opt_b"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustC+" "+corColorC+"\" for=\""+"c"+"\">"+getLists[i]["opt_c"]+"</label>"+
        "</div>"+
      "</li>"+
      "<li class=\"list-group-item d-flex justify-content-between align-items-center\">"+
        "<div class=\"custom-control\">"+
          "<label class=\""+lbCustD+" "+corColorD+"\" for=\""+"d"+"\">"+getLists[i]["opt_d"]+"</label>"+
        "</div>"+
      "</li>"+
    "</ul>"+
  "</div>";
  }
  return strQuet;
}


export const TimeLine=(lists)=>{
  var getLists=JSON.parse(lists);
  var strResp="";
  var strTimeL="";
  var strDivText="";
  var strImgText="";
  var strVidText="";
  var strOth="";
  for(var i=0;i<getLists.length;i++){
     strTimeL="";
     strDivText="";
     strImgText="";
     strVidText="";
     strOth="";
     var createdDate = new Date(getLists[i]["entry_date"]);
    strTimeL="<div class=\"time-label\">"+
     "<span class=\"bg-primary\">"+getLists[i]["entry_date"]+"</span>"+
   "</div>";
   strTimeL+="<div>"+
   "<i class=\"fa fa-comments bg-yellow\"></i>"+
   "<div class=\"timeline-item\">"+
   "<span class=\"time\"><i className=\"fa fa-clock\"></i> for self</span>"+
   "<h3 class=\"timeline-header\"><a href=\"#\">"+getLists[i]["col1"]+"</a></h3>";
   if(getLists[i]["details"]!==""){
    strDivText="<div class=\"timeline-body\">"+getLists[i]["details"]+"</div>";
   }
   if(getLists[i]["file_name"]!==""){
     var files=getLists[i]["file_name"];
     var arrFiles=files.split(',');
     for(var fi=0;fi<arrFiles.length;fi++){
       //alert(arrFiles[fi]);
       if(arrFiles[fi].toUpperCase().indexOf(".JPG")!=-1 || 
       arrFiles[fi].toUpperCase().indexOf(".PNG")!=-1 || arrFiles[fi].toUpperCase().indexOf(".JPEG")!=-1){
         if(strImgText==""){
           strImgText="<div class=\"timeline-body\">";
         }
        strImgText+="<img param1=\""+arrFiles[fi]+"\" height=\"100\" width=\"150\" class=\"hid_file_ch gallery-item\" alt=\"...\"/>";
       }else if(arrFiles[fi].toUpperCase().indexOf(".MP4")!=-1){
        
        if(strVidText==""){
           strVidText="<div class=\"timeline-body\">";
         }
         strVidText+="<video param1=\""+arrFiles[fi]+"\" id=\""+fi+getLists[i]["id"]+"\" class=\"hid_file_ch\" controls type=\"video/mp4\"></video>"; 
         //strVidText+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\"><i class=\"fa fa-play-circle-o fa-2x\" aria-hidden=\"true\"></i>"+arrFiles[fi]+"</a>";
       }else{
          if(strOth==""){
            strOth="<div class=\"timeline-body\">";
          }
          strOth+="<a class=\"_setbackparam\" href=\"javascript:void(0)\" param1=\""+arrFiles[fi]+"\">"+arrFiles[fi]+"</a>";
       }
       
     }
     if(strImgText!==""){
       strImgText+="</div>";
     }
     if(strVidText!==""){
       strVidText+="</div>";
     }
     if(strOth!==""){
       strOth+="</div>";
     }

   }
   
   strResp+=strTimeL+strDivText+strOth+strImgText+strVidText;
   strResp+="</div></div>"
  }
  return strResp;
}

export const sortList=(listjs, prop, asc,isNumeric)=>{
  if(isNumeric===true){
    listjs.sort(function (a, b) {
      if (asc) {
        return parseInt(a[prop]) > parseInt(b[prop])
          ? 1
          : parseInt(a[prop]) < parseInt(b[prop])
          ? -1
          : 0
      } else {
        return parseInt(b[prop]) > parseInt(a[prop])
          ? 1
          : parseInt(b[prop]) < parseInt(a[prop])
          ? -1
          : 0
      }
    })
  }else{
    if(asc){
      listjs = listjs.sort((a, b) => {
        if (a[prop] < b[prop]) {
          return -1;
        }
      });
    }else{
      listjs = listjs.sort((a, b) => {
        if (a[prop] > b[prop]) {
          return -1;
        }
      });
    }
   
  }
  
  return listjs;

}

export const getAccessRolesNew=(roles)=>{
  var getMenu="";
  const listFltr = d => (d.display_option === 'menu' || d.display_option === 'top_menu') && d.parent_id == 0;
  var topMenusList = roles.filter(listFltr);
  //alert(topMenusList.length);
  for (var i = 0; i < topMenusList.length; i++) {
      const childFltr = d => d.parent_id === topMenusList[i]["id"] && d.display_option !== 'desk'
      var childMenuList = roles.filter(childFltr);
      var getChMenu = "";
      //alert(childMenuList.length + " = " + topMenusList[i]["id"]);
      if (childMenuList.length === 0) {
          getMenu += " <li class=\"nav-item has-treeview\">" +
              "<a href = \"" + topMenusList[i]["red_link"]+"?rf="+btoa(topMenusList[i]["id"]) + "\" class=\"nav-link\" >"+topMenusList[i]["description"] + topMenusList[i]["name"] + "</a>" +
              "</li>";
      } else {
          getMenu += "<li class=\"nav-item dropdown\">" +
              "<a class=\"nav-link dropdown-toggle\" href = \"#\" id=\"navbarDropdown\" role=\"button\" data-toggle=\"dropdown\" aria haspopup=\"true\" aria-expanded=\"false\">" + topMenusList[i]["col1"] + "</a>"
          getChMenu = "<div class=\"dropdown-menu\" aria-labelledby=\"navbarDropdown\">";
          for (var ch = 0; ch < childMenuList.length; ch++) {
              getChMenu += "<a class=\"dropdown-item\" href=\"" + childMenuList[ch]["col3"]+"?rf="+atob(childMenuList[ch]["id"]) + "\">" + childMenuList[ch]["col1"] + "</a>";
          }
          getChMenu += "</div>";
          getMenu += getChMenu + "</li>";
      }

      // getMenu += topMenusList[i]["col1"];
  }
  return getMenu;
 
}

export const HideModal=(modelId,action="hide")=>{
  if(action==="hide"){
    $('#'+modelId).modal('hide');
  }else if(action==="show"){
    $('#'+modelId).modal('show');
  }
  
}


export const CallMapMethodRef=(obCls,frmValues,refObj)=>{
  var curl_type=frmValues['curl_type'];

    //alert("check url == "+curl_type);
    var divRes="";
    var getCallFunc=""
    if(frmValues['res_name'])
    {
      divRes=frmValues['res_name'];
    }

    if(frmValues['res_func'])
    {
      getCallFunc=frmValues['res_func'];
    }
        var ss=frmValues;
        //var xs = "{objs:" + JSON.stringify(ss) + "}";
       //alert(xs);
         $.ajax({
            crossDomain:true,
                type: "POST",
                url: appUrl+curl_type,
                data:ss,
             success: function (data) {
              //alert(data);   
              //ajaxindicatorstop();
                 // return data;
                 if(divRes!="")
                 {
                     if ($("#" + divRes).length!=0)
                  $("#"+divRes).html(data);
                 }
                 if(getCallFunc!="")
                 {
                  var checkData=$($($.parseHTML(data)).filter("#resP")).text();
                  var checkMsg=$($($.parseHTML(data)).filter("#resPS")).text();
                  //  eval(getCallFunc);
                  //alert(checkData);
                 return obCls[getCallFunc](checkData,checkMsg,refObj);
                    }
                   
                }
            });
}
export const CallMapMethod_CBack=(obCls,frmValues,callback)=>{
  var curl_type=frmValues['curl_type'];

  //alert("check url == "+curl_type);
  var divRes="";
  var getCallFunc=""
  if(frmValues['res_name'])
  {
    divRes=frmValues['res_name'];
  }

  if(frmValues['res_func'])
  {
    getCallFunc=frmValues['res_func'];
  }
      var ss=frmValues;
      var xs = "{'objs':" + JSON.stringify(ss) + "}";
     //alert(xs);
     
     ajaxindicatorstart("Loading Please Wait..");
       $.ajax({
          crossDomain:true,
              type: "POST",
              url: appUrl+curl_type,
              data:xs,
              contentType: "application/json;charset=utf-8",
              dataType: "json",
           success: function (data) {
            ajaxindicatorstop();
            
                //alert(data.d);
               //ajaxindicatorstop();
               // alert(data.d);
               // return data;0
             
               if(divRes!="")
               {
                   if ($("#" + divRes).length!=0)
                $("#"+divRes).html(data);
               }
               if(getCallFunc!="")
               {
                var checkData=$($($.parseHTML(data.d)).filter("#resP")).text();
                var checkMsg=$($($.parseHTML(data.d)).filter("#resPS")).text();
                //  eval(getCallFunc);
                  callback(checkData,checkMsg);
                  }
                 
              }
          });
}

export const CallMapMethod=(obCls,frmValues,loader=false)=>{
   
  var curl_type=frmValues['curl_type'];

  //alert("check url == "+curl_type);
  var divRes="";
  var getCallFunc=""
  if(frmValues['res_name'])
  {
    divRes=frmValues['res_name'];
  }

  if(frmValues['res_func'])
  {
    getCallFunc=frmValues['res_func'];
  }
      var ss=frmValues;
      var xs = "{'objs':" + JSON.stringify(ss) + "}";
    // alert(xs);
    // return;
    if(loader===true){
      ajaxindicatorstart("");
    }
    
       $.ajax({
          crossDomain:true,
              type: "POST",
              url: appUrl+curl_type,
              data:xs,
              contentType: "application/json;charset=utf-8",
              dataType: "json",
           success: function (data) {
            if(loader===true){
              ajaxindicatorstop();
            }
            
                //alert(data.d);
               //ajaxindicatorstop();
               // alert(data.d);
               // return data;0
             
               if(divRes!="")
               {
                   if ($("#" + divRes).length!=0)
                $("#"+divRes).html(data);
               }
               if(getCallFunc!="")
               {
                var checkData=$($($.parseHTML(data.d)).filter("#resP")).html();
                //alert(checkData);
                var checkMsg=$($($.parseHTML(data.d)).filter("#resPS")).html();
                //  eval(getCallFunc);
               return obCls[getCallFunc](checkData,checkMsg);
                  }
                 
              }
          });
}

export const GetImgCertUser_CBack=(obCls,frmNm,curl,callback)=>{
  ajaxindicatorstart("");
  var options={
    url     : appUrl+curl,
    success : function(data)
    {
      ajaxindicatorstop();
       var resData=data.documentElement.childNodes[0].nodeValue;
       
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
       //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
       var userMsg="";  
     return callback(checkData,checkMsg,userMsg);
        
 },
    error: function(data) {
     //alert(data);
     ajaxindicatorstop();
       return callback(0,JSON.stringify(data),"0");
     }
   };

   
var strFrmNm=frmNm.substr(1);
var isValidated=true;
//alert(strFrmNm);
var objElems=document.getElementById(strFrmNm).getElementsByClassName("required");
for(var r=0;r<objElems.length;r++){
  
  if(objElems[r].value==="" || objElems[r].value==="0"){
    //alert(objElems[r].type);
    objElems[r].style.border="2px solid rgb(195 17 17)";
    isValidated=false;
  }
}
if(!isValidated){
 ajaxindicatorstop();
  return callback(-10,"Fill Required Fileds","");
}else{
  
 $(frmNm).ajaxSubmit( options );
}

}

export const GetImgCertUser=(obCls,frmNm,curl,resfun)=>
{

        
   var options={
      url     : appUrl+curl,
      success : function(data)
      {
       // alert(data);
        var resData=data.documentElement.childNodes[0].nodeValue;
       var resData=data;
        var checkData=$($($.parseHTML(resData)).filter("#resP")).text();
       var checkMsg=$($($.parseHTML(resData)).filter("#resPS")).text();
       //var userMsg=$($($.parseHTML(resData)).filter("#reParty")).text();
       var userMsg="";  
       return obCls[resfun](checkData,checkMsg,userMsg);
          
   },
      error: function(data) {
      
         return obCls[resfun](0,data);
       }
     };
  
   $(frmNm).ajaxSubmit( options );
 

}

export const AjaxCallMethod = (obCls,cBack)=>{

  $("._brFileThumb").unbind('click');
  $(".updImgThumb").unbind('change');

  $("._brFileThumb").click(function(){
    var getNm=$(this).attr("setTo");
    document.getElementById(getNm).click();
  });
  $(".updImgThumbMulti").change(function(){
    var currentObj=$(this);
    var refBack=$(this).attr("ref_img");
    var refFlName=this.value.split('\\').pop();

    let checkSize=$(this).attr("size");
    let filesCollect=[];
    //alert();
    //alert(this.files.length);
    if (this.files && this.files[0]) {

     for(let f=0;f<this.files.length;f++){
  //check size
  if (typeof checkSize !== 'undefined' && checkSize !== false) {
    if(this.files[f].size>checkSize){
      return obCls[refBack]("-10","Size larger then validated!",currentObj);
    }
}
  
  //alert("i am read url");
    var reader = new FileReader();
    reader.onload = function (e) {
      //alert("i m in file read");
      //var refBack=$(this).attr("ref_img");
      //alert(refBack);
      //alert(e.target.name);
      //filesCollect.push(e.target.result);
      //obCls[refBack](filesCollect,refFlName,currentObj);
      obCls[refBack](e.target.result,refFlName,currentObj);
      //alert($(input).attr("ref_img"));
       // $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
    }

    reader.readAsDataURL(this.files[f]);
     }
    }
    //alert(filesCollect.length);
      
  });
  $(".updImgThumb").change(function(){
    var currentObj=$(this);
    var refBack=$(this).attr("ref_img");
    let lsControl="";
    if(this.hasAttribute("assignTo")){
      lsControl=$(this).attr("assignTo");
    }
    var refFlName=this.value.split('\\').pop();
    //alert();
    //alert(refBack);
    if (this.files && this.files[0]) {
      //alert("i am read url");
        var reader = new FileReader();
        reader.onload = function (e) {
          //var refBack=$(this).attr("ref_img");
          //alert(refBack);
          //alert(e.target.name);
          return obCls[refBack](e.target.result,refFlName,currentObj,"",lsControl);
          //alert($(input).attr("ref_img"));
           // $('.'+$(input).attr("ref_img")).attr('src', e.target.result);
        }
 
        reader.readAsDataURL(this.files[0]);
    }
  });

  $('._brFile').click(function(){
     var getNm=$(this).attr("setTo");
     
     document.getElementById(getNm).click();
     });
  $(".updImg").change(function(){
     readURL(this);
     //alert("A file has been selected.");
 });
 $(".select_target").change(function () {
  var getCallFunc= $(this).attr("resp_dest");
  var refId=$(this).val();
  return obCls[getCallFunc](refId);
 });

 $('.keyEvent_btn').keyup(function (e) {
  if (e.keyCode == 13) {
      var refEvent = $(this).attr("rf");
      $(refEvent).click();
  }
});
 

   $('._btnSetColor').click(function () {
   	 //var m_type=$("#m_type").val();
    //var curl_type=$("#curl_type").val();
  //$("#modal_login").serialize();
  var ssFrm=$(this).closest('form');
  var m_type= $(ssFrm).find('input[name="m_type"]').val();
  var curl_type=$(ssFrm).find('input[name="m_type"]').val();
  var getCallFunc=$(ssFrm).find('input[name="res_func"]').val();
   var ss = $(this).closest('form').serializeArray();
   
   var xs = "{'objs':" + JSON.stringify(ss) + "}";
   //alert(xs);
  if(!getCallFunc){
getCallFunc="";

   }
  // alert(xs);
  
   ajaxindicatorstart('loading data.. please wait..');
//alert(getCallFunc);
  //var ss=$("#"+fName).serialize();
        $.ajax({
           crossDomain: true,
               type: "POST",
            url: appUrl + curl_type,
            data: xs,
            contentType: "application/json;charset=utf-8",
            dataType: "json",
            success: function (data) {
               //alert(data.d);
                ajaxindicatorstop();
                   //alert("Registration Completed Successfully ");
                   // document.getElementById("frmRegister").reset();
                   //location.reload();
                    if(getCallFunc!="")
                    {
                       var checkData=$($($.parseHTML(data.d)).filter("#resP")).text();
                       var checkMsg=$($($.parseHTML(data.d)).filter("#resPS")).text();
                 //  eval(getCallFunc);
                 //alert(checkData);
                       return obCls[getCallFunc](checkData,checkMsg);
                    }else
                    {
                      //parent.$.colorbox.close();
                   }
                   
                
               },
               error: function (textStatus, errorThrown) {
                alert(textStatus.d+errorThrown);
            }
           });

});
}

export function ajaxindicatorstart(text) {
  if (jQuery('body').find('#resultLoading').attr('id') != 'resultLoading') {
      jQuery('body').append('<div id="resultLoading" style="display:none"><div><img src="../ajax-loader.gif"><div>' + text + '</div></div><div class="bg"></div></div>');
  }

  jQuery('#resultLoading').css({
      'width': '100%',
      'height': '100%',
      'position': 'fixed',
      'z-index': '10000000',
      'top': '0',
      'left': '0',
      'right': '0',
      'bottom': '0',
      'margin': 'auto'
  });

  jQuery('#resultLoading .bg').css({
      'background': '#000000',
      'opacity': '0.7',
      'width': '100%',
      'height': '100%',
      'position': 'fixed',
      'top': '0',
      'margin-left': '0'
  });

  jQuery('#resultLoading>div:first').css({
      'width': '250px',
      'height': '75px',
      'text-align': 'center',
      'position': 'fixed',
      'top': '0',
      'left': '0',
      'right': '0',
      'bottom': '0',
      'margin': 'auto',
      'font-size': '16px',
      'z-index': '99999999999',
      'color': '#ffffff'

  });

  jQuery('#resultLoading .bg').height('100%');
  jQuery('#resultLoading').fadeIn(300);
  jQuery('body').css('cursor', 'wait');
}

export function ajaxindicatorstop() {
  jQuery('#resultLoading .bg').height('100%');
  jQuery('#resultLoading').fadeOut(300);
  jQuery('body').css('cursor', 'default');
}

export default AjaxCallMethod