export function getRandomImagesPath(dirname="vendor_f",limit=15,ext="jpg"){
    let mapData=[];
    for(var i=1;i<=limit;i++){
        mapData.push({"category":"pathlab","path":dirname+"/"+i+"."+ext});
    }
    let indexRet=getRandomInt(limit);

    if(indexRet===0){
        indexRet++;
    }
//alert(indexRet);
return mapData[indexRet]["path"];    
}
function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  
export default getRandomImagesPath();