import $ from 'jquery';
import React from 'react';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Comp_Login from '../component_version2/comp_login';
import Comp_Login_Otp from '../component_version2/comp_login_otp';
import Comp_User_Registration from '../component_version2/comp_user_registration';
import Comp_Verify_Otp from '../component_version2/comp_verify_otp';

let cartItems;
class Footer extends React.Component{
    componentDidMount(){
        cartItems=localStorage.getItem("_cart");
        
        var strList="";
          let finalAmt=0;
        if(cartItems!=null && cartItems!==""){
          cartItems=JSON.parse(cartItems);
          //alert("i am in cart item "+cartItems.length);
          $("._cart_elm_count").html(cartItems.length);
          $("._cart_elm_sun").html("₹"+window.cAmount); 
        }
      }
    render(){
        return(
        <div className="pt-10px">
        <div className="footer-area">
        <div className="footer-container">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        
                        <div className="col-md-6 col-lg-4 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading">Address</h4>
                                <p className="about-text">Aiming for Healthy and a Happy You.<br></br>
                                5,Palda Industrial Area, Near Hanuman Temple <br></br>
                                Indore - 452010<br></br>
                                
                                {/* CIN:U74999MP2018PTC045036 <br></br> */}
                                Email:care@advaiet.com<br></br>
                                Customer care: 89-89-456-456
                                </p>
                                <ul className="link-follow">
                               
                                    <li className="li">
                                        <a className="paypal icon-social-facebook m-0" title="Facebook" href="javascript:void(0)"></a>
                                    </li>
                                    <li className="li">
                                        <a className="tumblr icon-social-instagram" title="Instagram" href="javascript:void(0)"></a>
                                    </li>
                                    <li className="li">
                                        <a className="twitter icon-social-twitter" title="Twitter" href="javascript:void(0)"></a>
                                    </li>
                                    <li className="li">
                                        <a className="pinterest icon-social-youtube" title="Youtube" href="javascript:void(0)"></a>
                                    </li>
                                   
                                </ul>
                            </div>
                        </div>
                       
                        <div className="col-md-6 col-sm-6 col-lg-3 mb-md-30px mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading">SERVICES</h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                        <li className="li"><a className="single-link" href="../tests">MEDICINES</a></li>
                                            <li className="li"><a className="single-link" href="../advaiet_nephro_care">DIALYSIS</a></li>
                                            <li className="li"><a className="single-link" href="../vendors/mri">MRI SCAN</a></li>
                                            <li className="li"><a className="single-link" href="../vendors/dexa">DEXA SCAN</a></li>
                                            <li className="li"><a className="single-link" href="../vendors/ECG">ECG</a></li>
                                            <li className="li"><a className="single-link" href="../vendors/EEG">EEG</a></li>
                                            <li className="li"><a className="single-link" href="../vendors/TMT">TMT</a></li>
                                            <li className="li"><a className="single-link" href="../vendors/pathology_laboratory">PATHOLOGY/LABORATORY</a></li>
                                            <li className="li"><a className="single-link" href="../vendors/diagnostic_centers">DIAGNOSTIC CENTERS</a></li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-lg-2 col-sm-6 mb-lm-30px">
                            <div className="single-wedge">
                                <h4 className="footer-herading">MY ACCOUNT</h4>
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                           <li className="li"><a className="single-link" href="blogs">BLOG</a></li>
                                            <li className="li"><a className="single-link" href="faqs">FAQ'S</a></li>
                                            <li className="li"><a className="single-link" href="reviews">REVIEWS</a></li>
                                            <li className="li"><a className="single-link" href="products">PRODUCTS</a></li>
                                            <li className="li"><a className="single-link" href="library">Library</a></li>
                                            <li className="li"><a className="single-link" href="contactus">CONTACT US</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                       
                        <div className="col-md-6 col-lg-3">
                            <div className="single-wedge">
                                <h4 className="footer-herading">FOLLOW US</h4>
                                <div className="footer-links">
                                <div className="footer-links">
                                    <div className="footer-row">
                                        <ul className="align-items-center">
                                        <li className="li"><a className="single-link" href="shipping_policy">Delivery Information</a></li>
                                            <li className="li"><a className="single-link" href="privacy_policy">Privacy & Policy</a></li>
                                            <li className="li"><a className="single-link" href="terms_condition">Terms & Condition</a></li>
                                            <li className="li"><a className="single-link" href="return_refund_cancellation_policy">Return,Refund & Cancellation Policy</a></li>
                                        </ul>
                                    </div>
                                </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-12" style={{paddingTop:"50px",fontSize:"13px"}}>
                        DISCLAIMER: This Site Is Not Intended To Provide Diagnosis, Treatment Or Medical Advice. Products, Services, Information And Other Content Provided On This Site, Including Information That May Be Provided On This Site Directly Or By Linking To Third-Party Websites Are Provided For Informational Purposes Only. Please Consult With A Physician Or Other Healthcare Professional Regarding Any Medical Or Health Related Diagnosis Or Treatment Options. The Results From The Products May Vary From Person To Person. Images shown here are for representation only, actual product may differ.
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row flex-sm-row-reverse">
                        <div className="col-md-6 text-right">
                            <div className="payment-link">
                            <p className="copy-text">Copywrite</p>
                                {/* <img src="assets/images/icons/payment.png" alt="" /> */}
                            </div>
                        </div>
                        <div className="col-md-6 text-left">
                            <p className="copy-text">Advaiet Healthcare Services is a company of Advaiet Diagnostic Private Limited.</p>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
        </div>
        <Cart_Canvas/>
        <Comp_Login />
        <Comp_Login_Otp />
        <Comp_User_Registration />
        <Comp_Verify_Otp/>
        </div>);
    }
}
export default Footer;