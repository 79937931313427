import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import {CallMapMethod_CBack,RespBkMthdMapp,AjaxCallMethod,listClock} from '../function_lib/common_lib';
import {StateMaster} from '../function_lib/city_india';
import User_Header from './user_header';
import User_Footer from './user_footer';
import Mobile_Widgets from '../sample_comp/mobile_widgets';
import Comp_Emp_Dashboard from '../user_components/comp_emp_dashboard';
import Comp_User_Dashboard from '../user_components/comp_user_dashboard';
import Comp_Vendor_Dashboard from '../user_components/comp_vendor_dashboard';
import Comp_Admin_Dashboard from '../user_components/comp_admin_dashboard';
import Comp_End_User_Dashboard from '../user_components/comp_end_user_dashboard';

import Comp_Upload_Basic from '../user_components/comp_upload_basic';
import Comp_Bank_Details from '../user_components/comp_bank_details';
import Comp_Working_Hours from '../user_components/comp_working_hours';
import Comp_Entity_Docs from '../user_components/comp_entity_docs';
import Comp_Vendor_Package from '../user_components/comp_vendor_package';

import Comp_View_Money_Offer from '../user_components/comp_view_money_offer';
import pdfIcon from '../assets/images/pdf_icon.png';
import './common.css';

let logInObj;
let stateList;
let clsDashboard;
let lsClock=[];


let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];
class Dashboard_New extends React.Component{
  
forceUpdateHandler(){
  this.forceUpdate();
};

    constructor(props){
        super(props);
        if(localStorage.getItem("userDS")!=null){
            //logInObj=JSON.parse(localStorage.getItem("userDS"));
            logInObj=getUserObjData();
          }
          clsDashboard=this;
          this.state={stAdvMoney:[],stOfferCode:[],
            stMoneyList:[],stOfferList:[],stOfferViewKey:"",stViewOffMoneyList:[]}

          stateList=StateMaster();
          lsClock=listClock(15);
    }
// set offer view component event
setOfferViewKey(setView){
  let viewOffMonList=[];
  if(setView==="offer"){
    viewOffMonList=clsDashboard.state.stOfferList;
  }else if(setView==="money"){
    viewOffMonList=clsDashboard.state.stMoneyList;
  }
  clsDashboard.setState({stOfferViewKey:setView,stViewOffMoneyList:viewOffMonList});
}


    /* entity documents upload file */

    /* end of entity documents*/

    // related upload file logic
    ImageThumbBack(flImg, flName,fObject,othInfo="",lsControl="") {
      //alert(lsControl);
        let destControl="#lsUpdFiles";
        if(lsControl!==""){
          destControl="#"+lsControl;
        }
        if(othInfo===""){
          othInfo="nm"+indexExisting+","+indexExisting;
        }
        indexExisting++;
        var arrNmValue=othInfo.split(',');
        //arrExistingFiles.push
        arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
        var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
        "<i class=\"icon-close _removeBtn"+indexExisting+"\"  param2=\""+lsControl+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
          "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
          "</div>";
          if(flImg.indexOf("data:application/pdf")!=-1 || flImg.indexOf("data:application/pdf")!=-1){
           strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
            "<i class=\"icon-close _removeBtn"+indexExisting+"\" param2=\""+lsControl+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
              "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\""+pdfIcon+"\" /></a>" +
              "</div>";
          }
          
        //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
        objImages.push({ name: arrNmValue[1], value: flImg });
        $(destControl).val("{\"objs\":" + JSON.stringify(objImages) + "}");
        //alert(strSet);            
        $(".imgLibThumb").append(strSet);
        if(fObject!==null){
          fObject.val('');
        }
      //   $('.gallery-item').magnificPopup({
      //     type: 'image',
      //     gallery: {
      //         enabled: true
      //     }
      // });
      
      RespBkMthdMapp(clsDashboard, "_removeBtn"+indexExisting, "rmFileProc", "2");
        //imgLibThumb
            }
            rmFileProc(data,lsControl=""){
              if(document.getElementById("ref_div_"+data)!=null){
                document.getElementById("ref_div_"+data).remove();
              }
              
              this.lcRemoveJSON(data,"image",lsControl);
          }
          lcRemoveJSON(refKey,fType,lsControl){
            let destControl="#lsUpdFiles";
            if(lsControl!==""){
              destControl="#"+lsControl;
            }
            var jsonP=JSON.parse($(destControl).val());
            var arrJson=jsonP.objs;
            for(var ai=0;ai<arrJson.length;ai++){
              if(arrJson[ai]["name"]===refKey){
                arrJson.splice(ai,1);
                $(destControl).val(JSON.stringify(jsonP));
                break;
              }
            }
            objImages=arrJson;
          }
      //end of upload file logic    

    componentDidMount(){
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
      
        document.body.appendChild(script);

        if(logInObj[0]["user_type"]==="_user"){
          this.getEndUserAdvMoney(logInObj[0]["id"]);
        }
        
        AjaxCallMethod(this,"okSuccCNOT");
    }
    
getEndUserAdvMoney(app_token){
  let map=[];
  map.push({name:"curl_type",value:"getUserAdvMoney"});
  map.push({name:"res_func",value:"callBackNg"});
  map.push({name:"app_token",value:app_token});
 
  map["res_func"]="getUserAdvMoney";
  map["curl_type"]="getUserAdvMoney";
  CallMapMethod_CBack(this,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let lsData=JSON.parse(data);
    
       
  clsDashboard.setState({stAdvMoney:lsData.total_money,stMoneyList:lsData.list_money},function(){
      clsDashboard.forceUpdateHandler();
    });
      
     
    }
    
  })
}
    render(){
        const adminOption=()=>{
          if(typeof logInObj[0]["loc"]!=="undefined"){
            if(logInObj[0]["loc"]===logInObj[0]["id"]){
              return <Comp_Admin_Dashboard/>
            }
          }else if(logInObj[0]["user_type"]==="_ash_adv" && logInObj[0]["app_user_id"]===logInObj[0]["id"]){
            return  <Comp_Vendor_Dashboard />
          }else if(logInObj[0]["user_type"]==="_ash_adv"){
            return <Comp_User_Dashboard/>
          }else{
            return <Comp_End_User_Dashboard setOnClkView={this.setOfferViewKey} adv_money={this.state.stAdvMoney} offer_money={this.state.stOfferCode} />
          }
          
         
         }
        return(<div>
            <div id="tmpSt"></div>
               <User_Header />
             
<div className="container">
{adminOption()}
</div>

<Comp_Upload_Basic userObject={logInObj} stateList={stateList}/>
<Comp_Bank_Details userObject={logInObj} />
<Comp_Working_Hours userObject={logInObj} listClocks={lsClock} />
<Comp_Vendor_Package userObject={logInObj} listClocks={lsClock}/>
<Comp_Entity_Docs userObject={logInObj}/>
<Comp_View_Money_Offer userObject={logInObj} viewOffMoneyList={this.state.stViewOffMoneyList} setKeyType={this.state.stOfferViewKey} />
               {/* <Mobile_Widgets/> */}
               <User_Footer/>
        </div>)
    }
}
export default Dashboard_New; 