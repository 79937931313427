import React from 'react';
export const Order_List_Element=(listItems)=>{
  
   //var listVar=JSON.parse("{\"objs\":"+listItems+"}");
   var listVal=JSON.parse(listItems);
  var strData="";
  for(var i=0;i<listVal.length;i++){
    strData+="<div class=\"row g-3\"><div class=\"col-12 col-md-6\">"+
    "<div class=\"card weekly-product-card\">"+
      "<div class=\"card-body d-flex align-items-center\">"+
        "<div class=\"product-description\"><a class=\"product-title d-block\" href=\"single-product.html\">No:"+listVal[i]["Invoice No"]+"</a>"+
          "<p>"+listVal[i]["date"]+"</p>"+
          "<p class=\"sale-price\"><i class=\"lni lni-rupee\"></i>Rs."+listVal[i]["Net Amount"]+"("+listVal[i]["Payment Status"]+")"+"</p>"+
          "<p class=\"sale-price\">"+listVal[i]["collection_status"]+"</p>"+
        "</div>"+"<div class=\"product-description\"><a class=\"product-title d-block\" href=\"single-product.html\">"+listVal[i]["Company Name"]+"</a>"+
        "<p>Gender:"+listVal[i]["Gender"]+"</p>"+
        "<p>Contact No:"+listVal[i]["contact_no"]+"</p>"+
        "<a class=\"btn btn-success btn-sm add2cart-notify\" href=\"\\viewResult?rf="+listVal[i]["invoice_id"]+"\"><i class=\"lni lni-arrow-right\"></i>View Result</a>"+
        "</div>"+
        
        "</div></div></div></div><br>";
  }
  return strData;
}

export const Order_Items=(itemList)=>{
  var listVal=JSON.parse(itemList);
  var preFixData="<div class=\"cart-wrapper-area py-3\"><div class=\"cart-table card mb-3\">"+
  "<div class=\"table-responsive card-body\">"+
    "<table class=\"table mb-0\"><tdbody>";
    var suffixData="</div></div></div></tdbody></table>";
  var strData="";
  for(var i=0;i<listVal.length;i++){
    strData+="<tr>"+"<td>"+listVal[i]["Item Category"]+"</td>"+
                   "<td>"+listVal[i]["Item"]+"</td>";
                   //"<td>"+listVal[i]["TR_unit"]+"</td>"+
                   //"<td>"+listVal[i]["col1"]+"</td>"+"</tr>";
    //strData+="<tr>"+listVal[i]["result_name"]+":"+listVal[i]["col2"]+"-"+listVal[i]["TR_unit"]+"</tr>";
  }
  strData=preFixData+strData+suffixData;
  return strData;
}

export const Result_Element=(resultItems)=>{
  var listVal=JSON.parse(resultItems);
  var preFixData="<div class=\"cart-wrapper-area py-3\"><div class=\"cart-table card mb-3\">"+
  "<div class=\"table-responsive card-body\">"+
    "<table class=\"table mb-0\"><tdbody>";
    var suffixData="</div></div></div></tdbody></table>";
  var strData="";
  for(var i=0;i<listVal.length;i++){
    strData+="<tr>"+"<td>"+listVal[i]["result_name"]+"</td>"+
                   "<td>"+listVal[i]["col2"]+"</td>"+
                   "<td>"+listVal[i]["TR_unit"]+"</td>"+
                   "<td>"+listVal[i]["col1"]+"</td>"+"</tr>";
    //strData+="<tr>"+listVal[i]["result_name"]+":"+listVal[i]["col2"]+"-"+listVal[i]["TR_unit"]+"</tr>";
  }
  strData=preFixData+strData+suffixData;
  return strData;
}

export default Order_List_Element