export function getUserObjData(){
  var cObject="";
  if(localStorage.getItem("userDS")!=null){
    var tmpObject=JSON.parse("{\"access\":"+localStorage.getItem("userDS")+"}");
    cObject=tmpObject.access['user'];
  }
  return cObject;
}
var UserProfile = (function() {
    let userObject="";
    let accessRoles="";  
    var getUserObject = function() {
      var cObject=userObject;
      if(cObject!=""){
       var tmpObject=JSON.parse("{\"access\":"+userObject+"}");
        cObject=tmpObject.access['user'];
        //alert(cObject.length);
      }
      return cObject;    // Or pull this from cookie/localStorage
    };

    var getAccessRoles=function(){
      var cObject=userObject;
      if(cObject!=""){
       var tmpObject=JSON.parse("{\"access\":"+userObject+"}");
        cObject=tmpObject.access['role'];
        //alert(cObject.length);
      }
      return cObject;    // Or pull this from cookie/localStorage
    };
  
    var setUserObject = function(userObj) {
        userObject = userObj;     
      // Also set this in cookie/localStorage
    };
  
    return {
        getUserObject: getUserObject,
        setUserObject: setUserObject,
        getAccessRoles:getAccessRoles
       
    }
  
  })();
  
  export default UserProfile;