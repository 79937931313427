import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import User_Header from './user_header';
import User_Footer from './user_footer';
import Mobile_Widgets from '../sample_comp/mobile_widgets';
import {getUserObjData} from '../function_lib/userToken';

import {Order_Data_Horizontal} from '../sample_comp/product_listing';
import {CallMapMethod,SetResponseDiv,AjaxCallMethod,TableDatePicker} from '../function_lib/common_lib';
import Reports_Search_Filter from '../component_version2/reports_search_filter';

let filtStatus;
let queries;
let getUser;
let clsOrderInvenstigation;
// parameters for filters
let searFltStatus=[{value:"0",display:"-All-"},
{value:"Pending Assign",display:"Pending Assign"},
{value:"Pending Collection",display:"Pending Collection"},
{value:"Pending Accession",display:"Pending Accession"},
{value:"Pending Complete",display:"Pending Complete"},
{value:"Pending Verification",display:"Pending Verification"},
{value:"Pending Approval",display:"Pending Approval"},
{value:"Pending Print",display:"Pending Print"},
{value:"Printed",display:"Printed"},
];
let isFromDate=true;
let isToDate=true;
// end of parameters for Filters
class Order_Investigation extends React.Component{
    constructor(props){
        super(props);
        queries = QueryString.parse(this.props.location.search);
        filtStatus="order";
        if(queries.rf==="collection")
            {
              filtStatus="collection";
            }
            getUser=getUserObjData();
            clsOrderInvenstigation=this;
      }
    getOrderInvst(){
    //alert("testing i m in click");
        //get Values from SearchParam Object
        var fromDt=$("#from_dt").val();
        var toDt=$("#to_dt").val();
     
       
       //console.log(this.props.location.search);
       //queryString.parse(location.search);
       var map=[];
       //alert(this.props);
       if(typeof clsOrderInvenstigation.props.location!=="undefined"){
         queries = QueryString.parse(clsOrderInvenstigation.props.location.search);
       }
       
       //console.log(queries.rf); 
       let filtStatus=$("#filter_status").val();
       //filtStatus="0";
     //   if(queries.rf==="collection")
     //   {
     //     filtStatus="collection";
     //     map.push({name:"collection_status",value:"Pending Assign"});
     //   }else{
     //     map.push({name:"collection_status",value:"Pending Accession"});
     //   }
       map.push({name:"curl_type",value:"getOrders"});
       map.push({name:"res_func",value:"callBackNg"});
       map.push({name:"from_dt",value:fromDt});
       map.push({name:"to_dt",value:toDt});
       map.push({name:"collection_status",value:filtStatus});
       map["res_func"]="callBackNg";
       map["curl_type"]="getOrders";
       if(getUser!==""){
           //alert("testing this is me");
           map.push({name:"app_token",value:getUser[0]["app_user_id"]});
           CallMapMethod(clsOrderInvenstigation,map,true);
       }
    }   
    componentDidMount(){
         this.getOrderInvst();
         AjaxCallMethod(this,"callBackNg");

         const script = document.createElement("script");
         script.src = "/main.js";
         script.async = true;
         //script.onload = () => clsObject.scriptLoaded();
       
         document.body.appendChild(script);

         document.body.appendChild(script);
         const script2 = document.createElement("script");
         script2.src = "/specific_listing.js";
         script2.async = true;
         document.body.appendChild(script2);
    }
    callBackNg(data,msg){
         // alert(data+msg);
        //console.log(data);
        if(data.trim()!=="0"){
           // divOrder
         var checkResp= Order_Data_Horizontal(data);
         $("#divOrder").html(checkResp);
         //alert(checkResp);
         //SetResponseDiv("ordItems",checkResp);
        }else{
         $("#divOrder").html("<center>No records found</center>")
        }
      
       // $("#checkResp").html(msg);
     }
    render(){
        return(<div>

               <User_Header />
          <div id="checkResp"></div>     
<div className="container">

<div className="row mt-5">
<Reports_Search_Filter 
statusList={searFltStatus} 
filters={[]}
isFromDate={isFromDate} 
isToDate={isToDate}
onSearchClick={this.getOrderInvst} 

/>
<div className="col-lg-8 col-mb-8">
<div id="divOrder">

</div>
</div>
  </div>                

</div>
    
               {/* <Mobile_Widgets/> */}
               <User_Footer/>
        </div>)
    }
}
export default Order_Investigation; 