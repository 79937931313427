import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import User_Header from './user_header';
import User_Footer from './user_footer';
//import Mobile_Widgets from '../sample_comp/mobile_widgets';
import {StateMaster} from '../function_lib/city_india';
import {CallMapMethod_CBack,RespBkMthdMapp,AjaxCallMethod,
  listClock,btnSetColor_CBack,appGetUrlPath} from '../function_lib/common_lib';
import QueryString from 'query-string';

import Comp_Upload_Basic from '../user_components/comp_upload_basic';
import Comp_Bank_Details from '../user_components/comp_bank_details';
import Comp_Working_Hours from '../user_components/comp_working_hours';
import Comp_Entity_Docs from '../user_components/comp_entity_docs';
import Comp_View_Sign_Doc from '../user_components/comp_view_sign_doc';
import pdfIcon from '../assets/images/pdf_icon.png';
import updFile from '../assets/images/upload.png';

import './common.css';

let clsCreatePackage;
let logInObj;
let stateList;
let lsClock=[];


let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];

let rf="0";
let queries;
let appUrlPath="";
class Create_Package extends React.Component{
    constructor(props){
      super(props);
      if(localStorage.getItem("userDS")!=null){
        //logInObj=JSON.parse(localStorage.getItem("userDS"));
        logInObj=getUserObjData();
      }
      clsCreatePackage=this;
      stateList=StateMaster();
      lsClock=listClock(15);
      queries = QueryString.parse(this.props.location.search);
      if(queries.rf!=null){
        rf=queries.rf;
      }
      appUrlPath=appGetUrlPath();
      //default data load required to getDefaultLoadPackage;
     
    }
    getDefaultOnLoadData(){
      let map=[];
      map.push({name:"curl_type",value:"getDefaultLoadPackage"});
      map.push({name:"res_func",value:"callBackNg"});
      map.push({name:"vendor_id",value:rf});
      map["res_func"]="callBackNg";
      map["curl_type"]="getDefaultLoadPackage";
      CallMapMethod_CBack(clsCreatePackage,map,function(data,msg){
        //alert(data);
        if(data!=="0" && data!==0){
          let jsonCollect=JSON.parse(data);
          if(jsonCollect.party_info.length>0){
            $("#tmp_company_name").val(jsonCollect.party_info[0]["company_name"]);
            $("#tmp_location").val(jsonCollect.party_info[0]["address"]);

            let partyCategory=0;
            if(jsonCollect.party_categories.length>0){
              partyCategory=jsonCollect.party_categories[0]["ref1_id"];
            }
            let strListCate="<option value=\"0\">Select Business</option>";
            for(let i=0;i<jsonCollect.default_categories.length;i++){
              strListCate+="<option value=\""+jsonCollect.default_categories[i]["id"]+"\">"+jsonCollect.default_categories[i]["category_name"]+"</option>";
             }
             $("#category_list").html(strListCate);
             $("#category_list").val(partyCategory);
          }
          if(jsonCollect.sign_entry.length>0){
            $("#col1").val(jsonCollect.sign_entry[0]["col1"]);
            $("#pkg_current_st").text(jsonCollect.sign_entry[0]["status"]);
            if(jsonCollect.sign_entry[0]["col5"].trim()!==""){
              $("#copy_sign_msg").html("<a target=\"blank\" href=\""+appUrlPath+"\\sign_doc\\"+jsonCollect.sign_entry[0]["col5"]+"\">Check uploaded Document </a>")
            }
          }

          
          
        }
        
      })

    }
      /* entity documents upload file */
     submitPackage(frmName){
 
        btnSetColor_CBack(this,frmName,"addUpdVendorPackage",function(data,datares){
          alert(datares);
          if(data===-10){
            alert("Kindly fill required fields");
          }else if(data==="001"){
            alert("Assigned Successfully");
          }else{
            //Not accepted from server
          }
        });
      }
    /* end of entity documents*/

    // related upload file logic
    ImageThumbBack(flImg, flName,fObject,othInfo="",lsControl="") {
      //alert(lsControl);
        let destControl="#lsUpdFiles";
        if(lsControl!==""){
          destControl="#"+lsControl;
        }
        if(othInfo===""){
          othInfo="nm"+indexExisting+","+indexExisting;
        }
        indexExisting++;
        var arrNmValue=othInfo.split(',');
        //arrExistingFiles.push
        arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
        var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
        "<i class=\"icon-close _removeBtn"+indexExisting+"\"  param2=\""+lsControl+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
          "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
          "</div>";
          if(flImg.indexOf("data:application/pdf")!=-1 || flImg.indexOf("data:application/pdf")!=-1){
           strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
            "<i class=\"icon-close _removeBtn"+indexExisting+"\" param2=\""+lsControl+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
              "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\""+pdfIcon+"\" /></a>" +
              "</div>";
          }
          
        //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
        objImages.push({ name: arrNmValue[1], value: flImg });
        $(destControl).val("{\"objs\":" + JSON.stringify(objImages) + "}");
        //alert(strSet);            
        $(".imgLibThumb").append(strSet);
        if(fObject!==null){
          fObject.val('');
        }
      //   $('.gallery-item').magnificPopup({
      //     type: 'image',
      //     gallery: {
      //         enabled: true
      //     }
      // });
      
      RespBkMthdMapp(clsCreatePackage, "_removeBtn"+indexExisting, "rmFileProc", "2");
        //imgLibThumb
            }
            rmFileProc(data,lsControl=""){
              if(document.getElementById("ref_div_"+data)!=null){
                document.getElementById("ref_div_"+data).remove();
              }
              
              this.lcRemoveJSON(data,"image",lsControl);
          }
          lcRemoveJSON(refKey,fType,lsControl){
            let destControl="#lsUpdFiles";
            if(lsControl!==""){
              destControl="#"+lsControl;
            }
            var jsonP=JSON.parse($(destControl).val());
            var arrJson=jsonP.objs;
            for(var ai=0;ai<arrJson.length;ai++){
              if(arrJson[ai]["name"]===refKey){
                arrJson.splice(ai,1);
                $(destControl).val(JSON.stringify(jsonP));
                break;
              }
            }
            objImages=arrJson;
          }
      //end of upload file logic    
    componentDidMount(){
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
        document.body.appendChild(script);

        AjaxCallMethod(this,"okSuccCNOT");
        this.getDefaultOnLoadData();
    }
    render(){
        return(<div>
               <User_Header />
              
{/* card process to */}
<div className="container">
<div class="card p-10">
              <div class="card-header">
                <h3 class="card-title">Merchant Package</h3>
                <p>Current Status:<span className="red" id="pkg_current_st"></span></p>
                <div class="card-tools">
                  {/* <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="btn btn-tool" data-card-widget="remove">
                    <i class="fas fa-times"></i>
                  </button> */}
                </div>
              </div>
              
              <div class="card-body p-0">
              <form id="frmVendorPackage" name="frmVendorPackage" className='formarea-sm' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="lsUpdFiles" id="lsUpdFiles" />
        <input type="hidden" name="curl_type" id="curl_type" value={"addUpdVendorPackage"}/>
        <input type="hidden" name="m_type" id="m_type" value={"addUpdVendorPackage"}/>
        <input type="hidden" name="res_func" id="res_func" value={"rescback"}/>
        
        <input type="hidden" name="app_token" id="app_token" value={logInObj[0].app_user_id} />
        <input type="hidden" name="vendor_id" id="vendor_id" value={rf}/>
        <input type="hidden" name="hi_docs" id="hi_docs"/>
        <div className="row">
        <div className="col-md-6">
            <div className="form-group">
              <label>Business Name*</label>
                <input type="text" id="tmp_company_name" name="tmp_company_name" readonly={"true"} className="form-control" placeholder="Company Name" />
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
              <label>Active Location*</label>
                <input type="text" id="tmp_location" name="tmp_location"  readonly={"true"} className="form-control" placeholder="Location" />
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
              <label>Business</label>
              <select className="form-control" id="category_list"  disabled={"true"} name="category_list">
                  <option>-Select Business-</option>
              </select>
                
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
              <label>% of Deduction</label>
                <input readonly type="text" id="col1" name="col1" className="form-control required"/>
            </div>
         </div>
         
         <div className="col-md-12">
            <div className="form-group">
            <label className="col-sm-2">Check Fine Prints*</label><br></br>
              <label class="checkbox-inline"><input type="checkbox" value="Valid only for option purchased" class="finePrint" name="col11" />Valid only for successfully service completed.</label><br></br>
              <label class="checkbox-inline"><input type="checkbox" value="All goods or services must be used by the same person" class="finePrint" name="col11" />All goods or services must be used by the same company/person</label><br></br>
              <label class="checkbox-inline"><input type="checkbox" value="Merchant's standard cancellation policy applies (any fees not to exceed ADVAIET price)" class="finePrint" name="col11" />Merchant's standard cancellation policy applies (any fees not to exceed ADVAIET price)</label><br></br>
            </div>
         </div>
         
         
         <div className="col-md-12">
            <div className="form-group">
            <label className="col-sm-2">Sign Agreement*</label><br></br>
            <p>I am a duly authorized representative of Merchant and my signature binds Merchant to this <a href="#" data-toggle="modal" data-target="#myModal">Agreement</a> including the <a href="<?php echo $appUrl ?>merchantTerm" target="_blank" > Merchant Terms and Conditions</a></p>
            </div>
         </div>
         <div className="col-md-12">
            <div className="form-group">
            {/* <label className="col-sm-2">Sign Agreement*</label><br></br> */}
            <p>*<a href="#" data-toggle="modal" data-target="#divViewSign">Preview Print Agreement</a></p>
            </div>
         </div>
        


         
         <div className="row audioLibThumb imgLibThumb"></div>
         <div className="col-md-3">
               {/* upload file 1 */}
               <div className="card-upd  card-info">
               <p className="red" id="copy_sign_msg"></p>
               {/* <img id="existingImg" /> */}
                      
                      </div>
              
</div>
{/* end of file upload */}
           </div>

               
        </form>
        <button type="button" onClick={()=>this.submitPackage("frmVendorPackage")} class="btn btn-primary">Submit Package</button>     
              </div>
              
             
            </div>
{/* end of card process */}
</div>



<Comp_View_Sign_Doc userObject={logInObj} vendorId={rf} stateList={stateList}/>
               <User_Footer/>
        </div>)
    }
}
export default Create_Package; 