import  $ from 'jquery';
import React,{useEffect } from 'react';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import ImgLogin from '../assets/images/login_img.png';
import {btnSetColor_CBack,CallMapMethod_CBack,HideModal} from '../function_lib/common_lib'; 
import './comp_login.css';

export function setRegistration(frmName){
    //alert("i m in click");
    // HideModal("compUserRegModal","hide");
    // HideModal("compOtpVerifyModal","show");
    // return;
    let user_captcha = document.getElementById('frmUserRegModal_user_captcha').value;
    if (validateCaptcha(user_captcha)===true) {
    
    btnSetColor_CBack(this,frmName,"getRegisterP",function(data,datares){
     //alert(data+datares);
      if(data===-10){
        //alert(datares);
        return;
      }
      if(data==="not_validated"){
        $("#frmUserRegModal_getRegLogn").html(datares);
        return;
      }
      if(data!=="0" && data!==""){
        
        $("#frmOtpVerifyModal_refotp_id").val(data);
        //$("#frmOtpVerifyModal_opt_resp_num").val(data);
        $(".frmOtpVerifyModal_opt_resp_num").html(datares);
        //HideModal("compLoginOtpModal");
        HideModal("compUserRegModal","hide");
        HideModal("compOtpVerifyModal","show");
        //clsObject.setClickFor("nav_otp");
        //let useOTP=JSON.parse(data);
        //$("#refotp_id").val(data);
        //$("#refotp_id").val(useOTP[0]["id"]);
        //$("#refotp_contact_id").val(useOTP[0]["id"]);
        //$(".opt_resp_num").html(datares);
      //  $("#getRegResp").html("Login Successfully..");
      //  localStorage.setItem('userDS',data);
      //  window.location.href="/home";
      }else{
        //clsObject.setClickFor("nav_otp");
        $("#frmUserRegModal_getRegLogn").html(datares);
      }
    })
  }else{
    $("#frmUserRegModal_getRegLogn").html("Invalid Capatch !");
  }
}
export const Comp_User_Registration=(props)=>{
    useEffect(() => {
        loadCaptchaEnginge(4,'white','black','lower');
      }, []);
    
return(<div className="modal fade" id="compUserRegModal" tabindex="-1" role="dialog">
    
<div className="modal-dialog modal-dialog-sm" role="document">
    <div className="modal-content">
        <div className="modal-header">
        
            <button type="button" className="close" data-bs-dismiss="modal" data-dismiss="modal" aria-label="Close"><span
                    aria-hidden="true">x</span></button>
        </div>
        <div className="modal-body container">
            <p>
        <h5 className="white1 center">Welcome to Advaiet Health Services</h5>
        </p>
            <div className="row">
                
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="product-details-content quickview-content">
                        <h2>Register as New User</h2>
                        
                <form id="frmUserRegModal" name="frmUserRegModal" className="form-control11">
                    <div>
                    <input type="hidden" name="curl_type" id="curl_type" value="getRegisterP" />  
            <input type="hidden" name="m_type" id="m_type" value="getRegisterP" />
            <input type="hidden" name="res_func" id="res_func" value="okSuccLogin" />
                    </div>
                    <div className="row">

                     <div className="col-lg-6 col-md-6 mb-2 pl-2 pr-2">
                        <div className="form-group">
                            <input className="form-control form-control-sm no-input-border-sm required" autocomplete="off" type="text" id="person_name" name="person_name" placeholder="Person Name *" />
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6 mb-2 pl-2 pr-2">
                        <div className="form-group">
                            <input className="form-control form-control-sm no-input-border-sm required" autocomplete="off" type="text" id="contact_no" name="contact_no" placeholder="Contact No *" />
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6 mb-2 pl-2 pr-2">
                        <div className="form-group">
                            <input className="form-control form-control-sm no-input-border-sm required" autocomplete="off" type="text" id="email" name="email" placeholder="Email *" />
                        </div>
                     </div>
                     {/* <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                        <div className="form-group">
                            <select className="form-control form-control-sm no-input-border-sm required" id="frmUserRegModal_gender">
                                <option value={"0"}>-Gender-</option>
                                <option value={"Male"}>-Male-</option>
                                <option value={"Female"}>-Female-</option>
                            </select>
                        </div>
                     </div> */}
                    
                     <div className="col-lg-6 col-md-6 mb-2 pl-2 pr-2">
                        <div className="form-group">
                            <input className="form-control form-control-sm no-input-border-sm required" autocomplete="off" type="password" id="password" name="password" placeholder="password" />
                        </div>
                     </div>
                     <div className="col-lg-6 col-md-6 mb-2 pl-2 pr-2">
                        <div className="form-group">
                            <input className="form-control form-control-sm no-input-border-sm required" autocomplete="off" type="password" id="re-password" name="re-password" placeholder="Re-enter password" />
                        </div>
                     </div>
                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                        <div className="form-group">
                        <LoadCanvasTemplate />
                          <input id="frmUserRegModal_user_captcha" name="frmUserRegModal_user_captcha" type="text" class="form-control form-control-sm no-input-border-sm required" />
                            {/* <input className="" autocomplete="off" type="password" id="frmUserRegModal_re-password" name="frmUserRegModal_re-password" placeholder="Re-enter password" /> */}
                        </div>
                     </div>
                    
                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2">
                     <div id="frmUserRegModal_getRegLogn" style={{color:"red"}}></div>
                        <div className="form-group">
                            <button type="button" onClick={()=>setRegistration("frmUserRegModal")} className="btn text-center btn-primary btn-hover-primary ml-4 float-right">Sign Up11</button>
                        </div>
                     </div>

                     <div className="col-lg-12 col-md-12 mb-2 pl-2 pr-2 d-flex">
                     <p className="copy-text col"><a className='linkclass' onClick={()=>HideModal("compUserRegModal")} data-toggle="modal" data-target="#compLoginOtpModal" href="javascript:void(0)">Login with OTP</a></p>
                     <p className="copy-text col float-right"><a className='linkclass' onClick={()=>HideModal("compUserRegModal")} data-toggle="modal" data-target="#compLoginModal" href="javascript:void(0)">Login with Contact No. and Password</a></p>
                     </div>
                        

                    </div>
                     </form>
                        
                        
                 </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>)
}
export default Comp_User_Registration;