import React from 'react';

export const Comp_End_User_Dashboard=(props)=>{
return ( <div className="row mt-4">
{/* invoice order view */}

     {/* end of invoice order view */}


{/* recent orders */}
<div className="col-md-4">
       <div className="card card-widget widget-user-2">
         <div className="widget-user-header card-primary">
           <div className="widget-user-image">
           
            <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
             
            
           </div>
           <h3 className="widget-user-username"></h3>
           <h5 className="widget-user-desc">{"My Account"}</h5>
         </div>
         <div className="card-footer p-0">
           <ul className="nav flex-column">
             <li className="nav-item">
             <a className="nav-link" href="../my_orderlist">Recent Orders
             <span className="float-right badge bg-primary">{"pending"}</span>
             </a>
             </li>
             <li className="nav-item">
             <a className="nav-link" href="../my_orderlist">Order History <span className="float-right badge bg-danger">{"pending"}</span></a>
             </li>
             <li className="nav-item">
               <a href="../my_orderlist" className="nav-link">
               Weekly <span className="float-right badge bg-success">{"9"}</span>
               </a>
             </li>
             
           </ul>
         </div>
       </div>
     </div>
{/* end of recent orders */}

{/* Action To DO */}
<div className="col-md-4">
       <div className="card card-widget widget-user-2">
         <div className="widget-user-header bg-info">
           <div className="widget-user-image">
            <span className="info-box-icon text-red1 bg-info1 elevation-11"><i className="fa fa-cubes fa-3x" aria-hidden="true"></i></span>
           </div>
           <h3 className="widget-user-username"></h3>
           <h5 className="widget-user-desc">{"Available Offers & Discount"}</h5>
           {/* <p class="card-text">Important to do</p> */}
         </div>
         <div className="card-footer p-0">
           <ul className="nav flex-column">
           {/* <li className="nav-item">
             <a className="nav-link" onClick={()=>props.setOnClkView("offer")} data-toggle="modal"  data-target="#divViewMoneyOffers" href="javascript:void(0)">Available Offer Moeny
             <span className="float-right badge bg-primary">Rs. {props.offer_money.length>0?props.offer_money[0]["available_amount"]:"-"}</span>
             </a>
             </li> */}
             <li className="nav-item">
             <a className="nav-link" onClick={()=>props.setOnClkView("money")} data-toggle="modal"  data-target="#divViewMoneyOffers" href="javascript:void(0)">Advaiet Money
             <span className="float-right badge bg-primary">Rs. {props.adv_money.length>0?props.adv_money[0]["available_amount"]:"-"}</span>
             </a>
             </li>
            
             
           </ul>
         </div>
       </div>
     </div>
{/* end of Action to DO */}

{/* 4th Item */}

{/* 5th Item */}

{/* 6th Item */}
<div className="col-md-4 pb-20px pt-20px">
<div class="card" style={{width: "18rem;"}}>

<div class="card-body">
<h5 class="card-title">Your Advaiet Consultant</h5>
<p class="card-text">Assigned:</p>
</div>
{/* <ul class="list-group list-group-flush">
<li class="list-group-item gion"><a target="blank" href="https://test.advaiet.com"><i class="fa-solid fa-arrow-right-to-bracket"></i>Login to Advaiet S/w</a></li>

</ul> */}
{typeof props.taxConsultant!=="undefined"?props.taxConsultant.map((item,index)=>{
return <ul class="list-group list-group-flush">

<li class="list-group-item">Name :{item.first_name}</li>
<li class="list-group-item">Email :{item.email_id}</li>
<li class="list-group-item">A third item</li>
</ul>

}):''}

</div>

</div>

</div>)
}
export default Comp_End_User_Dashboard;