import $ from 'jquery';
import React from 'react';
import {btnSetColor_CBack,CallMapMethod_CBack,appGetUrlPath} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './comp_upload_basic.css';
let appUrlPath;


export function Comp_View_Test_Result(props){
  appUrlPath=appGetUrlPath();  
  //alert(JSON.stringify(props));
  //alert(props.userObject);
  let callingVendorId=0;
  if(props.viewLoadDoc!=="undefined"){
   // alert(props.viewLoadDoc[0].doc_list);
   callingVendorId=props.userObject[0].app_user_id;
   if(typeof props.vendorId!=="undefined"){
    callingVendorId=props.vendorId 
   }
   if(callingVendorId!==0){
    //getPreData(callingVendorId);
   }
   
  }
  
  return(<div class="modal" id="divViewTestResult" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">Test Result</h5>
        <button type="button" class="close white" data-dismiss="modal" aria-label="Close">
          <span className="white" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
        <div className="row">
         <div className="col-md-12">
            {props.pItemIds!==""?<embed width="100%" height="500px" src={appUrlPath+"Modal/saveResultLog?node="+props.pItemIds+"&rf="+props.pPurchaseIds+"&code="+props.pItemCodes+"&ct_sth="+'#toolbar=0&navpanes=0'} toolbar="0"></embed>:''}
           
         </div>
        </div>       
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary">Print</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>);
}
export default Comp_View_Test_Result;