import $ from 'jquery';
import React from 'react';
import {GetImgCertUser_CBack,CallMapMethod_CBack} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './comp_upload_basic.css';
let appLoanUrlPath;
export function preBankDetails_TMP(app_token){
  let map=[];
  map.push({name:"curl_type",value:"getBankDetails"});
  map.push({name:"res_func",value:"callBackNg"});
  map.push({name:"app_token",value:app_token});
 
  map["res_func"]="callBackNg";
  map["curl_type"]="getBankDetails";
  CallMapMethod_CBack(this,map,function(data,msg){
   
    if(data!=="0"){
      let lsData=JSON.parse(data);
      if(lsData.length>0){
        $("#col1").val(lsData[0]["col1"]);
        $("#col2").val(lsData[0]["col2"]);
        $("#col3").val(lsData[0]["col3"]);
        $("#col4").val(lsData[0]["col4"]);
        $("#col5").val(lsData[0]["col5"]);
        //$("#existingImg").attr("src",'data:image/png;base64,'+btoa(unescape(encodeURIComponent(lsData[0]["docs"]))));
        $("#existingImg").attr("src",lsData[0]["docs"]);
      }
    }
    
  })
}
export function UpdDocSubmit(frmName){
 
  GetImgCertUser_CBack(this,frmName,"updateBankDetailsP",function(data,msg,userMsg){
    alert(data+msg+userMsg);
  });
}

export function ModalPrint(){
    return(<div class="modal" id="divMerchantAgreement" tabindex="-1" role="dialog">
    <div id="tmpRsp"></div>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">MERCHANT AGREEMENT</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="white" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      </div>
      

    </div>
  </div>
</div>)
}

export function Comp_Vendor_Package(props){
  //alert(JSON.stringify(props));
  //alert(props.userObject);
  let callingVendorId=0;
  if(props.viewLoadDoc!=="undefined"){
   // alert(props.viewLoadDoc[0].doc_list);
   callingVendorId=props.userObject[0].app_user_id;
   if(typeof props.vendorId!=="undefined"){
    callingVendorId=props.vendorId 
   }
   if(callingVendorId!==0){
    //getPreData(callingVendorId);
    preBankDetails_TMP(callingVendorId);
   }
  }
  
  return(<div class="modal" id="divVendorPackage" tabindex="-1" role="dialog">
    <div id="tmpRsp"></div>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">Business Package</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="white" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="frmVendorPackage" name="frmVendorPackage" className='formarea-sm' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="lsUpdFiles" id="lsUpdFiles" />
        <input type="hidden" name="curl_type" id="curl_type" value={"hidden"}/>
        <input type="hidden" name="app_token" id="app_token" value={props.userObject[0].app_user_id} />
        <input type="hidden" name="hi_docs" id="hi_docs"/>
        <div className="row">
        <div className="col-md-6">
            <div className="form-group">
              <label>Business Name*</label>
                <input type="text" id="col1" name="col1" className="form-control required" placeholder="Company Name" />
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
              <label>Active Location*</label>
                <input type="text" id="col1" name="col1" className="form-control required" placeholder="Company Name" />
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
              <label>Service Category</label>
                <input type="text" id="col2" name="col2" className="form-control required" />
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group">
              <label>% of Deduction</label>
                <input readonly type="text" id="col2" name="col2" className="form-control required" value={"10"}/>
            </div>
         </div>
         
         <div className="col-md-12">
            <div className="form-group">
            <label className="col-sm-2">Check Fine Prints*</label><br></br>
              <label class="checkbox-inline"><input type="checkbox" value="Valid only for option purchased" class="finePrint" name="col11" />Valid only for successfully service completed.</label><br></br>
              <label class="checkbox-inline"><input type="checkbox" value="All goods or services must be used by the same person" class="finePrint" name="col11" />All goods or services must be used by the same company/person</label><br></br>
              <label class="checkbox-inline"><input type="checkbox" value="Merchant's standard cancellation policy applies (any fees not to exceed ADVAIET price)" class="finePrint" name="col11" />Merchant's standard cancellation policy applies (any fees not to exceed ADVAIET price)</label><br></br>
            </div>
         </div>
         
         
         <div className="col-md-12">
            <div className="form-group">
            <label className="col-sm-2">Sign Agreement*</label><br></br>
            <p>I am a duly authorized representative of Merchant and my signature binds Merchant to this <a href="#" data-toggle="modal" data-target="#myModal">Agreement</a> including the <a href="<?php echo $appUrl ?>merchantTerm" target="_blank" > Merchant Terms and Conditions</a></p>
            </div>
         </div>
        


         
         <div className="row audioLibThumb imgLibThumb"></div>
         <div className="col-md-3">
               {/* upload file 1 */}
               <div className="card-upd  card-info">
                Existing Uploaded Copy (if Any)
               <img id="existingImg" />
                      
                      </div>
               <a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb updthumb">
<div className="card-header">
  <h3 className="card-title center-middle">Signature Image </h3>
  <img className="upd-icon img-fluid" src={updFile} />
  <small> (uploaded signature image will be fully secured under ADAVIET database) </small>
</div>
<div className="card-body">
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" accept="image/*,application/pdf" className="updImgThumb" />
      
    </div>
   
    
  </div>
  
</div>
</a>
</div>
{/* end of file upload */}
           </div>

               
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" onClick={()=>UpdDocSubmit("#frmBankDetails")} class="btn btn-primary">Upload Now</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>);
}
export default Comp_Vendor_Package;