import $ from 'jquery';
import React from 'react';
import {btnSetColor_CBack,CallMapMethod_CBack,appGetUrlPath} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './comp_upload_basic.css';
let appUrlPath;
export function getPreProfileData(app_token){
  let map=[];
  map.push({name:"curl_type",value:"getBasicProfile"});
  map.push({name:"res_func",value:"callBackNg"});
  map.push({name:"app_token",value:app_token});
 
  map["res_func"]="getBasicProfile";
  map["curl_type"]="getBasicProfile";
  CallMapMethod_CBack(this,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let lsData=JSON.parse(data);
      if(lsData.length>0){
        $("#company_name").val(lsData[0]["company_name"]);
        $("#address").val(lsData[0]["address"]);
        $("#state_id").val(lsData[0]["state_id"]);
        $("#city_id").val(lsData[0]["city_id"]);
        $("#pincode").val(lsData[0]["pincode"]);
        $("#alt_contact_no").val(lsData[0]["alt_contact_no"]);
        $("#email").val(lsData[0]["email"]);
        $("#pan_no").val(lsData[0]["pan_no"]);
        $("#gstin_no").val(lsData[0]["gstin_no"]);
      }
     
    }
    
  })
}
export function UpdPackageStatus(frmName){
 
  btnSetColor_CBack(this,frmName,"UpdateEntityDetails",function(data,datares){
    alert(data+"-="+datares);
    if(data===-10){
      alert("Kindly fill required fields");
    }else if(data==="001"){
      
    }else{
      //Not accepted from server
    }
  });
}

export function Comp_Approve_Packages(props){
    appUrlPath=appGetUrlPath();
    //alert(appUrlPath);

  //alert(JSON.stringify(props.vendor));
  //alert(props.userObject);
  let callingVendorId=0;
  let docPath="";
  if(typeof props.vendor.col5!=="undefined"){
    docPath=appUrlPath+"//sign_doc//"+props.vendor.col5;
  }
  if(props.viewLoadDoc!=="undefined"){
   // alert(props.viewLoadDoc[0].doc_list);
   callingVendorId=props.userObject[0].app_user_id;
   if(typeof props.vendorId!=="undefined"){
    callingVendorId=props.vendorId 
   }
   if(callingVendorId!==0){
    //getPreProfileData(callingVendorId);
   }
  
  }
  
  return(<div class="modal" id="divApprovePackages" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">Basic Details</h5>
        <button type="button" class="close white" data-dismiss="modal" aria-label="Close">
          <span className="white" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="frmApprovePackages" name="frmApprovePackages" className='formarea' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="curl_type" id="curl_type" value="UpdateEntityDetails" />  
        <input type="hidden" name="m_type" id="m_type" value="UpdateEntityDetails" /> 
        <input type="hidden" name="type_id" id="type_id" value={btoa("27")}/>
        <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
        <input type="hidden" name="app_token" id="app_token" value={callingVendorId} />
        <input type="hidden" name="vendor_id" id="vendor_id" value={callingVendorId}/>
        <input type="hidden" name="process_id" id="process_id" value={btoa(props.vendor.entity_details_id)}  />
        <input type="hidden" name="current_status" id="current_status" value={props.vendor.status}/>
        <input type="hidden" name="hi_docs" id="hi_docs"/>
        <div className="row">
         <div className="col-md-6">
            <div className="form group form-row">
              <label className="col-sm-2">Company Name*</label>
                <input type="text" id="company_name" name="company_name" readonly={"true"} className="form-control col-sm-10 required1" placeholder="Company Name" value={props.vendor.company_name} />
            </div>
         </div>
         <div className="col-md-6">
            <div className="form-group form-row">
              <label className="col-sm-2">Active Location*</label>
                <input type="text" id="address" name="address" readonly={"true"} className="form-control col-sm-10 required1" value={props.vendor.address} />
            </div>
         </div>

         <div className="col-md-6">
            <div className="form-group form-row">
              <label className="col-sm-2">State*</label>
                <select id="state_id" name="state_id" disabled={"true"} className="form-control col-sm-10 required1">
                    <option value={"0"}>--State--</option>
                    {props.stateList.map((item,index)=>{
                      return <option selected={props.vendor.state_id==item.id} value={item["id"]}>{item["label"]}</option>
                    })}
                </select>
            </div>
         </div>
         
         <div className="col-md-6">
            <div className="form-group form-row">
              <label className="col-sm-2">City*</label>
                <input type="text" id="city_id" name="city_id" readonly={"true"} className="form-control col-sm-10 required1" value={props.vendor.city_id} />
            </div>
         </div>

         <div className="col-md-6">
            <div className="form-group form-row">
              <label className="col-sm-2">Pincode*</label>
                <input type="number" id="pincode" name="pincode" readonly={"true"} className="form-control col-sm-10 required1" value={props.vendor.pincode} />
            </div>
         </div>
                    <div className="col-md-12">
<a href={docPath} target={"blank"}> View Signed Document </a>
                    </div>
        <div className="col-md-6">
            <div className="form-group">
                <label>Current Status</label>
                {props.vendor.status}

            </div>
        </div>            
        <div className="col-md-6">
            <div className="form-group">
                <label>Update Status</label>
                <select className="form-control required" id="upd_status" name="upd_status">
                    <option value="0">Update Status</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                    <option value="Revise Upload">Revise Upload</option>
                    </select>

            </div>
        </div> 

        </div>       
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" onClick={()=>UpdPackageStatus("frmApprovePackages")} class="btn btn-primary">Update Now</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>);
}
export default Comp_Approve_Packages;