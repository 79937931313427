import $ from 'jquery';
import React from 'react';
import {getUserObjData} from '../function_lib/userToken';
import User_Header from './user_header';
import User_Footer from './user_footer';
//import Mobile_Widgets from '../sample_comp/mobile_widgets';
import {StateMaster} from '../function_lib/city_india';
import {CallMapMethod_CBack,RespBkMthdMapp,AjaxCallMethod,listClock} from '../function_lib/common_lib';

import Comp_Upload_Basic from '../user_components/comp_upload_basic';
import Comp_Bank_Details from '../user_components/comp_bank_details';
import Comp_Working_Hours from '../user_components/comp_working_hours';
import Comp_Entity_Docs from '../user_components/comp_entity_docs';
import pdfIcon from '../assets/images/pdf_icon.png';

import './common.css';

let clsActivationProcess;
let logInObj;
let stateList;
let lsClock=[];


let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];

class Activation_Process extends React.Component{
    constructor(props){
      super(props);
      if(localStorage.getItem("userDS")!=null){
        //logInObj=JSON.parse(localStorage.getItem("userDS"));
        logInObj=getUserObjData();
      }
      clsActivationProcess=this;
      stateList=StateMaster();
      lsClock=listClock(15);
    }
      /* entity documents upload file */

    /* end of entity documents*/

    // related upload file logic
    ImageThumbBack(flImg, flName,fObject,othInfo="",lsControl="") {
      alert(lsControl);
        let destControl="#lsUpdFiles";
        if(lsControl!==""){
          destControl="#"+lsControl;
        }
        if(othInfo===""){
          othInfo="nm"+indexExisting+","+indexExisting;
        }
        indexExisting++;
        var arrNmValue=othInfo.split(',');
        //arrExistingFiles.push
        arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
        var strSet = "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
        "<i class=\"icon-close _removeBtn"+indexExisting+"\"  param2=\""+lsControl+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
          "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
          "</div>";
          if(flImg.indexOf("data:application/pdf")!=-1 || flImg.indexOf("data:application/pdf")!=-1){
           strSet= "<div id=\"ref_div_"+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
            "<i class=\"icon-close _removeBtn"+indexExisting+"\" param2=\""+lsControl+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
              "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\""+pdfIcon+"\" /></a>" +
              "</div>";
          }
          
        //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
        objImages.push({ name: arrNmValue[1], value: flImg });
        $(destControl).val("{\"objs\":" + JSON.stringify(objImages) + "}");
        //alert(strSet);            
        $(".imgLibThumb").append(strSet);
        if(fObject!==null){
          fObject.val('');
        }
      //   $('.gallery-item').magnificPopup({
      //     type: 'image',
      //     gallery: {
      //         enabled: true
      //     }
      // });
      
      RespBkMthdMapp(clsActivationProcess, "_removeBtn"+indexExisting, "rmFileProc", "2");
        //imgLibThumb
            }
            rmFileProc(data,lsControl=""){
              if(document.getElementById("ref_div_"+data)!=null){
                document.getElementById("ref_div_"+data).remove();
              }
              
              this.lcRemoveJSON(data,"image",lsControl);
          }
          lcRemoveJSON(refKey,fType,lsControl){
            let destControl="#lsUpdFiles";
            if(lsControl!==""){
              destControl="#"+lsControl;
            }
            var jsonP=JSON.parse($(destControl).val());
            var arrJson=jsonP.objs;
            for(var ai=0;ai<arrJson.length;ai++){
              if(arrJson[ai]["name"]===refKey){
                arrJson.splice(ai,1);
                $(destControl).val(JSON.stringify(jsonP));
                break;
              }
            }
            objImages=arrJson;
          }
      //end of upload file logic    
    componentDidMount(){
        const script = document.createElement("script");
        script.src = "/main.js";
        script.async = true;
        //script.onload = () => clsObject.scriptLoaded();
        document.body.appendChild(script);

        AjaxCallMethod(this,"okSuccCNOT");
    }
    render(){
        return(<div>
               <User_Header />
               <div className="breadcrumb-area product_breadcrumb">
<div className="container">
    <div className="row">
        <div className="col-12">
            <div className="row breadcrumb_box  align-items-center">
                <div className="col-auto text-center text-md-left">
               
                </div>
                <div className="col">
                    {/* <!-- breadcrumb-list start --> */}
                    <ul className="breadcrumb-list text-center text-md-right product_linkssh">
                        <li className="breadcrumb-item"><a href="../dashboard">Profile Activation</a></li>
                        {/* <li className="breadcrumb-item"><a href="../products">Products</a></li> */}
                        {(typeof categoryParam!=="undefined")?<li className="breadcrumb-item active"><a href="#"></a></li>:''}
                        
                    </ul>
                    {/* <!-- breadcrumb-list end --> */}
                </div>
            </div>
        </div>
    </div>
</div>
</div>

{/* card process to */}
<div class="card">
              <div class="card-header">
                <h3 class="card-title">Profile Status</h3>

                <div class="card-tools">
                  {/* <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="btn btn-tool" data-card-widget="remove">
                    <i class="fas fa-times"></i>
                  </button> */}
                </div>
              </div>
              
              <div class="card-body p-0">
                <ul class="products-list product-list-in-card pl-2 pr-2">
                  <li class="item">
                    <div class="product-img">
                    <i className="ion-arrow-graph-up-right"></i>
                      {/* <img src="dist/img/default-150x150.png" alt="Product Image" class="img-size-50" /> */}
                    </div>
                    <div class="product-info">
                      <a href="javascript:void(0)" data-toggle="modal" data-target="#divBasicDetails" href="javascript:void(0)" class="product-title">Basic Details
                        <span class="badge badge-warning float-right">Pending</span></a>
                      <span class="product-description">
                        Business Address.
                      </span>
                    </div>
                  </li>
                  
                  <li class="item">
                    <div class="product-img">
                    <i className="ion-arrow-graph-up-right"></i>
                      {/* <img src="dist/img/default-150x150.png" alt="Product Image" class="img-size-50" /> */}
                    </div>
                    <div class="product-info">
                      <a data-toggle="modal"  data-target="#divBankDetails" href="javascript:void(0)" class="product-title">Bank Details
                        <span class="badge badge-info float-right">pending</span></a>
                      <span class="product-description">
                        Bank details as per your business current account
                      </span>
                    </div>
                  </li>
                  
                  <li class="item">
                    <div class="product-img">
                    <i className="ion-arrow-graph-up-right"></i>
                      {/* <img src="dist/img/default-150x150.png" alt="Product Image" class="img-size-50" /> */}
                    </div>
                    <div class="product-info">
                      <a data-toggle="modal"  data-target="#divEntityDoc" href="javascript:void(0)" class="product-title">
                        Registration Details and Certification
                         <span class="badge badge-danger float-right">
                        Pending
                      </span>
                      </a>
                      <span class="product-description">
                        Fill your legal registration details , and upload pdf,image of documents
                      </span>
                    </div>
                  </li>
                
                  <li class="item">
                    <div class="product-img">
                    <i className="ion-arrow-graph-up-right"></i>
                      {/* <img src="dist/img/default-150x150.png" alt="Product Image" class="img-size-50"/> */}
                    </div>
                    <div class="product-info">
                      <a href="javascript:void(0)" class="product-title">Signin Documents
                        <span class="badge badge-success float-right">Pending</span></a>
                      <span class="product-description">
                        Read and Verify the acceptance with digitally signed documents
                      </span>
                    </div>
                  </li>
                 
                </ul>
              </div>
             
             
            </div>
{/* end of card process */}


{/* card process to */}
<div class="card">
              <div class="card-header">
                <h3 class="card-title">Business Page Activation</h3>

                <div class="card-tools">
                  {/* <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>
                  <button type="button" class="btn btn-tool" data-card-widget="remove">
                    <i class="fas fa-times"></i>
                  </button> */}
                </div>
              </div>
              
              <div class="card-body p-0">
                <ul class="products-list product-list-in-card pl-2 pr-2">
                  <li class="item">
                    <div class="product-img">
                    <i className="ion-arrow-graph-up-right"></i>
                      {/* <img src="dist/img/default-150x150.png" alt="Product Image" class="img-size-50" /> */}
                    </div>
                    <div class="product-info">
                      <a href="javascript:void(0)" data-toggle="modal"  data-target="#divWorkHours" class="product-title">Working Hours
                        <span class="badge badge-warning float-right">Pending</span></a>
                      <span class="product-description">
                        Business Address.
                      </span>
                    </div>
                  </li>
                  
                  <li class="item">
                    <div class="product-img">
                    <i className="ion-arrow-graph-up-right"></i>
                      {/* <img src="dist/img/default-150x150.png" alt="Product Image" class="img-size-50" /> */}
                    </div>
                    <div class="product-info">
                      <a href="javascript:void(0)" class="product-title">Business Page Images
                        <span class="badge badge-info float-right">pending</span></a>
                      <span class="product-description">
                        Bank details as per your business current account
                      </span>
                    </div>
                  </li>
                  
                  <li class="item">
                    <div class="product-img">
                    <i className="ion-arrow-graph-up-right"></i>
                      {/* <img src="dist/img/default-150x150.png" alt="Product Image" class="img-size-50" /> */}
                    </div>
                    <div class="product-info">
                      <a href="javascript:void(0)" class="product-title">
                      Business Page Activation
                         <span class="badge badge-danger float-right">
                        $350
                      </span>
                      </a>
                      <span class="product-description">
                        Fill your legal registration details , and upload pdf,image of documents
                      </span>
                    </div>
                  </li>
                
                  <li class="item">
                    <div class="product-img">
                    <i className="ion-arrow-graph-up-right"></i>
                      {/* <img src="dist/img/default-150x150.png" alt="Product Image" class="img-size-50"/> */}
                    </div>
                    <div class="product-info">
                      <a href="javascript:void(0)" class="product-title">Signin Documents
                        <span class="badge badge-success float-right">Pending</span></a>
                      <span class="product-description">
                        Read and Verify the acceptance with digitally signed documents
                      </span>
                    </div>
                  </li>
                 
                </ul>
              </div>
             
             
            </div>
{/* end of card process */}

        
<Comp_Upload_Basic userObject={logInObj} stateList={stateList}/>
<Comp_Bank_Details userObject={logInObj} />
<Comp_Working_Hours userObject={logInObj} listClocks={lsClock} />
<Comp_Entity_Docs userObject={logInObj}/>      
               <User_Footer/>
        </div>)
    }
}
export default Activation_Process; 