import  $ from 'jquery';
import React from 'react';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import {CallCMSMapMethod} from '../function_lib/common_cms';
import Front_Header from './front_header';
import Footer from './footer';
import Cart_Canvas from '../sample_comp/cart_canvas';
import Product_Listing from '../sample_comp/product_listing';
import AOS from 'aos';
/* components */
import Top_Banner from '../sample_comp/top_banner';
import Features_Slider  from '../sample_comp/features_slider';
import Home_Banner from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
/*Image */
import loginPng from '../assets/images/icons/login.png';

import './login_new.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let logInObj="";
class Login_New extends React.Component{
    
  setClickFor(idName){
    if(idName=="nav_contactotp"){
      //document.getElementById("nav_login").classList.remove("active");
      //document.getElementById("nav_regsi").classList.remove("active");

      document.getElementById("lg1").classList.remove("active");
      //document.getElementById("lg2").classList.remove("active");
      document.getElementById("lg3").classList.remove("active");
      
      document.getElementById("lg4").classList.add("active");
    }else if(idName==="nav_otp"){
      document.getElementById("nav_login").classList.remove("active");
      //document.getElementById("nav_regsi").classList.add("active");

      //document.getElementById("lg2").classList.remove("active");
      document.getElementById("lg1").classList.remove("active");
      document.getElementById("lg4").classList.remove("active");
      document.getElementById("lg3").classList.add("active");
    }else if(idName==="nav_regsi"){
        document.getElementById("nav_login").classList.remove("active");
        //document.getElementById("nav_regsi").classList.add("active");
        //document.getElementById("lg2").classList.add("active");
        document.getElementById("lg1").classList.remove("active");
        document.getElementById("lg3").classList.remove("active");
        document.getElementById("lg4").classList.remove("active");
        }else{
        document.getElementById("nav_login").classList.add("active");
        //document.getElementById("nav_regsi").classList.remove("active");
        //document.getElementById("lg2").classList.remove("active");
        document.getElementById("lg1").classList.add("active");
        document.getElementById("lg3").classList.remove("active");
        document.getElementById("lg4").classList.remove("active");
      }
    
    //$("#nav_regsi").click();
  }
    constructor(props){
        super(props);
        loc=props.location["pathname"].substr(1);
        if(localStorage.getItem("userDS")!=null){
          logInObj=JSON.parse(localStorage.getItem("userDS"));
        }

        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          
          //window.location.href="./Dashboard";
        }
      }
      componentDidMount(){
        clsObject=this;
      var pgMap=[];
      pgMap.push({name:"curl_type",value:"getPgData"});
      pgMap.push({name:"res_func",value:"callBackpgData"});
      pgMap.push({name:"loc",value:loc});
     // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
      //mapCat.push({name:"emp_id",value:userObject[0].id});
      pgMap["res_func"]="callBackpgData";
      pgMap["curl_type"]="getPgData";
      CallCMSMapMethod(clsObject,pgMap);
      AjaxCallMethod(this,"okSuccCNOT");
    }
    okSuccLogn(data,datares){
        if(data==="not_validated"){
          $("#getRegLogn").html(datares);
        }else{
//  alert(data);
//  return;
 if(data!=="0" && data!=="" && data!=="not_validated"){
  $("#getRegLogn").html("Login Successfully..");
 localStorage.setItem('userDS',data);
 window.location.href="/home";
}else{
  $("#getRegLogn").html("Invalid UserName or Password !");
}
    }
  }
  okSuccCNOT(data,datares){
    //alert(data);
    if(data==="not_validated"){
      $("#getRegLogn").html(datares);
      return;
    }
    if(data!="0" && data!==""){
      clsObject.setClickFor("nav_otp");
      let useOTP=JSON.parse(data);
      $("#refotp_id").val(useOTP[0]["id"]);
      $("#refotp_contact_id").val(useOTP[0]["id"]);
      $(".opt_resp_num").html(datares);
    //  $("#getRegResp").html("Login Successfully..");
    //  localStorage.setItem('userDS',data);
    //  window.location.href="/home";
    }else{
      //clsObject.setClickFor("nav_otp");
      $("#getRegLognReg").html("Invalid UserName or Password !");
    }
}
okSuccOTP(data,datares){
  if(data==="not_validated"){
    $("#getRegLognOTP").html(datares);
    return;
  }
 
   if(data!=="0" && data!==""){
      $("#getRegLognOTP").html("Verify Successfully..");
     localStorage.setItem('userDS',data);
     window.location.href="/home";
   }else{
    $("#getRegLognOTP").html("Invalid OTP");
   }
}
  callBackpgData(data,msg){
    //alert("testing of2 "+data);
    //$("#pgComp").html(data);
    var listObj= JSON.parse("{\"access\":"+data+"}");
    //alert("successfully parse");
    //var mainHt=Cart_Canvas();
    var mainHt="";
    if(loc==="home"){
       // mainHt+=Video_Banner_Comp();
    }
    var injextI=0;
    var injCondition=false;
  
    for(var _dlist=0;_dlist<50;_dlist++){
     
        if(listObj.access[_dlist]){
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               mainHt+=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
               // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
            }else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    mainHt+=Features_Slider();
                    //alert(products);
                    mainHt+=products;
                }
           }
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    if(listObj.access.length==0){
        mainHt+=products;
    }
    //alert(mainHt);
    //mainHt+=Product_Hori_Slider();
    $("#pgComp").html(mainHt);
    AOS.init();
    AOS.init({
      once: true
    });
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);
      //this.forceUpdateHandler();
  
}
render(){
    return(<div>
        <Front_Header/>
        <div id="pgComp">
      </div>
       <div className="bg-gogreen login-register-area">
<div className="container">
<div className="row border-box-2 loginform form-center login-register-tab-list nav mt-4">
<div className="header">
       <img id="logo" src={loginPng} alt="" />
       {/* <p className="formname login-register-tab-list nav"><a id="nav_login" className="text-white active" data-bs-toggle="tab" href="#lg1">
       Sign In
               </a>
                 </p> */}
       <p className="formtxt"><a id="nav_login" className="text-white active" data-bs-toggle="tab" href="#lg1">Returning Customer Sign In</a></p>
       
       </div>
       <div className="fdiv tab-content">
       <div id="lg4" className="tab-pane">
             <form className="formarea">
             <input type="hidden" name="curl_type" id="curl_type" value="registerWithOTP" />  
                <input type="hidden" name="m_type" id="m_type" value="registerWithOTP" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
                <p className="opt_resp_num"></p>
                <div className="from-group">
                <label className="form-control-label">Enter Registerd Email</label>
                <input type="text" name="p1_contact" id="p1_contact" placeholder=''  />
                </div>
                
<div className="row flex-sm-row-reverse">
                    <div className="col-md-6 text-right">
                        <div className="payment-link">
                        {/* <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Login with OTP</a></p> */}
                          
                        </div>
                    </div>
                    <div className="col-md-6 text-left">
                        <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contact444otp")} href="javascript:void(0)">Back to Login.</a></p>
                    </div>
                
                  
                </div>
                <input type="hidden" name="ref_id" id="refotp_contact_id" />
                <div id="getRegLognOTP" style={{color:"red"}}></div>
                <button className="btn-primary _btnSetColor"  type="button"><span>Verify Now</span></button>
             </form>
             
           </div>

           <div id="lg3" className="tab-pane">
             <form className="formarea">
             <input type="hidden" name="curl_type" id="curl_type" value="logotp" />  
                <input type="hidden" name="m_type" id="m_type" value="logotp" />
                <input type="hidden" name="res_func" id="res_func" value="okSuccOTP" />
                <p className="opt_resp_num"></p>
                <input  type="number" name="p1_otp" id="p1_otp"  />
                <div className="from-group">
                <label className="form-control-label">Enter OTP</label>
                <input type="hidden" name="ref_id" id="refotp_id" />
                </div>
               
                <div id="getRegLognOTP" style={{color:"red"}}></div>
                <div className="col-md-6 text-right">
                            <div className="payment-link">
                            <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Resend OTP</a></p>
                              
                            </div>
                        </div>
                        <div className="col-md-6 text-left">
                            {/* <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Forget Password.</a></p> */}
                        </div>
                <button className="btn-primary _btnSetColor" type="button"><span>Verify Now</span></button>
             </form>
           </div>
       <div id="lg1" className="tab-pane active">
           <form className="formarea">
           <input type="hidden" name="curl_type" id="curl_type" value="getAccessToken" />  
            <input type="hidden" name="m_type" id="m_type" value="getAccessToken" />
            <input type="hidden" name="res_func" id="res_func" value="okSuccLogn" />
            <div className="form-group">
                <label className="form-control-label">Contact No/Email</label>
                                <input type="text" className="form-control" name="txtUserName" id="txtUserName" placeholder="" />
                                </div>
            <div className="form-group">
            <label className="form-control-label">Password</label>
            <input rf="#btnLoginEv" type="password" name="txtPassword" id="txtPassword" className="form-control keyEvent_btn" placeholder="" />
                </div>                    
                                
           <div id="getRegLogn" style={{color:"red"}}></div><br></br>
           {/* <div className="row flex-sm-row-reverse">
                    <div className="col-md-6 text-right">
                        <div className="payment-link">
                        <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Login with OTP</a></p>
                          
                        </div>
                    </div>
                    <div className="col-md-6 text-left">
                        <p className="copy-text"><a className='linkclass' onClick={()=>this.setClickFor("nav_contactotp")} href="javascript:void(0)">Forget Password.</a></p>
                    </div>
                
                  
                </div> */}
           <button className="btn-primary _btnSetColor" id="btnLoginEv" name="btnLoginEv" type="button"><span>Login</span></button>
           <p className="bottomtxt">Not have login ! <a className="linkclass" href="../register">Click here to register</a></p>
       </form>
       </div>
      
   </div>
  
</div>
</div>
</div>
<Footer/>
   </div>)
}
}
export default Login_New;
