import $ from 'jquery';
import React from 'react';
import {GetImgCertUser_CBack,CallMapMethod_CBack} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './comp_upload_basic.css';
let appLoanUrlPath;
export function preBankDetails(app_token){
  let map=[];
  map.push({name:"curl_type",value:"getBankDetails"});
  map.push({name:"res_func",value:"callBackNg"});
  map.push({name:"app_token",value:app_token});
 
  map["res_func"]="callBackNg";
  map["curl_type"]="getBankDetails";
  CallMapMethod_CBack(this,map,function(data,msg){
   
    if(data!=="0"){
      let lsData=JSON.parse(data);
      if(lsData.length>0){
        $("#col1").val(lsData[0]["col1"]);
        $("#col2").val(lsData[0]["col2"]);
        $("#col3").val(lsData[0]["col3"]);
        $("#col4").val(lsData[0]["col4"]);
        $("#col5").val(lsData[0]["col5"]);
        //$("#existingImg").attr("src",'data:image/png;base64,'+btoa(unescape(encodeURIComponent(lsData[0]["docs"]))));
        $("#existingImg").attr("src",lsData[0]["docs"]);
      }
    }
    
  })
}
export function UpdDocSubmit(frmName){
 
  GetImgCertUser_CBack(this,frmName,"updateBankDetailsP",function(data,msg,userMsg){
    alert(data+msg+userMsg);
  });
}

export function Comp_Bank_Details(props){
  //alert(JSON.stringify(props));
  //alert(props.userObject);
  let callingVendorId=0;
  if(props.viewLoadDoc!=="undefined"){
   // alert(props.viewLoadDoc[0].doc_list);
   callingVendorId=props.userObject[0].app_user_id;
   if(typeof props.vendorId!=="undefined"){
    callingVendorId=props.vendorId 
   }
   if(callingVendorId!==0){
    //getPreData(callingVendorId);
    preBankDetails(callingVendorId);
   }
  }
  
  return(<div class="modal" id="divBankDetails" tabindex="-1" role="dialog">
    <div id="tmpRsp"></div>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">Bank Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="white" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="frmBankDetails" name="frmBankDetails" className='formarea' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="lsUpdFiles" id="lsUpdFiles" />
        <input type="hidden" name="curl_type" id="curl_type" value={"hidden"}/>
        <input type="hidden" name="app_token" id="app_token" value={props.userObject[0].app_user_id} />
        <input type="hidden" name="hi_docs" id="hi_docs"/>
        <div className="row">
         <div className="col-md-4">
            <div className="form group">
              <label>Account Name*</label>
                <input type="text" id="col1" name="col1" className="form-control required" placeholder="Company Name" />
            </div>
         </div>
         <div className="col-md-4">
            <div className="form-group form-row">
              <label>A/c Number*</label>
                <input type="text" id="col2" name="col2" className="form-control required" />
            </div>
         </div>
         <div className="col-md-4">
            <div className="form-group form-row">
              <label>Confirm A/c Number*</label>
                <input type="text" id="cnf_col2" name="cnf_Col2" className="form-control required" />
            </div>
         </div>
         <div className="col-md-4">
            <div className="form-group form-row">
              <label>Account Type*</label>
                <select id="col3" name="col3" className="form-control required">
                    <option value="0">-Account Type-</option>
                    <option value="CURRENT">CURRENT</option>
                    <option value="SAVING">SAVING</option>
                </select>
            </div>
         </div>
         
         <div className="col-md-4">
            <div className="form-group form-row">
              <label>Bank Name*</label>
                <input type="text" id="col4" name="col4" className="form-control required" />
            </div>
         </div>

         <div className="col-md-4">
            <div className="form-group form-row">
              <label>IFSC Code*</label>
                <input type="number" id="col5" name="col5" className="form-control required"/>
            </div>
         </div>

         
         <div className="row audioLibThumb imgLibThumb"></div>
         
         <div className="col-md-3">             
               <a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb updthumb">
<div className="card-header">
  {/* <h3 className="card-title center-middle">Upload Cancelled Cheque </h3> */}
  <span>Upload Cancelled Cheque</span>
  <img className="upd-icon img-fluid" src={updFile} />
  <small> (Max File Size 1MB) </small>
  <input type="file" ref_img="ImageThumbBack" multiple name="customFileBr" id="customFileBr" accept="image/*,application/pdf" className="updImgThumb" />
</div>

</a>
</div>
<div className="col-md-3">
               {/* upload file 1 */}
               <div className="card-upd  card-info">
                Existing Uploaded Copy
               <img id="existingImg" />
                      
                      </div>
         </div>
{/* end of file upload */}
           </div>

               
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" onClick={()=>UpdDocSubmit("#frmBankDetails")} class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>);
}
export default Comp_Bank_Details;