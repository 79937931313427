import React from 'react';
import './comp_sorting_bar.css';
export const onChangeEvent=()=>{
    alert("i m in onCHange");
}
const Comp_Sorting_Bar=()=>{
    return (
        <div class="parent_tb mb-2">
        <div class="line_tb">
            <div class="left_tb">
                
            </div>
            <div class="right_tb">
                <select class="form-control onchangeselect">
                    <option value="default">-Sort By-</option>
                    <option value="Price Low to High">Price Low to High</option>
                    <option value="Price High to Low">Price High to Low</option>
                    {/* <option value="Brand">Brand</option> */}
                    <option value="Name A – Z">Name A – Z</option>
                    <option value="Name Z – A">Name Z – A</option>
                </select>
            </div>
        </div>
    </div>
)
}
export default Comp_Sorting_Bar;