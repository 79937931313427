import React from 'react';
import {appGetUrlPath} from '../function_lib/common_lib';
 import './image_slider.css';
export function Image_Slider(lsData,clsName="new-product-slider1"){
   // alert(JSON.stringify(lsData));
/*Header Values */
var title="";
if(lsData.length!=0){
title=lsData[0]["g_title"];
}
//var appPath=appGetUrlPath();
var appPath="../";
//var listData=JSON.parse(lsData);
var strResp="";

var strHeader="<div  class=\"main-blog-area pt-10px\">"+
"<div class=\"container\">"+
    "<div class=\"row\">"+
        "<div style=\"text-align:center\" class=\"col-md-12\">"+
            "<div style=\"display:inline-block;\" class=\"flag bottom-boder heading section-heading  page__title-bar\">"+
                // "<h5 class=\"title text-white ml-1 title\">"+title+"</h5>"+
            "</div>"+
        "</div>"+
    "</div>"+
    "<div class=\""+clsName+" swiper-container slider-nav-style-1\">"+
    "<div class=\"new-product-wrapper swiper-wrapper\">";

var strFooter="</div>"+
"<div class=\"swiper-buttons\">"+
"<div class=\"swiper-button-next\"></div>"+
"<div class=\"swiper-button-prev\"></div>"+
"</div>"+
"</div></div></div>";
for(var i=0;i<lsData.length;i++){
    var urlTitle= lsData[i]["col1"];
    urlTitle=urlTitle.replace("?","_");
    urlTitle=urlTitle.replace(" ","-");
    strResp+="<div style=\"background-image:url("+appPath+lsData[i]["col4"]+");background-size:cover;\" class=\"single-blog swiper-slide\">"+
    "<div class=\"blog-image\">"+
    "<h6 class=\"central_blog_txt title1\"></h6>"+
    "</div>"+
"</div>";
}
return strHeader+strResp+strFooter;
}
export default Image_Slider;