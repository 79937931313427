import $ from 'jquery';
import React from 'react';
import {btnSetColor_CBack,CallMapMethod_CBack} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './comp_upload_basic.css';
let appLoanUrlPath;
let cdavmListMoney=[];
export function getPreData(app_token){
  let map=[];
  map.push({name:"curl_type",value:"getBasicProfile"});
  map.push({name:"res_func",value:"callBackNg"});
  map.push({name:"app_token",value:app_token});
 
  map["res_func"]="getBasicProfile";
  map["curl_type"]="getBasicProfile";
  CallMapMethod_CBack(this,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let lsData=JSON.parse(data);
      if(lsData.length>0){
        $("#company_name").val(lsData[0]["company_name"]);
        $("#address").val(lsData[0]["address"]);
        $("#state_id").val(lsData[0]["state_id"]);
        $("#city_id").val(lsData[0]["city_id"]);
        $("#pincode").val(lsData[0]["pincode"]);
        $("#alt_contact_no").val(lsData[0]["alt_contact_no"]);
        $("#email").val(lsData[0]["email"]);
        $("#pan_no").val(lsData[0]["pan_no"]);
        $("#gstin_no").val(lsData[0]["gstin_no"]);
      }
     
    }
    
  })
}
export function UpdDocSubmit(frmName){
 
  btnSetColor_CBack(this,frmName,"distributeVenAdvaietMoney",function(data,datares){
    //alert(data+datares);
    if(data===-10){
      alert("Kindly fill required fields");
    }else if(data==="001"){
      alert(datares);
      window.location.reload();
    }else{
      //Not accepted from server
    }
  });
}

export function Comp_Dis_Adv_Money(props){
  //alert(JSON.stringify(props));
  //alert(props.userObject);
  let callingVendorId=0;
  cdavmListMoney=props.adv_money_list;
  if(props.viewLoadDoc!=="undefined"){
   // alert(props.viewLoadDoc[0].doc_list);
   callingVendorId=props.userObject[0].app_user_id;
   if(typeof props.vendorId!=="undefined"){
    callingVendorId=props.vendorId 
   }
   if(callingVendorId!==0){
    getPreData(callingVendorId);
   }
   
  }
 function informUserMsg(moneyId){
    //alert(JSON.stringify(cdavmListMoney));
    let i=0;
    for(i=0;i<cdavmListMoney.length;i++){
        if(cdavmListMoney[i]["encKey"]===moneyId){
            $("#cdavmListMsg").html("Selected Code is <b>["+cdavmListMoney[i]["m_code"]+"]</b>"+
            " you can distribute available money to <b>"+parseFloat(cdavmListMoney[i]["available_amount"])/50+" contact numbers.</b>");

            break;
        }
    }
    if(i===cdavmListMoney.length){
        $("#cdavmListMsg").html("No available option");
    }
  }
  
  return(<div class="modal" id="divDistAdvMoney" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">Advaiet Money Distribution</h5>
        <button type="button" class="close white" data-dismiss="modal" aria-label="Close">
          <span className="white" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="frmDistAdvMoney" name="frmDistAdvMoney" className='formarea' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="curl_type" id="curl_type" value="distributeVenAdvaietMoney" />  
        <input type="hidden" name="m_type" id="m_type" value="distributeVenAdvaietMoney" /> 
        <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
        <input type="hidden" name="app_token" id="app_token" value={props.userObject[0].app_user_id} />
        <div className="row">
         <div className="col-md-12">
            <div className="form group">
              <label>Select Available Advaiet Code*</label>
                <select id="adv_money_id" name="adv_money_id" onChange={(event)=>informUserMsg(event.target.value)} className="form-control">
                    <option value={"0"}>-Select-</option>
                    {props.adv_money_list.map((item,index)=>{
                        return <option  value={item.encKey}>{item.m_code+": [Avl Rs."+item.available_amount+"]"}</option>
                    })}
                </select>
            </div>
         </div>
         <div className="col-md-12">
            <p style={{"color":"#809fff"}} id="cdavmListMsg"></p>

         </div>
         <div className="col-md-12">
            <div className="form group">
              <label>Enter Comma Separated Contact Numbers*</label>
               <textarea rows="7" className="form-control" id="applied_contact_nos" name="applied_contact_nos">

               </textarea>
            </div>
         </div>
       

         
       

      


         

        


        </div>       
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" onClick={()=>UpdDocSubmit("frmDistAdvMoney")} class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>);
}
export default Comp_Dis_Adv_Money;