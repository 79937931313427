import $ from 'jquery';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import UserToken from '../function_lib/userToken';
import {CallMapMethod,RespBkMthdMapp,AjaxCallMethod,GetImgCertUser} from '../function_lib/common_lib';
import {getRoleDetails,CallCMSMapMethod} from '../function_lib/common_cms';
import Product_Listing from '../sample_comp/product_listing';
import Front_Header from './front_header';
import Footer from './footer';
import Top_Banner from '../sample_comp/top_banner';
import Top_Specified from './top_specified';
import Home_Product from './home_product';
import Product_Slider from '../sample_comp/product_slider';
import Features_Slider  from '../sample_comp/features_slider';
import Feature_Web_List from '../sample_comp/feature_web_list';
import Article_Listing from '../sample_comp/article_listing';
import {Home_Banner,Home_Banner2Div} from '../sample_comp/home_banner';
import Article_Blogs from '../sample_comp/article_blogs';
import Left_Img_Txt_Desc from '../sample_comp/left_img_txt_desc';
import Single_Img_Msg from '../sample_comp/single_img_msg';
import Txt_Description from '../sample_comp/txt_description';
import ImageGallery from '../sample_comp/image_gallery';
import Video_Slider from '../sample_comp/video_slider';
import Collapse_Pan from '../sample_comp/collapse_pan';
import Feature_Card from '../sample_comp/feature_card';
import Testimonials from '../sample_comp/testimonials';

import Feature_Explore_Comp from '../component_version2/feature_explore_comp';
import Image_Collarge_Grid from '../component_version2/image_collarge_grid';

import { Player } from 'video-react';
import AOS from 'aos';
import LogoImg from '../assets/images/my_logo.jpg';
import titleVid from '../assets/videos/imp_water.mp4';

//import titleVid from '../assets/images/backg.gif';
import './index.css';
/* Media */
import logoImg from '../assets/images/logo/logo.png';

import ban1 from '../assets/images/slider-image/02.jpg';
import ban2 from '../assets/images/slider-image/02.jpg';

import why_medi from '../assets/images/icons/capsule.png';
import why_edu from '../assets/images/icons/fist.png';
import why_care from '../assets/images/icons/heart.png';
import why_confi from '../assets/images/icons/discretion.png';
import why_trans from '../assets/images/icons/eye.png';

//import pImg1 from '../assets/images/product-image/AsthmaticaOil.png'
import Left_Menu_Option from '../sample_comp/left_menu_option';
import Blogs from '../sample_comp/blogs';
import Time_Line from '../sample_comp/time_line';

import '../sample_comp/why_us.css';

let loginUser="";
let userObject="";
let clsObject="";
let loc="";
let products="";
let rightBarLists;
class Index extends React.Component{
    constructor(props){
        super(props);
        this.state={viewData:"0"};
        this.multiselectRef = React.createRef();
        loc=props.location["pathname"].substr(1);
        //alert(loc);
        if(localStorage.getItem("userData")!=null){
          //alert(localStorage.getItem("userData"));
          UserToken.setUserObject(localStorage.getItem("userData"));
          userObject= UserToken.getUserObject();
         var listRoles= UserToken.getAccessRoles();
          if(userObject){
            //loginUser=userObject[0].person_name;
          }
          rightBarLists= getRoleDetails(listRoles,loc);
         
          //window.location.href="./Dashboard";
        }
      }
componentDidMount(){
  clsObject=this;
  if(loc===""){
    loc="home";
}
  this.callBackProList("-1","-1");
/*
  var mapCat=[];
  mapCat.push({name:"curl_type",value:"getProducts"});
  mapCat.push({name:"res_func",value:"callBackProList"});
  mapCat.push({name:"gb_token",value:"5e7hmtL7R0GU7zEBVhJZmrKx8Qb4xy-G_ihGPxqUtrGt5qMHlOaQK8ba1r-o7qKuAChRCIL0_Pilz1gStWW2Mg"});
  mapCat.push({name:"p1",value:"new arrivals"});
  mapCat.push({name:"p2",value:"0"});
 // mapCat.push({name:"emp_id",value:userObject[0].id});
  mapCat["res_func"]="callBackProList";
  mapCat["curl_type"]="getProducts";
  CallMapMethod(clsObject,mapCat);
  */
  AjaxCallMethod(clsObject,"okSuccCNOT");

  
  if(loc===""){
      loc="home";
  }
  //alert(loc);
  //loc="blogs_article"
  
  
}
callBackpgData(data,msg){
    //alert("testing of2 "+data);
    //$("#pgComp").html(data);
    var listObj= JSON.parse("{\"access\":"+data+"}");
    //alert("successfully parse");
    var mainHt="";
    if(loc==="home"){
       // mainHt+=Video_Banner_Comp();
    }

    var injextI=0;
    var injCondition=false;
    var ifImgGallery=false;
    
    var strTopBanner="";
    for(var _dlist=0;_dlist<50;_dlist++){
     
        if(listObj.access[_dlist]){
            if(listObj.access[_dlist].top_banner){
               // alert("I am in conditio");
               injCondition=true;
               strTopBanner=Top_Banner(listObj.access[_dlist].top_banner);
            }
            else if(listObj.access[_dlist].home_banner){
               // alert("i am in cond..");
               injCondition=true;
                mainHt+=Home_Banner(listObj.access[_dlist].home_banner);
            }else if(listObj.access[_dlist].home_banner2div){
                injCondition=true;
                mainHt+=Home_Banner2Div(listObj.access[_dlist].home_banner2div)
            }
            else if(listObj.access[_dlist].dual_text_card){
                injCondition=true;
                //var dbs=render(){return(new Dual_Banner(listObj.access[_dlist].dual_text_card)};
               // mainHt+=Dual_Banner(listObj.access[_dlist].dual_text_card);
            }else if(listObj.access[_dlist].single_img_text){
                injCondition=true;
                mainHt+=Single_Img_Msg(listObj.access[_dlist].single_img_text);
            }else if(listObj.access[_dlist].img_text_desc_img_left){
                injCondition=true;
                mainHt+=Left_Img_Txt_Desc(listObj.access[_dlist].img_text_desc_img_left);
            }else if(listObj.access[_dlist].text_description){
                injCondition=true;
                //alert("i am in text");
                //alert(Txt_Description(listObj.access[_dlist].text_description));
                mainHt+=Txt_Description(listObj.access[_dlist].text_description);
            }else if(listObj.access[_dlist].list_slider){
                injCondition=true;
                mainHt+=Article_Blogs(listObj.access[_dlist].list_slider);
            }else if(listObj.access[_dlist].image_gallery){
                injCondition=true;
                ifImgGallery=true;
                mainHt+=ImageGallery(listObj.access[_dlist].image_gallery);
            }else if(listObj.access[_dlist].video_slider){
                //alert("I am in testing");
                injCondition=true;
                mainHt+=Video_Slider(listObj.access[_dlist].video_slider);
            }
            else if(listObj.access[_dlist].blogs){
                
                injCondition=true;
                mainHt+=Blogs(listObj.access[_dlist].blogs);
            }else if(listObj.access[_dlist].feature_web_list){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Feature_Card(listObj.access[_dlist].feature_web_list);
            }else if(listObj.access[_dlist].article_listing){
                injCondition=true;
                //alert("I am in feature "+listObj.access[_dlist].feature_web_list);
                mainHt+=Article_Listing(listObj.access[_dlist].article_listing);
            }else if(listObj.access[_dlist].collapse_pan){
                injCondition=true;
                mainHt+=Collapse_Pan(listObj.access[_dlist].collapse_pan);
            }else if(listObj.access[_dlist].time_line){
                injCondition=true;
                mainHt+=Time_Line(listObj.access[_dlist].time_line);
            }else if(listObj.access[_dlist].testimonials){
                injCondition=true;
                //alert("maina t-- "+injextI);
                let needRatings=true;
                if(loc==="work_with_us"){
                   needRatings=false;
                }
                mainHt+=Testimonials(listObj.access[_dlist].testimonials,injextI,needRatings);
            }else if(listObj.access[_dlist].feature_explore){
                injCondition=true;
                mainHt+=ReactDOMServer.renderToString(<Feature_Explore_Comp lsData={listObj.access[_dlist].feature_explore} />);
            }else if(listObj.access[_dlist].image_collarge_grid){
                injCondition=true;
                mainHt+=Image_Collarge_Grid(listObj.access[_dlist].image_collarge_grid);
            }
            if(injCondition){
                injextI++;
            }
            if(injextI==1){
                //alert("check in condi == "+loc)
                if(loc==="home"){
                    mainHt+=Features_Slider();
                    //alert(products);
                    mainHt+=products;
                }
           }
        }

      
        //$("#pgComp").innerHtml=mainHt;
    }
    //alert(mainHt);
    //mainHt+=Product_Hori_Slider();

    //Condition for leftMenuOpt
    if(rightBarLists){
        var getLeftOpt=Left_Menu_Option(rightBarLists,loc);
    var mainDiv="<div class=\"shop-category-area pb-100px pt-70px\">"+
    "<div class=\"container\">"+
        "<div class=\"row\">";
    var mainCloseDiv="</div></div></div>";
    mainHt="<div class=\"col-lg-9 order-lg-last col-md-12 order-md-first\">"+
            mainHt+"</div>";
    mainHt=mainDiv+getLeftOpt+mainHt+mainCloseDiv;
      }
   mainHt=strTopBanner+mainHt;

    $("#pgComp").html(mainHt);

    RespBkMthdMapp(clsObject,"_addToCart","setCartBk");

    AOS.init({
      once: true
    });
    const script = document.createElement("script");
    script.src = "/main.js";
    script.async = true;
    //script.onload = () => clsObject.scriptLoaded();
  
    document.body.appendChild(script);
      //this.forceUpdateHandler();
    if(ifImgGallery){
        $('.gallery-item').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }
  
}
setCartBk(refId){
   // alert(refId);
    this.setState(state =>({viewData:refId}));
    this.forceUpdate();
    $("#offcanvas-cart").addClass("offcanvas-open");
  }
callBackProList(data,msg){
       //alert("testing=="+data);
    if(data!=="0"){
    // var resPs= Product_Slider(data);
   
    // products+=resPs;
    
    }
    //alert("i am in lcick");
    var pgMap=[];
  pgMap.push({name:"curl_type",value:"getPgData"});
  pgMap.push({name:"res_func",value:"callBackpgData"});
  pgMap.push({name:"loc",value:loc});
 // mapCat.push({name:"gb_token",value:userObject[0].app_user_id});
  //mapCat.push({name:"emp_id",value:userObject[0].id});
  pgMap["res_func"]="callBackpgData";
  pgMap["curl_type"]="getPgData";
  CallCMSMapMethod(clsObject,pgMap);
  }
saveEdu(){
  GetImgCertUser(clsObject,"#frmEdu","getTest","okEdut");
}
okEdut(data,datares,userResp){
  //alert(data);
}
    render(){
        return(<div>
          <Front_Header/>

          <div className="offcanvas-overlay"></div>
        
         <div id="pgComp">

         </div>
     
     {/* <section id="why-us" className="why-us text-center pt-70px pb-70px "> */}

{/* <div className="small_container text-center">
		<div className="row d-flex justify-content-center row-cols-2 row-cols-lg-3">
			<div className="col-4 col-lg-2">
				<div className="text-center">
					<a href="">
						<img src={why_medi} className="img-fluid mb-2" />
					</a>
					<a href="">
						<p className="fs-5 fw-bold mb-0">Service 1</p>
					</a>
					<p>Contrary to popular...</p>
				</div>
			</div>
			<div className="col-4 col-lg-2">
				<div className="text-center">
					<a href="">
						<img src={why_edu} className="img-fluid mb-2" />
					</a>
					<a href="">
						<p className="fs-5 fw-bold mb-0">Service 2</p>
					</a>
					<p>Contrary to popular...</p>
				</div>
			</div>
			<div className="col-4 col-lg-2">
				<div className="text-center">
					<a href="">
						<img src={why_care} className="img-fluid mb-2" />
					</a>
					<a href="">
						<p className="fs-5 fw-bold mb-0">Service 3</p>
					</a>
					<p>Contrary to popular...</p>
				</div>
			</div>
			<div className="col-4 col-lg-2">
				<div className="text-center">
					<a href="">
						<img src={why_care} className="img-fluid mb-2" />
					</a>
					<a href="">
						<p className="fs-5 fw-bold mb-0">Service 4</p>
					</a>
					<p>Contrary to popular...</p>
				</div>
			</div>
			<div className="col-4 col-lg-2">
				<div className="text-center">
					<a href="">
						<img src={why_trans} className="img-fluid mb-2" />
					</a>
					<a href="">
						<p className="fs-5 fw-bold mb-0">Service 5</p>
					</a>
					<p>Contrary to popular...</p>
				</div>
			</div>
			
		</div>
	</div> */}
	{/* <div className="text-center mt-5">
		<a href="" className="btn btn-primary btn-green">Read More</a>
	</div> */}
{/* </section> */}

          <Footer/>
          
          
          
          
          </div>
          
          );
    }
}
export default Index