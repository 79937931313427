import React from 'react';
import {appGetUrlPath} from '../function_lib/common_lib';
import './product_slider.css';
export function Product_Slider(lsData){
   // alert(lsData);
    var appUrtPath=appGetUrlPath();
var listData=JSON.parse(lsData);
var strResp="";
var strHeader="<div class=\"section\">"+
"<div class=\"container\">"+
   "<div class=\"row\">"+
        "<div class=\"col-md-12\">"+
        "<div class=\"heading page__title-bar\">"+
        "<h3 class=\"title\">Featured Products</h3>"+
        "<div class=\"page__title-right\">"+
            "<div class=\"swiper-button-prev slider-cover__prev swiper-button-prev5\" tabindex=\"-1\" role=\"button\" aria-label=\"Previous slide\" aria-disabled=\"true\"></div>"+
            "<div class=\"swiper-button-next slider-cover__next swiper-button-next5\" tabindex=\"0\" role=\"button\" aria-label=\"Next slide\" aria-disabled=\"false\"></div>"+
        "</div>"+
    "</div>"+
        "</div>"+
    "</div>"+
    "<div class=\"new-product-slider swiper-container slider-nav-style-1\"  sliderPerView=\"5\" >"+
        "<div class=\"new-product-wrapper swiper-wrapper\">";

var strFooter="</div></div></div></div>";
for(var i=0;i<listData.length;i++){
    let imgNm=listData[i]["img_path"].split(',');
    strResp+="<div class=\"new-product-item swiper-slide\">"+
    "<div class=\"product\">"+"<form>"+
    "<input type=\"hidden\" name=\"item_name\" value=\""+listData[i]["product_name"]+"\" />"+
    "<input type=\"hidden\" name=\"item_code\" value=\""+listData[i]["product_code"]+"\"/>"+
    "<input type=\"hidden\" name=\"item_rate\" value=\""+listData[i]["cost"]+"\" />"+
    "<input type=\"hidden\" name=\"comp_name\" value=\""+"0"+"\" />"+
    "<input type=\"hidden\" name=\"imgs\" value=\""+imgNm[0]+"\" />"+
    "<input type=\"hidden\" name=\"item_rf_id\" value=\""+listData[i]["id"]+"\" />"+
        "<div class=\"thumb\">"+
            "<a href=\"\product\\"+listData[i]["product_name"].replace(/\s/g, '_')+"?rf="+listData[i]["rf_id"]+"\" class=\"image\">"+
                "<img src=\""+appUrtPath+imgNm[0]+"\" alt=\"Product\" />"+
                "<img class=\"hover-image\" src=\""+appUrtPath+imgNm[0]+"\" alt=\"Product\" />"+
            "</a>"+
            "<span class=\"badges\">"+
                "<span class=\"new\">New</span>"+
            "</span>"+
            "<div class=\"actions\">"+
                "<a href=\"#\" class=\"action quickview\" data-link-action=\"quickview\" title=\"Quick view\" data-bs-toggle=\"modal\" data-bs-target=\"#exampleModal\"><i class=\"icon-size-fullscreen\"></i></a>"+
            "</div>"+
            "<button type=\"button\" title=\"Add To Cart\" class=\"_addToCart add-to-cart\">Add To Cart</button>"+
        "</div>"+
        "<div class=\"content\">"+
            "<h6 class=\"title\"><a href=\"\product\\"+listData[i]["product_name"].replace(/\s/g, '_')+"?rf="+listData[i]["rf_id"]+"\">"+listData[i]["product_name"]+"</a></h6>"+
            "<span class=\"price\">"+
                "<span class=\"new\">Rs. "+listData[i]["cost"]+"</span>"+
            "</span>"+
        "</div>"+
    "</form>"+"</div>"+
"</div>";
}
return strHeader+strResp+strFooter;
}
export default Product_Slider;