import $ from 'jquery';
import React from 'react';
import {btnSetColor_CBack,CallMapMethod_CBack} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './comp_upload_basic.css';
let appLoanUrlPath;
export function getPreData(app_token){
  let map=[];
  map.push({name:"curl_type",value:"getBasicProfile"});
  map.push({name:"res_func",value:"callBackNg"});
  map.push({name:"app_token",value:app_token});
 
  map["res_func"]="getBasicProfile";
  map["curl_type"]="getBasicProfile";
  CallMapMethod_CBack(this,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let lsData=JSON.parse(data);
      if(lsData.length>0){
        $("#company_name").val(lsData[0]["company_name"]);
        $("#address").val(lsData[0]["address"]);
        $("#state_id").val(lsData[0]["state_id"]);
        $("#city_id").val(lsData[0]["city_id"]);
        $("#pincode").val(lsData[0]["pincode"]);
        $("#alt_contact_no").val(lsData[0]["alt_contact_no"]);
        $("#email").val(lsData[0]["email"]);
        $("#pan_no").val(lsData[0]["pan_no"]);
        $("#gstin_no").val(lsData[0]["gstin_no"]);
      }
     
    }
    
  })
}
export function UpdDocSubmit(frmName){
 
  btnSetColor_CBack(this,frmName,"updateAppUserBasicP",function(data,datares){
    if(data===-10){
      alert("Kindly fill required fields");
    }else if(data==="001"){
      
    }else{
      //Not accepted from server
    }
  });
}

export function Comp_Upload_Basic(props){
  //alert(JSON.stringify(props));
  //alert(props.userObject);
  let callingVendorId=0;
  if(props.viewLoadDoc!=="undefined"){
   // alert(props.viewLoadDoc[0].doc_list);
   callingVendorId=props.userObject[0].app_user_id;
   if(typeof props.vendorId!=="undefined"){
    callingVendorId=props.vendorId 
   }
   if(callingVendorId!==0){
    getPreData(callingVendorId);
   }
   
  }
  
  return(<div class="modal" id="divBasicDetails" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">Basic Details</h5>
        <button type="button" class="close white" data-dismiss="modal" aria-label="Close">
          <span className="white" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="frmBasicDetails" name="frmBasicDetails" className='formarea' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="curl_type" id="curl_type" value="updateAppUserBasicP" />  
        <input type="hidden" name="m_type" id="m_type" value="updateAppUserBasicP" /> 
        <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
        <input type="hidden" name="app_token" id="app_token" value={props.userObject[0].app_user_id} />
        <input type="hidden" name="hi_docs" id="hi_docs"/>
        <div className="row">
         <div className="col-md-4">
            <div className="form group">
              <label>Company Name*</label>
                <input type="text" id="company_name" name="company_name" className="form-control required" placeholder="Company Name" />
            </div>
         </div>
         <div className="col-md-8">
            <div className="form-group">
              <label>Address*</label>
                <input type="text" id="address" name="address" className="form-control required" />
            </div>
         </div>

         <div className="col-md-3">
            <div className="form-group">
              <label>State*</label>
                <select id="state_id" name="state_id" className="form-control required">
                    <option value={"0"}>--State--</option>
                    {props.stateList.map((item,index)=>{
                      return <option value={item["id"]}>{item["label"]}</option>
                    })}
                </select>
            </div>
         </div>
         
         <div className="col-md-3">
            <div className="form-group">
              <label>City*</label>
                <input type="text" id="city_id" name="city_id" className="form-control required" />
            </div>
         </div>

         <div className="col-md-3">
            <div className="form-group">
              <label className="">Pincode*</label>
                <input type="number" id="pincode" name="pincode" className="form-control required" />
            </div>
         </div>

         <div className="col-md-3">
            <div className="form-group">
              <label>Alt. Contact No*</label>
                <input type="number" id="alt_contact_no" name="alt_contact_no" className="form-control required"/>
            </div>
         </div>

         <div className="col-md-4">
            <div className="form-group">
              <label >Email*</label>
                <input type="text" id="email" name="email" className=" required" />
            </div>

         </div>

         <div className="col-md-4">
            <div className="form-group">
              <label className="col-sm-3">PAN</label>
                <input type="text" id="pan_no" name="pan_no" className="form-control required12"/>
            </div>
         </div>

         <div className="col-md-4">
            <div className="form-group">
              <label>GSTIN</label>
                <input type="text" id="gstin_no" name="gstin_no" className="required12" />
            </div>

         </div>

        </div>       
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" onClick={()=>UpdDocSubmit("frmBasicDetails")} class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>);
}
export default Comp_Upload_Basic;