import $ from 'jquery';
import React from 'react';
import QueryString from 'query-string';
import User_Header from './user_header';
import User_Footer from './user_footer';
import GoogleMapReact from 'google-map-react';
import Mobile_Widgets from '../sample_comp/mobile_widgets';
import {getUserObjData} from '../function_lib/userToken';

import {Order_Data_Horizontal} from '../sample_comp/product_listing';
import {CallMapMethod,SetResponseDiv,AjaxCallMethod,TableDatePicker,CallMapMethod_CBack,
  btnSetColor_CBack,dateString2Date} from '../function_lib/common_lib';
import {Comp_Colby_Collection} from '../component_version2/comp_colby_collection';
import Reports_Search_Filter from '../component_version2/reports_search_filter';

let filtStatus;
let queries;
let getUser;
let clsHomeCollection;

// parameters for filters
let searFltStatus=[{value:"0",display:"-All-"},
{value:"Pending Assign",display:"Pending Assign"},
{value:"Pending Collection",display:"Pending Collection"},
{value:"Pending Accession",display:"Pending Accession"},
{value:"Pending Complete",display:"Pending Complete"},
{value:"Pending Verification",display:"Pending Verification"},
{value:"Pending Approval",display:"Pending Approval"},
{value:"Pending Print",display:"Pending Print"},
{value:"Printed",display:"Printed"},
];
let isFromDate=true;
let isToDate=true;
// end of parameters for Filters

const getCoordinatesFromAddress = async (address) => {
  // Make an API request to a geocoding service and retrieve the coordinates
  // This example assumes you have an API key for the service
  const apiKey = 'AIzaSyCzryGjQ7IsDjFmFlK4-bU3IUndpGSfGJ8';
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`
  );
  const data = await response.json();
    
  if (data.results.length > 0) {
    const location = data.results[0].geometry.location;
    //alert(JSON.stringify(data.results[0].geometry.location));
    return {
      lat: location.lat,
      lng: location.lng,
    };
  }else{
    return {}
  } 
};

class Home_Collection extends React.Component{
  
/* Collection Boy Active */  
updateCollectionBoy(frmName){
  //alert(frmName);
  btnSetColor_CBack(clsHomeCollection,frmName,"setCollectionStatus",function(data,msg){
    $("#getColboyresp").text(msg);
  })
}

forceUpdateHandler(){
  this.forceUpdate();
};
    constructor(props){
        super(props);
        clsHomeCollection=this;
        queries = QueryString.parse(this.props.location.search);
        filtStatus="order";
        if(queries.rf==="collection")
            {
              filtStatus="collection";
            }
            getUser=getUserObjData();
            this.state={stInvoiceItems:[],stInvOrderItems:[],stGeoCode:[]};
            this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
      }
    getCollectionItems(data){
      //alert("check collections");
      //alert(JSON.stringify(data));
      //return;
      var fromDt=$("#from_dt").val();
      var toDt=$("#to_dt").val();
      let filtStatus=$("#filter_status").val();
      let map=[];
      map.push({name:"curl_type",value:"getHomeCollectionOrders"});
      map.push({name:"res_func",value:"callBackNg"});
      map.push({name:"from_dt",value:fromDt});
      map.push({name:"to_dt",value:toDt});
      map.push({name:"local_token",value:getUser[0]["id"]});
      map.push({name:"collection_status",value:filtStatus});
      map.push({name:"invoice_type_id",value:"14"});// for homecolleciton only
      map.push({name:"rf",value:data.invoice_id});
      map["res_func"]="callBackNg";
      map["curl_type"]="getHomeCollectionOrders";
      if(getUser!==""){
       
          //alert("testing this is me");
          map.push({name:"app_token",value:getUser[0]["app_user_id"]});
          CallMapMethod_CBack(clsHomeCollection,map,async function(data,msg){
            let _gAddress="";
            let arrCode=[];
            let lsData=JSON.parse(data);
            if(lsData.length>0){
              _gAddress=lsData[0]["Address"]+", "+lsData[0]["city_name"]+","+lsData[0]["pincode"]+", "+lsData[0]["State"];
              //alert(_gAddress)
              let setGCod=await getCoordinatesFromAddress(_gAddress);
              arrCode.push(setGCod);
            }
           
           //alert(JSON.stringify(setGCod));
          
            clsHomeCollection.setState({stInvOrderItems:JSON.parse(data),stGeoCode:arrCode});
           
          });
      }

    }  
    getHomeCollection(){
       // alert(getUser[0]["id"]);
    //alert("testing i m in click");
        //get Values from SearchParam Object
        var fromDt=$("#from_dt").val();
        var toDt=$("#to_dt").val();
     
       
       //console.log(this.props.location.search);
       //queryString.parse(location.search);
       var map=[];
       //alert(this.props);
       if(typeof clsHomeCollection.props.location!=="undefined"){
         queries = QueryString.parse(clsHomeCollection.props.location.search);
       }
       
       //console.log(queries.rf); 
       let filtStatus=$("#filter_status").val();
       //filtStatus="0";
     //   if(queries.rf==="collection")
     //   {
     //     filtStatus="collection";
     //     map.push({name:"collection_status",value:"Pending Assign"});
     //   }else{
     //     map.push({name:"collection_status",value:"Pending Accession"});
     //   }
       map.push({name:"curl_type",value:"getHomeCollectionInvoice"});
       map.push({name:"res_func",value:"callBackNg"});
       map.push({name:"from_dt",value:fromDt});
       map.push({name:"to_dt",value:toDt});
       map.push({name:"local_token",value:getUser[0]["id"]});
       map.push({name:"collection_status",value:filtStatus});
       map.push({name:"invoice_type_id",value:"14"});// for homecolleciton only
       map["res_func"]="callBackNg";
       map["curl_type"]="getHomeCollectionInvoice";
       if(getUser!==""){
           //alert("testing this is me");
           map.push({name:"app_token",value:getUser[0]["app_user_id"]});
           CallMapMethod(clsHomeCollection,map,true);
       }
       

    }   
    componentDidMount(){
         this.getHomeCollection();
         AjaxCallMethod(this,"callBackNg");

         const script = document.createElement("script");
         script.src = "/main.js";
         script.async = true;
         //script.onload = () => clsObject.scriptLoaded();
       
         document.body.appendChild(script);
         const script2 = document.createElement("script");
         script2.src = "/specific_listing.js";
         script2.async = true;
         document.body.appendChild(script2);

       
    }
    callBackNg(data,msg){
      //$("#checkResp").html(msg);
        //alert(data);
        //console.log(data);
        if(data.trim()!=="0"){
          //alert("i m in data");
          clsHomeCollection.setState({stInvoiceItems:JSON.parse(data)},function(){
            clsHomeCollection.forceUpdateHandler();
          });
        }
      
        //$("#checkResp").html(msg);
     }
    render(){
        return(<div>
               <User_Header />
               <div id="checkResp"></div>
<div className="container">

<div id="divOrder">
<div className="shop-category-area pb-10px pt-10px">
    <div className="container">
        <div className="row mt-5">
        <Reports_Search_Filter statusList={searFltStatus} 
filters={[]}
isFromDate={isFromDate} 
isToDate={isToDate}
onSearchClick={this.getHomeCollection} 
/>
            <div className="col-lg-8 order-lg-last col-md-8 order-md-first">
            <div className="shop-bottom-area">
            <div className="row">
                {/* start of rotationalCOmpo */}
           {this.state.stInvoiceItems.map((item,index)=>{
            let assignTime=dateString2Date(item["colboy_dt"],0);
            assignTime=assignTime.getHours()+":"+assignTime.getMinutes()+" Hrs";

            let collectionTime=dateString2Date(item["colboy_dt"],90);
            collectionTime=collectionTime.getHours()+":"+collectionTime.getMinutes()+" Hrs";

            return (<div className="col-md-12 pb-5">
            <form>
          <div className="card p-1 shadow">
                <div className="row">
                <div className="col-sm-4">
                <div className="card-block">
                    <div className="p-2 mb-1 alert-warning mybg text-white">
                        <h6 className="mb-0">Invoice No:<b>{item["Invoice No"]}</b></h6>
                    </div>
                    <p className="mb-0">Name : <b>{item["Company Name"]}</b></p>
                    <p className="mb-0">Contact No : <b>{item["contact_no"]}</b></p>
                    <p className="text-danger mb-0">Gender :<b>{item["Gender"]}</b></p>
                    
                </div>
            </div>
                    <div className="col-sm-4">
                    <div className="card-block">
                    <p className="mb-0">Status : <b>{item["collection_status"]}</b></p>
                    <p className="mb-0">Date : <b>{item["date"]}</b></p>
                    
                    <p className="mb-0">Amount : <b>₹ {item["Net Amount"]}</b></p>
                    <p className="text-danger mb-0">Payment Status : <b>{item["Payment Status"]}</b></p>
                </div>
            </div>
            <div className="col-sm-4">
            <p className="mb-0">At : <b>{item["vend_company_name"]}</b></p>
            {item["collection_status"]==="Pending Collection"?
                    <div className="text-white">
                      
                      <p className="mb-0"><span className="badge bg-primary">Assignt At:<b>{assignTime}</b></span></p>
                    <p><span className="badge bg-danger">Collect Before:<b className="ref">{collectionTime}</b></span></p>  
                    
                    </div>:''}
            {/* <p className="mb-0">UserName : <b>{item["vend_username"]}</b></p> */}
                <div className="p-2 mb-1 text-white">
                      {
                      item["collection_status"]==="Pending Collection"?<a href={"javascript:void(0)"} onClick={()=>this.getCollectionItems(item)} data-toggle="modal" data-target="#divColByCollection" className="btn btn-primary float-right py-2 px-4">Process Collection</a>:''
                      }
                    
                    </div>
                </div>    
                  
                </div>
            </div>
        </form></div>)
           })}     
                


              </div></div></div></div></div></div>
</div>
</div>
    
               {/* <Mobile_Widgets/> */}
               <User_Footer/>
               <Comp_Colby_Collection geocodes={this.state.stGeoCode} userObject={getUser} eventSubmit={this.updateCollectionBoy} invOrderItems={this.state.stInvOrderItems} />
        </div>)
    }
}
export default Home_Collection; 