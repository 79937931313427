import $ from 'jquery';
import React from 'react';
import {btnSetColor_CBack,CallMapMethod_CBack} from '../function_lib/common_lib';
import updFile from '../assets/images/upload.png';
import './comp_upload_basic.css';
let appLoanUrlPath;
let cdavmListMoney=[];
export function getPreData(app_token){
  let map=[];
  map.push({name:"curl_type",value:"getBasicProfile"});
  map.push({name:"res_func",value:"callBackNg"});
  map.push({name:"app_token",value:app_token});
 
  map["res_func"]="getBasicProfile";
  map["curl_type"]="getBasicProfile";
  CallMapMethod_CBack(this,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let lsData=JSON.parse(data);
      if(lsData.length>0){
        $("#company_name").val(lsData[0]["company_name"]);
        $("#address").val(lsData[0]["address"]);
        $("#state_id").val(lsData[0]["state_id"]);
        $("#city_id").val(lsData[0]["city_id"]);
        $("#pincode").val(lsData[0]["pincode"]);
        $("#alt_contact_no").val(lsData[0]["alt_contact_no"]);
        $("#email").val(lsData[0]["email"]);
        $("#pan_no").val(lsData[0]["pan_no"]);
        $("#gstin_no").val(lsData[0]["gstin_no"]);
      }
     
    }
    
  })
}
export function UpdDocSubmit(frmName){
 
  btnSetColor_CBack(this,frmName,"distributeVenAdvaietMoney",function(data,datares){
    //alert(data+datares);
    if(data===-10){
      alert("Kindly fill required fields");
    }else if(data==="001"){
      alert(datares);
      window.location.reload();
    }else{
      //Not accepted from server
    }
  });
}

export function Comp_View_Money_Offer(props){
  
  
 
  
  return(<div class="modal" id="divViewMoneyOffers" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title white">Available Offers</h5>
        <button type="button" class="close white" data-dismiss="modal" aria-label="Close">
          <span className="white" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form id="frmDistAdvMoney" name="frmDistAdvMoney" className='formarea' enctype="multipart/form-data" method="POST" action="#">
        <input type="hidden" name="curl_type" id="curl_type" value="distributeVenAdvaietMoney" />  
        <input type="hidden" name="m_type" id="m_type" value="distributeVenAdvaietMoney" /> 
        <input type="hidden" name="res_func" id="res_func" value="okSuccCNOT" />
        <input type="hidden" name="app_token" id="app_token" value={props.userObject[0].app_user_id} />
        <div className="row">
         
         <div className="col-md-12">
            <p style={{"color":"#809fff"}} id="cdavmListMsg"></p>

         </div>
         <div className="col-md-12">
         {props.viewOffMoneyList.map((item,index)=>{
            return <div class="col-md-12 pb-10">
            <form>
          <div class="card p-1 shadow">
                <div class="row">
                <div class="col-sm-12">
                <div class="card-block">
                    <div class="p-2 mb-1 alert-warning mybg text-white">
                        <h5 class="mb-0">Code:{item.m_code}</h5>
                    </div>
                    <p class="text-danger mb-0">Contact No : <b>{item.applied_contact_no}</b></p>
                    <p class="mb-0">Amount : <b>₹{item.available_amount}</b></p>
                    <p class="mb-0">Status : <b>{item.status}</b></p>
                </div>
            </div>
                </div>
            </div>
        </form></div>
         })}  
         </div>
        </div>       
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>);
}
export default Comp_View_Money_Offer;