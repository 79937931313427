import React from 'react';
import imgCT from '../assets/images/product-image/CT.png';
import imgUSG from '../assets/images/product-image/USG.png';
import imgXRay from '../assets/images/product-image/XRay.png';
import imgMRI from '../assets/images/product-image/MRI.png';
import imgDexScan from '../assets/images/product-image/Dexa_Scan.png';
import imgECG from '../assets/images/product-image/ECG.png';
import imgEEG from '../assets/images/product-image/EEG.png';

import './features_slider.css';

export function Features_Slider(){
    var strResp="";
    strResp+="<div class=\"feature_slider_bg border-box section\">"+
    "<div class=\"container1\">"+
        "<div class=\"category-slider swiper-container\">"+
            "<div class=\"category-wrapper swiper-wrapper\">"+
                
                "<div class=\"swiper-slide\">"+
                    "<a href=\"..\\vendors\\ct\" class=\"category-inner\">"+
                        "<div class=\"category-single-item feat_img\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+imgCT+"\" alt=\"\" /></div>"+
                            "<span class=\"title\">CT SCAN</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+
                "<div class=\" swiper-slide\">"+
                    "<a href=\"..\\vendors\\usg\" class=\"category-inner feat_img\">"+
                        "<div class=\"category-single-item\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+imgUSG+"\" alt=\"\" /></div>"+
                            "<span class=\"title\">USG</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+

                "<div class=\"swiper-slide\">"+
                "<a href=\"..\\vendors\\x-ray\" class=\"category-inner feat_img\">"+
                    "<div class=\"category-single-item\">"+
                        "<div class=\"feature_img_bg\"><img src=\""+imgXRay+"\" alt=\"\" /></div>"+
                        "<span class=\"title\">X-Ray</span>"+
                    "</div>"+
                "</a>"+
            "</div>"+

                "<div class=\" swiper-slide\">"+
                    "<a href=\"..\\vendors\\mri\" class=\"category-inner feat_img\">"+
                        "<div class=\"category-single-item\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+imgMRI+"\" alt=\"\" /></div>"+
                            "<span class=\"title\">MRI SCAN</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+
                
                "<div class=\" swiper-slide\">"+
                    "<a href=\"..\\vendors\\dexa\" class=\"category-inner feat_img\">"+
                        "<div class=\"category-single-item\">"+
                            "<div class=\"feature_img_bg\"><img src=\""+imgDexScan+"\" alt=\"\" /></div>"+
                            "<span class=\"title\">DEXA SCAN</span>"+
                        "</div>"+
                    "</a>"+
                "</div>"+

                "<div class=\" swiper-slide\">"+
                "<a href=\"..\\vendors\\ECG\" class=\"category-inner feat_img\">"+
                    "<div class=\"category-single-item\">"+
                        "<div class=\"feature_img_bg\"><img src=\""+imgECG+"\" alt=\"\" /></div>"+
                        "<span class=\"title\">ECG</span>"+
                    "</div>"+
                "</a>"+
            "</div>"+
             
            "<div class=\" swiper-slide\">"+
            "<a href=\"..\\vendors\\EEG\" class=\"category-inner feat_img\">"+
                "<div class=\"category-single-item\">"+
                    "<div class=\"feature_img_bg\"><img src=\""+imgEEG+"\" alt=\"\" /></div>"+
                    "<span class=\"title\">EEG</span>"+
                "</div>"+
            "</a>"+
        "</div>"+
        "<div class=\" swiper-slide\">"+
        "<a href=\"..\\vendors\\TMT\" class=\"category-inner feat_img\">"+
            "<div class=\"category-single-item\">"+
                "<div class=\"feature_img_bg\"><img src=\""+imgEEG+"\" alt=\"\" /></div>"+
                "<span class=\"title\">TMT</span>"+
            "</div>"+
        "</a>"+
    "</div>"+
               
            "</div>"+
        "</div>"+
    "</div>"+
"</div>";
//alert("testin g=="+strResp);
return strResp;
}
export default Features_Slider;