import $ from 'jquery';
import React from 'react';
import GoogleMapReact from 'google-map-react';
import './comp_colby_collection.css';
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const CustomMarker = ({ text }) => {
    return (
      <div className="custom-marker">
        <div className="marker-icon">
          {/* Replace 'your-icon-url' with the URL of your custom marker icon */}
          <img src="https://advaiet.com/google_loc_pin.png" alt="Marker Icon" />
        </div>
        <div className="marker-text">{text}</div>
      </div>
    );
  };
export function SimpleMap(props){

  //let getCordi= getCoordinatesFromAddress(props.gAddress);
  
  let geoLat=23.167953;
  let geoLng=75.788162;
  if(props.geocodes.length>0){
    geoLat=props.geocodes[0].lat;
    geoLng=props.geocodes[0].lng;
  }
    const defaultProps = {
      center: {
        lat: geoLat,
        lng: geoLng
      },
      zoom: 15
    };
  
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCzryGjQ7IsDjFmFlK4-bU3IUndpGSfGJ8" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <CustomMarker
            lat={geoLat}
            lng={geoLng}
            //img_src="https://advaiet.com/google_loc_pin.png"
            text={props.markerText}
          />
        </GoogleMapReact>
      </div>
    );
  }
export const Comp_Colby_Collection=(props)=>{
  
    let colBasicDet={};
    let purIds="";
    let itemIds="";
    $("#getColboyresp").text("");
    $("#collection_status").val("0");
    $("#otp_confirm").val("");
    let mapMarkerText="";
    
    if(props.invOrderItems.length>0){
        colBasicDet=props.invOrderItems[0];
        //alert(JSON.stringify(props.invOrderItems[0]));
        mapMarkerText=props.invOrderItems[0]["Company Name"]+"("+props.invOrderItems[0]["Invoice No"]+")";
        
    }
    //alert(JSON.stringify(colBasicDet));
    
    return(<div className="modal fade" id="divColByCollection" tabindex="-1" role="dialog">
    
    <div className="modal-dialog modal-xl" role="document">
        <div className="modal-content">
            <div className="modal-header">
            Invoice No:<b>{colBasicDet["Invoice No"]}</b>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">x</span></button>
            </div>
            <div className="modal-body">
                <div className="row">
                     
                    <div className="col-md-7 col-sm-12 col-xs-6">
                        <div className="product-details-content quickview-content">
                            
                            
                            
                            <div className="pricing-meta12">
                                <ul>
                                    <li className="old-price not-cut"><a href={"tel:"+colBasicDet["Contact_No"]}><i className="icon-call-in"></i>Click to Call Customer:</a></li>
                                </ul>
                            </div>
                            
                            <table>
                                <tr>
                                    <td>Patient Name :</td>
                                    <td><b>{colBasicDet["Company Name"]}</b></td>
                                </tr>
                                <tr>
                                    <td>Gender :</td>
                                    <td><b>{colBasicDet["Gender"]}</b></td>
                                </tr>
                                <tr>
                                    <td>Age :</td>
                                    <td><b>{colBasicDet["Age"]}</b></td>
                                </tr>
                                <tr>
                                    <td>Address :</td>
                                    <td><b>{colBasicDet["Address"]}</b></td>
                                </tr>
                            </table>

                            <div className="pro-details-rating-wrap">
                               
                               <span className="read-review"><a className="reviews" href="#">No of Order Collection ({props.invOrderItems.length})</a></span>
                           </div>
                            
                        <table className="table table-bordered table-striped table-sm">
                            <thead>
                                <tr style={{"background-color":"#FC766AFF"}}>
                                    <td>S.No.</td>
                                    <td>Test</td>
                                    <td>Test Code</td>
                                </tr>
                                
                                
                            </thead>
                            <tbody>
                              {props.invOrderItems.map((item,index)=>{
                                if(purIds!==""){
                                    purIds+=",";
                                }
                                if(itemIds!==""){
                                    itemIds+=","
                                }
                                purIds+=item["purchase_item_id"];
                                itemIds+=item["item_id"];

                                return <tr>
                                    <td>{index+1}</td>
                                    <td>{item["Item"]}</td>
                                    <td>{item["Item Code"]}</td>
                                </tr>
                              })}
                            </tbody>
                        </table> 
                        
                     </div>
                     <form id="frmColboyCollection" name="frmColboyCollection">
                        <input type="hidden" id="invoice_id" name="invoice_id" value={btoa(colBasicDet["invoice_id"])}/>
                        <input type="hidden" id="pur_itemids" name="pur_itemids" value={btoa(purIds)}/>
                        <input type="hidden" id="m_type" name="m_type" value={"setCollectionStatus"}/>
                        <input type="hidden" id="res_func" name="res_func" value={"se_valid_beo"}/>
                        <input type="hidden" id="type_id" name="type_id" value={"7"}/>
                        <input type="hidden" id="ref1_id" name="ref1_id" value={props.userObject[0]["id"]}/>
                        <input type="hidden" id="ref2_id" name="ref2_id" value={btoa(colBasicDet["invoice_id"])}/>
                        <input type="hidden" id="itemids" name="itemids" value={btoa(itemIds)} />
                     <div className="form-group">
                <label className="form-control-label">Sample Status</label>
                 <select className="form-control required" id="collection_status" name="collection_status">
                    <option value={"0"}>-Status-</option>
                    <option value="Pending Accession">Sample Collected</option>
                    <option value="Not Collected">Not Collected</option>
                    </select>               
                                </div>

                                <div className="form-group">
                <label className="form-control-label">OTP-Confirmation</label>
                 <input className="form-control required" id="otp_confirm" name="otp_confirm" />
                                </div>         
                                <div>
                                <div id="getColboyresp" style={{color:"red"}}></div><br></br>
                    <span class="float-left  badge "> 
            <button type="button" onClick={()=>props.eventSubmit("frmColboyCollection")} class="btn btn-primary float-right py-2 px-4">Submit</button>
            </span>
            </div>         
                     </form>
                    </div>
                    <div className="col-md-5 col-sm-12 col-xs-12 mb-lm-30px mb-sm-30px">
                        {props.geocodes.length>0?
                        <SimpleMap geocodes={props.geocodes} markerText={mapMarkerText} />:'Not Map Available'
                        }
                        
                      
                    </div>  
                    
                    
                </div>
            </div>
        </div>
    </div>
    </div>)
}
export default Comp_Colby_Collection;